import {useQuery} from '@tanstack/react-query'
import {axiosWithAuth} from '../utils/axios'

export const useHomeOffers = () => {
  const axios = axiosWithAuth()
  return useQuery({
    queryKey: ['home8_offers'],
    queryFn: async () => {
      try {
        const { data } = await axios.get('user/me')
        const userID = data?.result?.id
        if (userID == null) {
          return []
        }
        const result = await axios.get(`user/offers`)
        console.log(result)
        if (result.data?.code === -1) {
          return []
        }
        return result.data?.result
      } catch (e: any) {
        return []
      }
    },
    refetchOnWindowFocus: false,
  })
}
