

type PublicSchool = {
    name: string
    score: number
    address: string
    district: string
    number_of_student?: number
    student_to_teacher_ratio?: number
}


function PublicSchoolCard({
    name,
    score,
    address,
    district,
    number_of_student,
    student_to_teacher_ratio,
}: PublicSchool) {
    const styles = {
        container: {
            fontFamily: 'Arial, sans-serif',
            maxWidth: '800px',
            margin: '0 auto',
            padding: '20px',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
        },
        title: {
            fontSize: '24px',
            fontWeight: 'bold',
        },
        dataSource: {
            fontSize: '14px',
            color: '#5D6B79',
        },
        lastUpdate: {
            fontSize: '14px',
            color: '#5D6B79',
            marginTop: '4px',
        },
        link: {
            color: '#007BFF',
            textDecoration: 'none',
        },
        button: {
            backgroundColor: '#000',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '20px',
            border: 'none',
            cursor: 'pointer',
            fontSize: '16px',
        },
        buttonSubText: {
            display: 'block',
            fontSize: '12px',
            color: '#ddd',
        },
        schoolCard: {
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            backgroundColor: '#F6F7F8',
            borderRadius: '12px',
            marginBottom: '10px',
            //boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        },
        rating: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#EEF0F2',
            borderRadius: '12px',
            width: '57px',
            height: '57px',
            marginRight: '15px',
        },
        ratingScore: {
            fontSize: '24px',
            fontWeight: '600',
        },
        ratingBase: {
            fontSize: '14px',
            fontWeight: '400',
            color: '#748595',
            //fontWeight: 'bold',
        },
        schoolInfo: {
            flex: 1,
            alignSelf: 'flex-end',
        },
        extraInfo: {
            flex: 1,
            textAlign: 'right' as const,
            alignSelf: 'flex-end',
            marginRight: '12px'
        },
        infoText: {
            fontSize: '14px',
            fontWeight: '400',
            color: '#2F363D',
            lineHeight: '18px',
        },
        schoolName: {
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '21.79px',
        },
        district: {
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '18px',
            margin: 0
        },
        address: {
            fontSize: '14px',
            lineHeight: '18px',
            color: '#126FD6',
        },
        studentInfo: {
            fontSize: '14px',
            color: '#2F363D',
        },
    };

    return (
        <div style={styles.schoolCard}>
            <div style={styles.rating}>
                <span style={styles.ratingScore}>{score}</span>
                <span style={styles.ratingBase}>/10</span>
            </div>
            <div style={styles.schoolInfo}>
                <h4 style={styles.schoolName}>{name}</h4>
                <p style={styles.district}>{district}</p>
                <p style={{margin: 0, padding: 0, lineHeight: '18px'}}><a href={`https://maps.google.com/?q=${address}`} style={styles.address}>
                    {address}
                </a></p>
            </div>
            <div style={styles.extraInfo}>
                <p style={styles.infoText}>Number of Students: {number_of_student}</p>
                <p style={styles.infoText}>Student to Teacher Ratio: {student_to_teacher_ratio}</p>
            </div>
        </div>
    );
}

export default PublicSchoolCard;