import { useMutation } from '@tanstack/react-query'
import useAgentInfoStore from '../store/agentInfo'
import useAuthStore from '../store/auth'
import {axiosAdminWithoutAuth, axiosWithAuth} from '../utils/axios'
export const useAdminCreateProperty = () => {
  const axios = axiosAdminWithoutAuth()
  const { mlsListingID, setSuccessModalOpen } =
    useAgentInfoStore()
  const createAgent = async () => {
    try {
      const data = {
        'mls_listing_id': mlsListingID,
        'knowledges': [],
      }
      const res = await axios.post('propertyv2', data)
      setSuccessModalOpen(true)
      return res
    } catch (e) {
      return null
    } finally {
    }
  }

  const { mutate } = useMutation(createAgent)
  return { mutate }
}
