import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { axiosWithAuth } from '../utils/axios';

export const useHomeAutoCompleteHouse = (searchQuery: string) => {
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  const axios = axiosWithAuth();
  return useQuery({
    queryKey: ['home8_autocomplete', debouncedSearchQuery],
    queryFn: async () => {
      if (!debouncedSearchQuery) {
        return [];
      }
      try {
        const data = {
          input: debouncedSearchQuery,
        };
        const result = await axios.post('property_auto_complete', data);

        console.log(result.data.result.suggestions);
        return result.data.result.suggestions;
      } catch (e: any) {
        return [];
      }
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true, 
    staleTime: 300
  });
};
