import {useTranslation} from "react-i18next";


type ListingRead = {
    mls_listing_id: string
    on_market_date?: string
    off_market_date?: string
    original_listing_price?: number
    final_listing_price?: number
    close_price?: number
}

function ListingCard({
    mls_listing_id,
    on_market_date,
    off_market_date,
    original_listing_price,
    final_listing_price,
    close_price
}: ListingRead ) {
    const { t, i18n } = useTranslation();
    const styles = {
        card: {
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#F6F7F8',
            paddingTop: '8px',
            paddingLeft: '12px',
            borderRadius: '12px',
            marginBottom: '8px',
        },
        dateColumn: {
            flex: 1,
            marginRight: '20px',
        },
        detailsColumn: {
            flex: 2,
            textAlign: 'right' as const,
        },
        date: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '700',
            marginBottom: '10px',
        },
        detail: {
            fontSize: '12px',
            color: '#000000',
            marginBottom: '8px',
            lineHeight: '16.34px',
            fontWeight: '400',
        },
        price: {
            fontSize: '20px',
            lineHeight: '28px',
            fontWeight: '700',
            marginRight: '12px',
            color: '#161A1D',
        },
    };

    var final_listing_price_currency = 'N/A'
    if (final_listing_price) {
        final_listing_price_currency = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0, // Removes cents
            maximumFractionDigits: 0  // Removes cents
        }).format(final_listing_price);
    }

    var original_listing_price_currency = 'N/A'
    if (original_listing_price) {
        original_listing_price_currency = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0, // Removes cents
            maximumFractionDigits: 0  // Removes cents
        }).format(original_listing_price);
    }

    var off_market_date_str = 'N/A'
    if (off_market_date) {
        off_market_date_str = off_market_date
    }

    var close_price_str = 'N/A'
    if (close_price) {
        close_price_str = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0, // Removes cents
            maximumFractionDigits: 0  // Removes cents
        }).format(close_price);
    }

    return (
        <div style={styles.card}>
            <div style={styles.dateColumn}>
                <p style={styles.date}>{on_market_date}</p>
                <p style={styles.detail}>{`${t('listingOffMarketDate')}: ${off_market_date_str}`}</p>
                <p style={styles.detail}>{`${t('listingOriginalListingPriceLabel')}: ${original_listing_price_currency}`}</p>
                <p style={styles.detail}>{`${t('listingFinalListingPriceLabel')}: ${final_listing_price_currency}`}</p>
                <p style={styles.detail}>{`${t('listingMLSListingIDLabel')}: ${mls_listing_id}`}</p>
            </div>
            <div style={styles.detailsColumn}>
                <p style={styles.price}>{close_price_str}</p>
            </div>
        </div>
    );
}

export default ListingCard;