import { useQuery } from "@tanstack/react-query";
import { axiosWithAuth } from "../utils/axios";

export const useHomePropertyDetail = (propertyId?: string) => {
  const axios = axiosWithAuth();
  return useQuery({
    queryKey: ["home8_propertyDetail", propertyId?.toString()],
    queryFn: async () => {
      if (!propertyId) {
        return null;
      }
      try {
        const result = await axios.get(`property/${propertyId}`, {
          // language: "en",
        });
        return result.data.result;
      } catch (e: any) {
        return null;
      }
    },
    refetchOnWindowFocus: false,
  });
};
