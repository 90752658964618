import { useQuery } from '@tanstack/react-query'
import {axiosAdminWithoutAuth, axiosWithAuth} from '../utils/axios'

export const useAdminConversationHistoryByAgent = (propertyId?: string) => {
    const axios = axiosAdminWithoutAuth()
    return useQuery({
        queryKey: ['conversationHistoryByAgent', propertyId?.toString()],
        queryFn: async () => {
            let id = propertyId
            if (!propertyId) {
                id = 'all'
            }
            try {
                const data = {
                    property_id: id,
                    limit: 50,
                    offset: 0,
                }

                const result = await axios.post(`property_chat_history`, data)
                return result.data
            } catch (e: any) {
                return []
            }
        },
        refetchOnWindowFocus: false,
    })
}

export const useAdminConversationHistoryById = (conversationId?: string) => {
    const axios = axiosAdminWithoutAuth()
    return useQuery({
        queryKey: ['propertyChatHistoryById', conversationId?.toString()],
        queryFn: async () => {
            if (!conversationId) {
                return null
            }
            try {
                const result = await axios.get(`property_chat_history/${conversationId}`)
                return result.data
            } catch (e: any) {
                return null
            }
        },
        refetchOnWindowFocus: false,
    })
}
