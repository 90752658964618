import { create } from 'zustand'
import {Status} from "../home8/OfferCard";

export interface Knowledge {
  name: string
  s3_key: string
  gcs_blob_name: string
  status: FileStatus
  download_url: string
}

export enum FileStatus {
  UPLOADING = 'uploading',
  PROCESSING = 'processing',
  SUCCEED = 'succeed',
  FAILED = 'failed',
}

export function getTextForStatus(status: FileStatus) {
  switch (status) {
    case FileStatus.UPLOADING:
      return 'uploading'
    case FileStatus.PROCESSING:
      return 'processing'
    case FileStatus.SUCCEED:
      return 'ready'
    case FileStatus.FAILED:
      return 'failed'
    default:
      return 'ready'
  }
}

export interface Agent {
  id: string
  instruction: string
  knowledges: Knowledge[]
  name: string
  open_message: string
  phone_numbers: string[]
}

type EmailActivity = {
  event: string;
  time: number;
  email?: string;
};

export interface OfferType {
  buyer1_ds_user_id: number
  buyer2_ds_user_id: number
  content: string
  documents: any[]
  id: number
  last_envelope_id: number
  last_submitted_time: number
  property: Property
  property_id: string
  status: Status
  user_id: number
  review_failure_comment: string
  last_offer_email_activities: EmailActivity[]
}

export interface Property {
  id: string
  address: string
  bedrooms: number
  city: string
  county: string
  apn: string
  listing_agent_name: string
  listing_agent_email: string
  listing_agent_phone_number: string
  listing_agent_license_number: string
  brokerage_name: string
  brokerage_license_number: string
  create_time: string
  disclosure_url: string
  property_thumbnail_url?: string
  estimate_price: number
  full_bathrooms: number
  half_bathrooms: number
  listing_price: number
  living_sqft: number
  lot_size: number
  mls_listing_id: string
  property_type: string
  status: string
  zipcode: string
  is_publish: boolean
  edit_status: string
  knowledges: Knowledge[]
}

export interface EstimatePrice {
  price: number
  description: string
}

export interface RentalEstimate {
  low: number
  average: number
  high: number
}

export interface InspectionReport {
  source: string
  summary: string
  problems: InspectionProblem[]
}

export interface TermiteReport {
  source: string
  summary: string
  problems: TermiteProblem[]
}

export interface SellerPropertyQuestionaire {
  source: string
  summary: string[]
}

export interface TransactionDisclosureStatement {
  source: string
  seller_items_url: string
  seller_items_description: string
  summary?: string[]
}

export interface BackgroundStories {
  reason_for_selling: string
  seller_property_questionaire?: SellerPropertyQuestionaire
  transaction_disclosure_statement?: TransactionDisclosureStatement
}

export interface UpgradesAndRevovations {
  source: string
  content: string
}

export interface Contractor {
  name: string
}

export interface PermitRead {
  type: string
  description: string
  permit_classifications: string
  permit_number: string
  permit_status?: string
  permit_effective_date: string
  job_value: number
  contractors: Contractor[]
}

export interface InspectionProblem {
  category: string
  status: string
  defects: string
  recommendations: string
  repair_cost: string
}

export interface TermiteProblem {
  category: string
  status: string
  defects: string
  recommendations: string
  repair_cost: string
}

export interface NatureHazardRisk {
  issue: string
  status: string
  description: string
}

export interface NatureHazardRisks {
  source: string
  risks: NatureHazardRisk[]
}

export interface EnvironmentalPollution {
  issue: string
  status: string
  description: string
}

export interface EnvironmentalPollutions {
  source: string
  pollutions: EnvironmentalPollution[]
}

export interface HOARead {
  source: string
  content: string
}

export interface PublicSchool {
  name: string
  score: number
  address: string
  district: string
  number_of_student?: number
  student_to_teacher_ratio?: number
}

export interface SchoolRead {
  public_school_source: string
  public_school: PublicSchool[]
}

export interface ComparableSale {
  address: string
  bedrooms: number
  full_bathrooms: number
  half_bathrooms: number
  lot_size: number
  living_sqft: number
  off_market_date: string
  mls_listing_id: string
  original_listing_price: number
  close_price: number
}

export interface ComparableSales {
  comps_highest?: ComparableSale
  comps1?: ComparableSale
  comps2?: ComparableSale
  comps3?: ComparableSale
  comps4?: ComparableSale
  comps5?: ComparableSale
  comps6?: ComparableSale
}

export interface ListingAgentRead {
  name: string
  phone_number: string
  email: string
  company: string
  profile_url: string
}

export interface ListingRead {
  property_id: number
  mls_listing_id: string
  on_market_date: string
  off_market_date?: string
  original_listing_price: number
  final_listing_price: number
  close_price?: number
}

export interface TransactionRead {
  property_id: number
  sale_date: string
  sale_amount?: string
  title_company?: string
  buyer_names: string[]
  seller_names: string[]
}

export interface ListingOrTransaction {
  type: string
  listing?: ListingRead
  transaction?: TransactionRead
}

export interface UtilityProvider {
  category: string
  vendor: string
  contact: string
  website: string
  estimate_price: string
}

export interface Discrepancies {
  is_mismatch: boolean
  description: string
}

export interface LegalAndTitle {
  source: string
  plot_map_url: string
  prelim_info: string
}

export interface Neighborhood {
  economy_url: string
  housing_url: string
  people_url: string
  quality_url: string
}

export interface MarketTrend {
  market_trend_url: string
}

export interface CostOfOwnership {
  down_percentage: string
  interest: string
  mortgage: number
  insurance?: number
  property_tax?: number
}

export interface SellingPoint {
  category: string
  description: string
}

export interface Concern {
  category: string
  description: string
}

export interface PropertyReport {
  property_id: number
  estimate_price: EstimatePrice
  images: string[]
  property_type: string
  bedrooms: number
  full_bathrooms: number
  half_bathrooms: number
  lot_size: number
  living_sqft: number
  rental_estimate: RentalEstimate
  built_year: number
  on_market_date: string
  cost_of_ownership: CostOfOwnership
  selling_point: SellingPoint[]
  concerns: Concern[]
  inspection_report: InspectionReport
  termite_report: TermiteReport
  background_stories: BackgroundStories
  upgrades_and_revovations: UpgradesAndRevovations
  permits: PermitRead[]
  discrepancies: Discrepancies
  legal_and_title: LegalAndTitle
  nature_hazard_risks: NatureHazardRisks
  environmental_pollutions: EnvironmentalPollutions
  noise_level: string
  t_section: string
  voltage_lines: string
  potential_drivers_of_depreciation: string
  potential_drivers_of_appreciation: string
  hoa?: HOARead
  neighborhood: Neighborhood
  school: SchoolRead
  commute_time: string
  amenities: string
  utility_provider: UtilityProvider[]
  comparable_sales: ComparableSales
  market_trend: MarketTrend
  listing_and_transaction_history: ListingOrTransaction[]
  listing_agent: ListingAgentRead
  offer_related_insights: string
}

type AgentInfoStore = {
  loading: boolean
  setLoading: (value: boolean) => void
  propertyId: string
  mlsListingID: string
  setMlsListingID: (value: string) => void
  knowledges: Knowledge[]
  addToKnowledge: (fileName: string, key: string) => void
  removeKnowledge: (index: number) => void
  initForCreate: () => void
  initForUpdate: (info: Property) => void
  successModalOpen: boolean
  setSuccessModalOpen: (value: boolean) => void
  errorModalOpen: boolean
  setErrorModalOpen: (value: boolean) => void
}

const useAgentInfoStore = create<AgentInfoStore>((set) => ({
  loading: false,
  setLoading: (value: boolean) => set({ loading: value }),
  propertyId: '-1',
  mlsListingID: '',
  setMlsListingID: (value: string) => set({ mlsListingID: value }),
  knowledges: [],
  addToKnowledge: (fileName: string, key: string) =>
    set((state) => {
      state.knowledges = [
        ...state.knowledges,
        {
          name: fileName,
          s3_key: '',
          gcs_blob_name: key,
          status: FileStatus.UPLOADING,
          download_url: '',
        },
      ]
      return { ...state }
    }),
  removeKnowledge: (index: number) =>
    set((state) => {
      if (index >= state.knowledges.length) {
        return { ...state }
      } else {
        state.knowledges = [
          ...state.knowledges.slice(0, index),
          ...state.knowledges.slice(index + 1),
        ]
        return { ...state }
      }
    }),
  initForCreate: () =>
    set({
      propertyId: '-1',
      mlsListingID: '',
      knowledges: [],
    }),
  initForUpdate: (info: Property) =>
    set({
      propertyId: info.id,
      mlsListingID: info.mls_listing_id,
      knowledges: info.knowledges,
    }),
  successModalOpen: false,
  setSuccessModalOpen: (value: boolean) => set({ successModalOpen: value }),
  errorModalOpen: false,
  setErrorModalOpen: (value: boolean) => set({ errorModalOpen: value }),
}))

export default useAgentInfoStore
