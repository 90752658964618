import React, { useState, useRef, useEffect } from 'react';
import {useTranslation} from "react-i18next"; // Import your CSS for styling

function LanguageSelector() {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const languageMap: Record<string, string> = {
        'en-US': 'English',
        'zh-CN': '中文',
        // Add more language codes and names as needed
    };

    const toggleDropdown = () => setIsOpen(!isOpen);

    const selectLanguage = (language: string) => {
        setSelectedLanguage(language);
        setIsOpen(false);
        i18n.changeLanguage(language)
            .then(() => {
                console.log(`Language changed to ${language}`);
            })
            .catch((error) => {
                console.error(`Failed to change language to ${language}:`, error);
            });
        // Implement language change logic here (e.g., updating context or local storage)
    };

    const styles = {
        selectIcon: {
            width: '24px', /* Adjust the size of the icon */
            height: '24px',
            marginLeft: '8px' /* Space between the icon and the text */
        },
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="language-selector" ref={dropdownRef}>
            <button className="selected-language" onClick={toggleDropdown}>
                {languageMap[selectedLanguage] || 'Unknown Language'}
                <img src={isOpen ? "https://home8-gswe.onrender.com/down-arrow.png" : "https://home8-gswe.onrender.com/up-arrow.png"} alt="icon" style={styles.selectIcon}/>
            </button>
            {isOpen && (
                <div className="dropdown-menu">
                    {Object.keys(languageMap).map((languageCode) => (
                        <div
                            key={languageCode}
                            className="dropdown-item"
                            onClick={() => selectLanguage(languageCode)}
                        >
                            <img src="https://home8-gswe.onrender.com/mark-yes.png" alt="mark" className="mark-icon"/>
                            {languageMap[languageCode]}
                        </div>
                        ))}
                </div>
            )}
        </div>
    );
}

export default LanguageSelector;