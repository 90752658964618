import {Property, PropertyReport} from "../store/agentInfo";
import CustomReactionMarkdown from "./CustomReactMarkdown";
import React from "react";
import InspectionCard from "./InspectionCard";
import {useTranslation} from "react-i18next";

type HighlightInput = {
    property: Property,
    report: PropertyReport
}

function formatCamelCaseToTitleCase(str: string) {
    return str.replace(/([A-Z])/g, ' $1').trim();
}

function PropertyHighlightCard({property, report}: HighlightInput) {
    const styles = {
        propertyReport: {
            padding: '20px',
            maxWidth: '1200px',
            margin: 'auto',
        },
        container: {
            display: 'flex',
            flexDirection: 'row' as const,
            gap: '8px', /* Space between the columns */
        },
        leftColumn: {
            flex: '0 0 60%', /* Each column takes up equal space */
        },
        rightColumn: {
            flex: '0 0 40%', /* Each column takes up equal space */
        },
        title: {
            fontSize: '20px',
            fontWeight: '700',
            color: '#192D1D',
            lineHeight: '28px',
            marginTop: '20px'
        },
        updateTime: {
            fontSize: '12px',
            fontWeight: '400',
            color: '#5D6B79',
            lineHeight: '16.34px',
            marginTop: '4px'
        },
        propertyHighlights: {
            backgroundColor: '#F6F7F8', // Light background color similar to what appears in the image
            borderRadius: '12px',
            fontFamily: 'Arial, sans-serif',
            padding: '16px',
            marginTop: '8px',
        },
        propertyPriceEstimation: {
            backgroundColor: '#F6F7F8', // Light background color similar to what appears in the image
            borderRadius: '12px',
            fontFamily: 'Arial, sans-serif',
            padding: '16px',
            marginTop: '8px',
        },
        propertyPriceEstimationTitle: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '700',
            color: '#161A1D', /* Default color for values */
            marginBottom: '12px',
        },
        propertyPriceEstimationItem: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '8px'
        },
        propertyPriceEstimationLabel: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            color: '#5D6B79', /* Gray color for labels */
        },
        propertyPriceEstimationValue: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            color: '#5D6B79', /* Default color for values */
        },
        home8PriceEstimationItem: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '4px',
            marginTop: '12px'
        },
        home8PriceEstimationLabel: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            color: '#5D6B79', /* Gray color for labels */
        },
        home8PriceEstimationValue: {
            fontSize: '20px',
            lineHeight: '28px',
            fontWeight: '700',
            color: '#161A1D', /* Default color for values */
        },
        listingPriceLabel: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '700',
            color: '#161A1D', /* Gray color for labels */
        },
        listingPriceValue: {
            fontSize: '20px',
            lineHeight: '28px',
            fontWeight: '700',
            color: '#161A1D', /* Default color for values */
        },
        home8PriceSqftItem: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: 0,
        },
        home8PriceSqftValue: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            textAlign: 'right' as const,
            display: 'block',
            width: '100%',
            color: '#5D6B79', /* Default color for values */
        },
        propertyCostOfOwnership: {
            backgroundColor: '#F6F7F8', // Light background color similar to what appears in the image
            borderRadius: '12px',
            fontFamily: 'Arial, sans-serif',
            paddingTop: '16px',
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingBottom: '10px',
            marginTop: '8px',
        },
        propertyCostOfOwnershipItem: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '6px'
        },
        propertyCostOfOwnershipLabel: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            color: '#5D6B79', /* Gray color for labels */
        },
        propertyCostOfOwnershipValue: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            color: '#5D6B79', /* Default color for values */
        },
        propertyBidding: {
            backgroundColor: '#F6F7F8', // Light background color similar to what appears in the image
            borderRadius: '12px',
            fontFamily: 'Arial, sans-serif',
            paddingTop: '16px',
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingBottom: '10px',
            marginTop: '8px',
        },
        propertyBiddingItem: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '6px'
        },
        propertyBiddingLabel: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            color: '#5D6B79', /* Gray color for labels */
        },
        propertyBiddingValue: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            color: '#5D6B79', /* Default color for values */
        },
        sellingPointHeader: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '700',
            marginBottom: '8px',
            color: '#13986A'
        },
        concernsPointHeader: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '700',
            marginBottom: '8px',
            marginTop: '16px',
            color: '#C40612'
        },
        propertyDetails: {
            marginTop: '16px',
            display: 'grid',
            paddingTop: '16px',
            paddingLeft: '16px',
            paddingBottom: '4px',
            gridTemplateColumns: '1fr 1fr',
            //gap: '10px 20px', /* Row gap: 10px, Column gap: 20px */
            backgroundColor: '#F6F7F8',
            borderRadius: '12px',
        },
        detailItem: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            color: '#161A1D',
            marginBottom: '12px'
        },
        icon: {
            width: '24px', /* Adjust the size of the icon */
            height: '24px',
            marginRight: '8px' /* Space between the icon and the text */
        },
        propertyImageContainer: {
            position: 'relative' as const,
            flex: 1,
            maxWidth: '500px', /* Adjust based on your design */
            borderRadius: '10px', /* Rounded corners for the container */
            overflow: 'hidden', /* Ensure content stays within the rounded corners */
            marginRight: '20px',
            marginTop: '20px'
        },
        propertyImage: {
            width: '100%',
            height: 'auto',
            borderRadius: '10px'
        },
        sellingPointlabel: {
            color: '#13986A',
            marginRight: '12px'
        },
        sellingPointCategory: {
            color: '#161A1D',
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '700'
        },
        concernlabel: {
            color: '#C40612',
            marginRight: '12px'
        },
        concernCategory: {
            color: '#161A1D',
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '700'
        },
        cardList: {
            listStyleType: 'none',
            padding: 0,
        },
        cardItem: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            marginBottom: '8px',
            color: '#5D6B79',
        },
    }

    const update_date = new Date(property.create_time);
    const { t, i18n } = useTranslation();

    // Format the time string
    const updateTimeString = update_date.toLocaleTimeString(i18n.language, {
        year: 'numeric',
        month: 'long', // "long" for full month name, "short" for abbreviated
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit', // Optional: Include seconds
        hour12: true,
    });

    const calculateDaysUntil = (date: string) => {
        const now = new Date(); // Current date and time
        const target = new Date(date); // Target date

        // Calculate the difference in time (milliseconds)
        const differenceInTime = now.getTime() - target.getTime();

        // Convert the time difference to days
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

        return differenceInDays;
    };

    const daysUntil = calculateDaysUntil(report.on_market_date);
    const formattedLiving = new Intl.NumberFormat(i18n.language).format(property.living_sqft);
    const formattedLot = new Intl.NumberFormat(i18n.language).format(property.lot_size);
    const formattedPropertyType = formatCamelCaseToTitleCase(property.property_type);

    const home8_estimate = new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // Removes cents
        maximumFractionDigits: 0  // Removes cents
    }).format(property.estimate_price);
    const home8_price_sqft = new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // Removes cents
        maximumFractionDigits: 0  // Removes cents
    }).format(property.estimate_price / property.living_sqft);

    const listing_price = new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // Removes cents
        maximumFractionDigits: 0  // Removes cents
    }).format(property.listing_price);
    const listing_price_sqft = new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // Removes cents
        maximumFractionDigits: 0  // Removes cents
    }).format(property.listing_price / property.living_sqft);

    const monthly_mortgage = report.cost_of_ownership.mortgage.toLocaleString(i18n.language, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // Removes cents
        maximumFractionDigits: 0  // Removes cents
    });

    const rent_average = new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // Removes cents
        maximumFractionDigits: 0  // Removes cents
    }).format(report.rental_estimate.average);

    return (
        <div style={styles.container}>
            <div style={styles.leftColumn}>
                <div style={styles.title}>{t('propertyReportTitle')}</div>
                <div style={styles.updateTime}>{t('lastUpdateLabel')}: {updateTimeString}</div>
                <div style={styles.propertyDetails}>
                    <div style={styles.detailItem}>
                        <img src="https://home8-gswe.onrender.com/homeIcon.png" alt="Home" style={styles.icon}/>
                        <span>{formattedPropertyType}</span>
                    </div>
                    <div style={styles.detailItem}>
                        <img src="https://home8-gswe.onrender.com/bedIcon.png" alt="Bed" style={styles.icon}/>
                        <span>{property.bedrooms} {t('bedsLabel')}</span>
                    </div>
                    <div style={styles.detailItem}>
                        <img src="https://home8-gswe.onrender.com/livingIcon.png" alt="Living" style={styles.icon}/>
                        <span>{formattedLiving} {t('sqftLabel')} {t('propertyHighlightLivingAreaLabel')}</span>
                    </div>
                    <div style={styles.detailItem}>
                        <img src="https://home8-gswe.onrender.com/bathtubIcon.png" alt="Bathtub" style={styles.icon}/>
                        <span>{property.full_bathrooms + property.half_bathrooms * 0.5} {t('bathsLabel')}</span>
                    </div>
                    <div style={styles.detailItem}>
                        <img src="https://home8-gswe.onrender.com/yardIcon.png" alt="Yard" style={styles.icon}/>
                        <span>{formattedLot} {t('sqftLabel')} {t('propertyHighlightLotLabel')}</span>
                    </div>
                    <div style={styles.detailItem}>
                        <img src="https://home8-gswe.onrender.com/constructionIcon.png" alt="Construction"
                             style={styles.icon}/>
                        <span>{t('propertyHighlightBuiltInLabel')} {report.built_year}</span>
                    </div>
                    <div style={styles.detailItem}>
                        <img src="https://home8-gswe.onrender.com/timeIcon.png" alt="Time" style={styles.icon}/>
                        <span>{daysUntil} {t('propertyHighlightDaysOnMarketLabel')}</span>
                    </div>
                </div>
                <div style={styles.propertyHighlights}>
                    <div style={styles.sellingPointHeader}>{t('propertyHighlightSellingPointLabel')}</div>
                    <ul style={styles.cardList}>
                        {
                            report && report.selling_point && report.selling_point.map((selling_point, index) =>
                                <li style={styles.cardItem}>
                                    <span style={styles.sellingPointlabel}>●</span>
                                    <span style={styles.sellingPointCategory}>{selling_point.category}</span>: {selling_point.description}
                                </li>
                            )
                        }
                    </ul>
                    <div style={styles.concernsPointHeader}>{t('propertyHighlightConcernLabel')}</div>
                    <ul style={styles.cardList}>
                        {
                            report && report.concerns && report.concerns.map((concern, index) =>
                                <li style={styles.cardItem}>
                                    <span style={styles.concernlabel}>●</span>
                                    <span style={styles.concernCategory}>{concern.category}</span>: {concern.description}
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
            <div style={styles.rightColumn}>
                <div style={styles.propertyImageContainer}>
                    <img src={property.property_thumbnail_url} alt="Property" style={styles.propertyImage}/>
                </div>
                <div style={styles.propertyPriceEstimation}>
                    <div style={styles.home8PriceEstimationItem}>
                        <span
                            style={styles.listingPriceLabel}>{t('propertyListingPriceLabel')}</span>
                        <span style={styles.listingPriceValue}>{listing_price}</span>
                    </div>
                    <div style={styles.home8PriceSqftItem}>
                        <span style={styles.home8PriceSqftValue}>{listing_price_sqft}/{t('sqftLabel')}</span>
                    </div>
                    {/* Gray line */}
                    <hr className="border-gray-200 my-4"/>
                    <div style={styles.propertyPriceEstimationTitle}>{t('propertyEstimateValueLabel')}
                    </div>
                    <div style={styles.propertyPriceEstimationItem}>
                        <span
                            style={styles.propertyPriceEstimationLabel}>{t('propertyEstimateValueBaseEstimationLabel')}</span>
                        <span style={styles.propertyPriceEstimationValue}>{t('comingSoonLabel')}</span>
                    </div>
                    <div style={styles.propertyPriceEstimationItem}>
                        <span
                            style={styles.propertyPriceEstimationLabel}>{t('propertyEstimateValueRepairCostLabel')}</span>
                        <span style={styles.propertyPriceEstimationValue}>{t('comingSoonLabel')}</span>
                    </div>
                    <div style={styles.propertyPriceEstimationItem}>
                        <span
                            style={styles.propertyPriceEstimationLabel}>{t('propertyEstimateValueDepreciationFactorsLabel')}</span>
                        <span style={styles.propertyPriceEstimationValue}>{t('comingSoonLabel')}</span>
                    </div>
                    <hr className="border-gray-200 my-3 w-[100px] ml-auto"/>
                    <div style={styles.home8PriceEstimationItem}>
                        <span
                            style={styles.home8PriceEstimationLabel}>{t('propertyEstimateValueEstimatePriceLabel')}</span>
                        <span style={styles.home8PriceEstimationValue}>{home8_estimate}</span>
                    </div>
                    <div style={styles.home8PriceSqftItem}>
                        <span style={styles.home8PriceSqftValue}>{home8_price_sqft}/{t('sqftLabel')}</span>
                    </div>
                </div>
                <div style={styles.propertyCostOfOwnership}>
                    <div style={styles.propertyCostOfOwnershipItem}>
                        <span style={styles.propertyCostOfOwnershipLabel}>{t('propertyCostOfOwnershipLabel')}</span>
                        <span style={styles.propertyCostOfOwnershipValue}>~{monthly_mortgage}/{t('monthUnitLabel')}</span>
                    </div>
                    <div style={styles.propertyCostOfOwnershipItem}>
                        <span style={styles.propertyCostOfOwnershipLabel}>{t('propertyRentalEstimateLongTermRentLabel')}</span>
                        <span style={styles.propertyCostOfOwnershipValue}>~{rent_average}/{t('monthUnitLabel')}</span>
                    </div>
                    <div style={styles.propertyCostOfOwnershipItem}>
                        <span style={styles.propertyCostOfOwnershipLabel}>{t('propertyRentalEstimateShortTermRentLabel')}</span>
                        <span style={styles.propertyCostOfOwnershipValue}>~{rent_average}/{t('monthUnitLabel')}</span>
                    </div>
                </div>
                {/*<div style={styles.propertyBidding}>
                    <div style={styles.propertyBiddingItem}>
                        <span style={styles.propertyBiddingValue}>{t('propertyHighlightWinBiddingWarLabel')}</span>
                    </div>
                    <div style={styles.propertyBiddingItem}>
                        <span style={styles.propertyBiddingLabel}>30%</span>
                        <span style={styles.propertyBiddingValue}>{t('comingSoonLabel')}</span>
                    </div>
                    <div style={styles.propertyBiddingItem}>
                        <span style={styles.propertyBiddingLabel}>50%</span>
                        <span style={styles.propertyBiddingValue}>{t('comingSoonLabel')}</span>
                    </div>
                    <div style={styles.propertyBiddingItem}>
                        <span style={styles.propertyBiddingLabel}>70%</span>
                        <span style={styles.propertyBiddingValue}>{t('comingSoonLabel')}</span>
                    </div>
                    <div style={styles.propertyBiddingItem}>
                        <span style={styles.propertyBiddingLabel}>90%</span>
                        <span style={styles.propertyBiddingValue}>{t('comingSoonLabel')}</span>
                    </div>
                </div>*/}
            </div>
        </div>
    )
}

export default PropertyHighlightCard;