import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    //.use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        debug: true,
        resources: {
            en: {
                translation: {
                    menuAllProperties: "All properties",
                    menuMyOffer: "My offer",
                    menuHouseTour: "Property tour",
                    menuRenovationConsultation: "Renovation consultation",
                    menuMortgage: "Mortgage",
                    menuTransaction: "Transaction",
                    requestPropertyReport: "Request property report",
                    requestPropertyReportNote: "Note: we can only obtain data for properties that are currently on the market",
                    requestPropertyReportAddressLabel: "Property address:",
                    requestPropertyReportNotifNote: "When property report is ready, notify me with:",
                    requestPropertyReportSendNote: "Send request",
                    searchPropertyHint: "Search property by address, zip code, city",
                    draftNewOfferLabel: "Buyer self-written offer + expert review",
                    draftNewOfferNote: "0 commission fee",
                    expertOfferLabel: "Video chat with expert",
                    expertOfferNote: "Free consultation | Mon-Fri 9am-5pm",
                    onMarketLabel: "On market",
                    lastUpdateLabel: "Last update",
                    offerSearchPropertyTitle: "Find the property you'd like to place an offer on",
                    offerSearchPropertyNoteLabel: "Note: Currently, Home8 only assist with property purchases in California. We plan to expand our services across the entire U.S. in 2025.",
                    offerCheckAvailabilityLabel: "Check availability",
                    offerSearchAnotherPropertyLabel: "Search another property",
                    offerSearchPropertyContinueLabel: "Continue",
                    offerSearchNoResultLabel: "No result found",
                    offerSearchNoResultHintLabel: "We apologize, but Home8 currently does not support self-service offers for this property. If you still wish to send an offer, please contact our expert to draft offer for this property.",
                    offerDueDateLabel: "Due date",
                    offerExpirationLabel: "Offer expire",
                    offerStatusDraftLabel: "Buyer draft offer",
                    offerStatusDraftReviewLabel: "Offer draft review by expert",
                    offerStatusDraftReviewDescriptionLabel: "Usually 3 business hours. Or ask expert if you need an expedited process outside of business hours.",
                    offerStatusDraftReviewFailDescriptionLabel: "Please contact expert for details",
                    offerStatusSignLabel: "Buyer sign offer in Docusign",
                    offerStatusSignCTALabel: "Sign offer",
                    offerStatusSendLabel: "Buyer send offer to listing agent",
                    offerStatusSendCTALabel: "Send offer to listing agent",
                    offerStatusSendHintLabel: "Usually get response in 48 hrs",
                    offerStatusNegotiationLabel: "Offer under negotiation",
                    offerStatusNegotiationHintLabel: "Supported by expert",
                    offerStatusOfferAcceptedLabel: "Accepted",
                    offerStatusOfferAcceptedSubLabel: "by seller",
                    offerStatusOfferDeclinedLabel: "Rejected",
                    offerStatusOfferDeclinedSubLabel: "by seller",
                    offerStatusOfferExpiredLabel: "Never heard back",
                    offerStatusOfferExpiredSubLabel: "from listing agent",
                    offerTalkToExpertLabel: "Chat with expert",
                    offerLastSubmittedTimeLabel: "Last update",
                    offerYourPriceLabel: "Your price",
                    offerLabel: "Offer",
                    offerEmailTrackerLabel: "Email tracker",
                    offerEmailTrackerNotOpenLabel: "not read yet",
                    offerEmailTrackerOpenLabel: "read",
                    offerEditSubLabel: "Any changes need expert review",
                    offerSendOfferRequireActionLabel: "[Action required] Send offer",
                    offerSendOfferRequireActionSubLabel: "To listing agent",
                    offerSignOfferRequireActionLabel: "[Action required] Sign offer",
                    offerDraftOfferContinueLabel: "Continue editing",
                    offerContinueLabel: "Continue",
                    offerUpdateStatusLabel: "Update your offer status",
                    offerExpertNegotiationSupportLabel: "Book video chat with expert for negotiation support",
                    offerEditLabel: "How would you like to edit your offer?",
                    offerContinueEditLabel: "Continue editing",
                    offerEditMinimumChangeLabel: "Minimum changes",
                    offerEditMinimumChangeScopeLabel: "You can change:",
                    offerEditMinimumChangeOfferPriceLabel: "Offer price",
                    offerEditMinimumChangeOfferExpirationLabel: "Offer expiration time",
                    offerEditMinimumChangeExpertReviewNoteLabel: "No expert review required; you will receive the updated offer document immediately.",
                    offerEditSignificantChangeLabel: "Significant changes",
                    offerEditSignificantChangeReviewNoteLabel: "You can modify everything from your previous offer version. This update will require another round of expert review, which typically takes 3 business hours.",
                    expertAvailableLabel: "Mon-Fri 9am-5pm",
                    propertyReportTitle: "Property report - Unbiased data and in-depth insights",
                    propertyDataSourceLabel: "Data source",
                    propertyPublicDataLabel: "Public record",
                    propertyHighlightLabel: "Property Highlight",
                    propertyHighlightLivingAreaLabel: "living",
                    propertyHighlightLotLabel: "lot",
                    propertyHighlightBuiltInLabel: "Built in",
                    propertyHighlightDaysOnMarketLabel: "days on market",
                    propertyHighlightWinBiddingWarLabel: "Win the bidding war",
                    propertyHighlightSellingPointLabel: "Selling point",
                    propertyHighlightConcernLabel: "Concerns",
                    propertyDeepdiveLabel: "Property deep dive",
                    propertyInspectionReportLabel: "Inspection report",
                    propertyTermiteReportLabel: "Termite report",
                    propertyBackgroundStoriesLabel: "Background stories",
                    propertySellerPropertyQuestionaireLabel: "Seller property questionaire",
                    propertyTransactionDisclosureStatementLabel: "Transaction disclosure statement",
                    propertyReasonForSellingLabel: "Reason for selling",
                    propertyUpgradesAndRenovationsLabel: "Recent upgrades and renovations",
                    propertyPermitHistoryLabel: "Permit history",
                    propertyDiscrepanciesLabel: "Discrepancies",
                    propertyLegalAndTileLabel: "Legal and title",
                    propertyPlotMapIntroduction: "Below is the plot map",
                    propertyPrelimReportLabel: "Prelim report",
                    propertyNatureHazardDisclosureLabel: "Nature hazard disclosure",
                    propertyNatureHazardRisksLabel: "Nature hazard risks",
                    propertyEnvironmentalPollutionLabel: "Environmental pollution",
                    propertyPublicSchoolLabel: "Public schools",
                    communityDeepdiveLabel: "Community deep dive",
                    communityNeighborhoodLabel: "Neighborhood",
                    communitySchoolLabel: "School information",
                    communityCommuteTimeLabel: "Commute time",
                    communityAmenitiesLabel: "Amenities",
                    communityUnityProvidersLabel: "Unity providers",
                    financialAndMarketAnalysisLabel: "Financial and market analysis",
                    propertyEstimateValueLabel: "Estimate appraisal value",
                    propertyEstimateValueBaseEstimationLabel: "Base estimation",
                    propertyEstimateValueRepairCostLabel: "Repair cost",
                    propertyEstimateValueDepreciationFactorsLabel: "Depreciation factors",
                    propertyEstimateValueEstimatePriceLabel: "Total",
                    propertyListingPriceLabel: "Listing price",
                    propertyComparableSalesLabel: "Comparable sales",
                    propertyComparableSalesHistoricalHighestLabel: "Historical highest",
                    propertyComparableSalesCurrentComparableSalesLabel: "Current comparable sales",
                    propertyComparableSalesListingLabel: "Listing",
                    propertyComparableSalesSoldLabel: "Sold",
                    propertyMarketTrendLabel: "Market trend",
                    propertyCostOfOwnershipLabel: "Cost of ownership",
                    propertyCostOfOwnershipTotalMonthlyCostLabel: "Total Monthly Cost of Ownership",
                    propertyCostOfOwnershipDownPaymentLabel: "Down Payment",
                    propertyCostOfOwnershipLoanAmountLabel: "Loan Amount",
                    propertyCostOfOwnershipMonthlyMortgagePaymentLabel: "Monthly Mortgage Payment",
                    propertyCostOfOwnershipMonthlyPropertyTaxLabel: "Monthly Property Tax",
                    propertyCostOfOwnershipMonthlyInsuranceLabel: "Monthly Insurance",
                    propertyRentalEstimateLabel: "Rental estimate",
                    propertyRentalEstimateLongTermRentLabel: "Long term rent",
                    propertyRentalEstimateShortTermRentLabel: "Short term rent",
                    propertyListingAndTransactionHistoryLabel: "Listing and transaction history",
                    propertyListingAgentContactLabel: "Listing agent contact",
                    propertyOfferInsightsLabel: "Offer insights",
                    propertyViewOfferLabel: "View my current offer status",
                    propertyViewOfferHintLabel: "Continue where you left off",
                    propertyStatusOnSaleLabel: "On sale",
                    propertyStatusPendingLabel: "Pending",
                    propertyStatusContingentLabel: "Contingent",
                    propertyStatusSoldLabel: "Sold",
                    chatbotOpenQuestion: "How much does it cost to fix the problems?",
                    chatbotQuestionStarter: "Type your question",
                    chatbotAgentDescriptionLabel: "Your home-buying advisor",
                    editLabel: "Edit",
                    viewLabel: "View",
                    createNewLabel: "Create new",
                    comingSoonLabel: "Coming soon",
                    monthUnitLabel: "mo",
                    sqftLabel: "sqft",
                    bedsLabel: "beds",
                    bathsLabel: "baths",
                    bedsShortLabel: "B",
                    bathsShortLabel: "B",
                    askLabel: "Ask",
                    listingAgentLabel: "Listing agent",
                    permitDescription: "Permit description",
                    permitClassifications: "Permit classifications",
                    permitNumber: "Permit number",
                    permitStatus: "Status",
                    permitStatusDate: "Status date",
                    permitJobValue: "Job value",
                    permitContractors: "Contractors",
                    listingAgentPhoneLabel: "Personal",
                    listingOffMarketDate: "Off market date",
                    listingOriginalListingPriceLabel: "Original listing price",
                    listingFinalListingPriceLabel: "Final listing price",
                    listingMLSListingIDLabel: "MLS listing ID",
                    transactionBuyerLabel: "Buyer",
                    transactionSellerLabel: "Seller",
                    transactionTitleCompany: "Title company",
                    botAskAILabel: "Ask AI",
                    botAskAINoteLabel: "Any question, any time",
                    botAskExpertLabel: "Ask expert",
                    botVideoLabel: "Video chat with expert",
                    botVideoNoteLabel: "Free consultation | Mon-Fri 9am-5pm",
                    sampleQuestionProblem: "Any problem that can not be fixed?",
                    sampleQuestionTSection: "Is the porperty facing T-section or xxx?", // To be fixed
                    sampleQuestionStructure: "Any foundation/structure issue?",
                    sampleQuestionHVAC: "Any issue with HVAC?",
                    sampleQuestionRoof: "What's the condition of the roof?",
                    sampleQuestionLeakage: "Any leakage?",
                    sampleQuestionElectrical: "Any electrical issue?",
                    sampleQuestionFireZone: "Is the property located on fire zone/flood zone/superfund?",
                    sampleQuestionTermite: "What's the quote for termite issues?",
                    sampleQuestionDeath: "Any death on property?",
                    sampleQuestionRenovation: "What addition/remodel/update/improvement did the seller do? With a permit?",
                    sampleQuestionModification: "Any addition or modification of floor plan without permit?",
                    sampleQuestionEasement: "Any easement?",
                    sampleQuestionNoise: "Any noise issue?",
                    sampleQuestionPollution: "Is the property located on near superfund/gas pipeline?",
                }
            },
            zh: {
                translation: {
                    menuAllProperties: "房产报告",
                    menuMyOffer: "我的报价",
                    menuHouseTour: "看房",
                    menuRenovationConsultation: "装修咨询",
                    menuMortgage: "贷款",
                    menuTransaction: "交易",
                    requestPropertyReport: "获取房产报告",
                    requestPropertyReportNote: "注意: 我们只能获取正在上市出售的房产的数据",
                    requestPropertyReportAddressLabel: "房产地址：",
                    requestPropertyReportNotifNote: "当报告准备好时，用下面的方式通知我:",
                    requestPropertyReportSendNote: "发送请求",
                    searchPropertyHint: "通过地址，邮编，城市搜索房产",
                    draftNewOfferLabel: "买家自助报价+专家审核",
                    draftNewOfferNote: "免佣金",
                    expertOfferLabel: "和专家视频通话",
                    expertOfferNote: "免费咨询 | 周一到周五 9am-5pm",
                    onMarketLabel: "出售中",
                    lastUpdateLabel: "上次更新",
                    offerSearchPropertyTitle: "查询您想报价的房子",
                    offerSearchPropertyNoteLabel: "注意：现在Home8仅支持加州的房产购买。我们计划在2025年持续推向全美。",
                    offerCheckAvailabilityLabel: "查询",
                    offerSearchAnotherPropertyLabel: "查询其他房产",
                    offerSearchPropertyContinueLabel: "继续",
                    offerSearchNoResultLabel: "无结果",
                    offerSearchNoResultHintLabel: "很抱歉Home8暂时不支持为这个房子自助报价。如果仍想为这个房产报价，请联系我们的客服为您起草报价。",
                    offerDueDateLabel: "截止时间",
                    offerExpirationLabel: "报价到期时间",
                    offerStatusDraftLabel: "买家起草报价",
                    offerStatusDraftReviewLabel: "专家审核报价草稿",
                    offerStatusDraftReviewDescriptionLabel: "通常会在3小时内(工作日9am-6pm)完成审核，想要加速请联系后台",
                    offerStatusDraftReviewFailDescriptionLabel: "请联系平台给您安排的经纪人",
                    offerStatusSignLabel: "买家对报价电子签",
                    offerStatusSignCTALabel: "电子签名",
                    offerStatusSendLabel: "买家发送报价给卖家经纪人",
                    offerStatusSendCTALabel: "发送报价给卖家经纪人",
                    offerStatusSendHintLabel: "通常卖家经纪人会在48小时之内回复",
                    offerStatusNegotiationLabel: "报价协商中",
                    offerStatusNegotiationHintLabel: "由议价专家支持",
                    offerStatusOfferAcceptedLabel: "接受",
                    offerStatusOfferAcceptedSubLabel: "被卖方",
                    offerStatusOfferDeclinedLabel: "拒绝",
                    offerStatusOfferDeclinedSubLabel: "被卖方",
                    offerStatusOfferExpiredLabel: "没消息",
                    offerStatusOfferExpiredSubLabel: "从卖方经纪人",
                    offerTalkToExpertLabel: "联系专家",
                    offerYourPriceLabel: "您的出价",
                    offerLastSubmittedTimeLabel: "上次更新",
                    offerLabel: "报价",
                    offerEmailTrackerLabel: "报价邮件追踪",
                    offerEmailTrackerNotOpenLabel: "邮件未打开",
                    offerEmailTrackerOpenLabel: "邮件已打开",
                    offerEditSubLabel: "任何修改都需要专家审核",
                    offerSendOfferRequireActionLabel: "[需要行动] 发送报价",
                    offerSendOfferRequireActionSubLabel: "给卖方经纪人",
                    offerSignOfferRequireActionLabel: "[需要行动] 电子签名",
                    offerDraftOfferContinueLabel: "继续编辑",
                    offerContinueLabel: "继续",
                    offerUpdateStatusLabel: "更新您的报价状态",
                    offerExpertNegotiationSupportLabel: "和议价专家预约视频会议",
                    offerEditLabel: "您想如何修改报价？",
                    offerContinueEditLabel: "继续编辑",
                    offerEditMinimumChangeLabel: "小改动",
                    offerEditMinimumChangeScopeLabel: "小改动包括：",
                    offerEditMinimumChangeOfferPriceLabel: "价格",
                    offerEditMinimumChangeOfferExpirationLabel: "报价有效时间",
                    offerEditMinimumChangeExpertReviewNoteLabel: "无需专家审核；您即刻可以拿到修改完成的报价文件。",
                    offerEditSignificantChangeLabel: "大改动",
                    offerEditSignificantChangeReviewNoteLabel: "您可以修改上一次报价中的任何内容。此次修改须经过专家审核，通常需要3工作小时。",
                    expertAvailableLabel: "周一到周五 9am-5pm",
                    propertyReportTitle: "房产报告 - 公正数据和深度洞察",
                    propertyDataSourceLabel: "数据源",
                    propertyPublicDataLabel: "公开数据",
                    propertyHighlightLabel: "房产亮点",
                    propertyHighlightLivingAreaLabel: "居住面积",
                    propertyHighlightLotLabel: "土地面积",
                    propertyHighlightBuiltInLabel: "建于",
                    propertyHighlightDaysOnMarketLabel: "天已待售",
                    propertyHighlightWinBiddingWarLabel: "赢得议价",
                    propertyHighlightSellingPointLabel: "卖点",
                    propertyHighlightConcernLabel: "问题",
                    propertyDeepdiveLabel: "房产深度分析",
                    propertyInspectionReportLabel: "房产检查报告",
                    propertyTermiteReportLabel: "房产白蚁报告",
                    propertyBackgroundStoriesLabel: "背景信息",
                    propertySellerPropertyQuestionaireLabel: "卖家房屋问卷",
                    propertyTransactionDisclosureStatementLabel: "交易披露信息表",
                    propertyReasonForSellingLabel: "卖房原因",
                    propertyUpgradesAndRenovationsLabel: "近期维修/装修/加建",
                    propertyPermitHistoryLabel: "建筑许可证",
                    propertyDiscrepanciesLabel: "与公开数据不一致的信息",
                    propertyLegalAndTileLabel: "产权相关信息",
                    propertyPlotMapIntroduction: "产权地图",
                    propertyPrelimReportLabel: "产权调查报告",
                    propertyNatureHazardDisclosureLabel: "自然灾害报告",
                    propertyNatureHazardRisksLabel: "自然灾害风险",
                    propertyEnvironmentalPollutionLabel: "环境污染",
                    propertyPublicSchoolLabel: "公立学校",
                    propertyViewOfferLabel: "查看报价",
                    propertyViewOfferHintLabel: "继续您之前的进度",
                    communityDeepdiveLabel: "社区深度分析",
                    communityNeighborhoodLabel: "社区概述",
                    communitySchoolLabel: "学区信息",
                    communityCommuteTimeLabel: "交通",
                    communityAmenitiesLabel: "周边配套",
                    communityUnityProvidersLabel: "水电煤气和垃圾",
                    financialAndMarketAnalysisLabel: "财务和市场分析",
                    propertyEstimateValueLabel: "预评估价格",
                    propertyEstimateValueBaseEstimationLabel: "基础估价",
                    propertyEstimateValueRepairCostLabel: "维修价格",
                    propertyEstimateValueDepreciationFactorsLabel: "折价",
                    propertyEstimateValueEstimatePriceLabel: "总共",
                    propertyListingPriceLabel: "挂牌价",
                    propertyComparableSalesLabel: "近期可比交易记录",
                    propertyComparableSalesHistoricalHighestLabel: "历史最高价",
                    propertyComparableSalesCurrentComparableSalesLabel: "近期可比交易",
                    propertyComparableSalesListingLabel: "挂牌价",
                    propertyComparableSalesSoldLabel: "售价",
                    propertyMarketTrendLabel: "市场走势",
                    propertyCostOfOwnershipLabel: "持有成本",
                    propertyCostOfOwnershipTotalMonthlyCostLabel: "平均月支出",
                    propertyCostOfOwnershipDownPaymentLabel: "首付",
                    propertyCostOfOwnershipLoanAmountLabel: "贷款额",
                    propertyCostOfOwnershipMonthlyMortgagePaymentLabel: "月供",
                    propertyCostOfOwnershipMonthlyPropertyTaxLabel: "月地产税",
                    propertyCostOfOwnershipMonthlyInsuranceLabel: "月保险金",
                    propertyRentalEstimateLabel: "租金评估",
                    propertyRentalEstimateLongTermRentLabel: "长租",
                    propertyRentalEstimateShortTermRentLabel: "短租",
                    propertyListingAndTransactionHistoryLabel: "上市和交易历史",
                    propertyListingAgentContactLabel: "卖房经纪联系方式",
                    propertyOfferInsightsLabel: "出价指导信息",
                    propertyStatusOnSaleLabel: "出售中",
                    propertyStatusPendingLabel: "Pending",
                    propertyStatusContingentLabel: "Contingent",
                    propertyStatusSoldLabel: "已售出",
                    chatbotOpenQuestion: "需要花多少钱来修复房屋问题？",
                    chatbotQuestionStarter: "输入你的问题",
                    chatbotAgentDescriptionLabel: "你的买房顾问",
                    editLabel: "修改",
                    viewLabel: "查看",
                    createNewLabel: "新建",
                    comingSoonLabel: "开发中",
                    monthUnitLabel: "月",
                    sqftLabel: "平方英尺",
                    bedsLabel: "卧室",
                    bathsLabel: "浴室",
                    bedsShortLabel: "卧",
                    bathsShortLabel: "浴",
                    askLabel: "询问",
                    listingAgentLabel: "卖方经纪人",
                    permitDescription: "许可证描述",
                    permitClassifications: "许可证分类",
                    permitNumber: "许可证编号",
                    permitStatus: "状态",
                    permitStatusDate: "状态生效日期",
                    permitJobValue: "项目费用",
                    permitContractors: "承包商",
                    listingAgentPhoneLabel: "电话",
                    listingOffMarketDate: "下市日期",
                    listingOriginalListingPriceLabel: "初始挂牌价格",
                    listingFinalListingPriceLabel: "最终挂牌价格",
                    listingMLSListingIDLabel: "挂牌编号(MLS listing ID)",
                    transactionBuyerLabel: "买家",
                    transactionSellerLabel: "卖家",
                    transactionTitleCompany: "产权公司",
                    botAskAILabel: "问AI",
                    botAskAINoteLabel: "任何问题，任何时间",
                    botAskExpertLabel: "问专家",
                    botVideoLabel: "和专家视频通话",
                    botVideoNoteLabel: "免费咨询 | 周一到周五 9am-5pm",
                    sampleQuestionProblem: "有什么硬伤吗？",
                    sampleQuestionTSection: "有路冲/反弓煞吗？",
                    sampleQuestionStructure: "有地基/结构问题吗？",
                    sampleQuestionHVAC: "暖气/空调/热水器需要维修吗？",
                    sampleQuestionRoof: "屋顶需要维修吗？",
                    sampleQuestionLeakage: "有漏水吗？",
                    sampleQuestionElectrical: "有电路问题吗？",
                    sampleQuestionFireZone: "在火险区/水淹区/Superfund上吗？",
                    sampleQuestionTermite: "白蚁问题需要多少钱维修？",
                    sampleQuestionDeath: "有人在房屋中死亡吗？",
                    sampleQuestionRenovation: "做过哪些装修加建？有permit吗？",
                    sampleQuestionModification: "有违法加建改建吗？",
                    sampleQuestionEasement: "有地役权吗？",
                    sampleQuestionNoise: "有噪音问题吗？",
                    sampleQuestionPollution: "在Superfund/Gas pipeline 附近吗？",
                }
            }
        }
    });

export default i18n;