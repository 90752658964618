

export const formatUnixTimestamp = (unixTimestamp: number) => {
    // Convert Unix epoch time (in seconds) to milliseconds for the Date constructor
    const date = new Date(unixTimestamp * 1000);

    // Extract date components
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    // Extract time components
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12 || 12; // Convert hour '0' to '12' for 12-hour format

    // Format the final timestamp
    const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}${ampm}`;
    return formattedDate;
};


export const formatIsoTimestamp = (isoString: string) => {
    const date = new Date(isoString);

    // Extract date components
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    // Extract time components
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    hours = hours % 12 || 12; // Convert hour '0' to '12' for 12-hour format

    // Format the final timestamp
    const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}${ampm}`;
    return formattedDate;
};
