import { useMutation } from '@tanstack/react-query';
import qs from 'qs';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../store/auth';
import { axiosWithAuth, axiosWithoutAuth } from '../utils/axios';

interface SendCodeRequest {
  email: string;
}

export const useSendCode = () => {
  const axios = axiosWithoutAuth();

  const sendCode = async ({ email }: SendCodeRequest) => {
    try {
      await axios.post(
        'auth/send_code',
        {
          email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

    } catch {
    } 
  };
  const { mutateAsync } = useMutation(sendCode);
  return { mutateAsync };
};
