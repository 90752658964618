import {Status} from "./OfferCard";
import {Contractor} from "../store/agentInfo";


type EnvironmentalPollution = {
    issue: string
    status: string
    description: string
};

function EnvironmentalPollutionCard({
                                  issue,
                                  status,
                                  description
                              }: EnvironmentalPollution) {
    const styles = {
        card: {
            backgroundColor: '#F6F7F8', // Light background color similar to what appears in the image
            paddingTop: '12px',
            paddingLeft: '16px',
            paddingBottom: '16px',
            borderRadius: '12px',
            maxWidth: '400px',
            fontFamily: 'Arial, sans-serif',
        },
        title: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '700',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
        },
        status: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '700',
            color: '#FF7A00', // Orange color for the "Yes" indicator
        },
        description: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            color: '#161A1D',
        },
    };

    return (
        <div style={styles.card}>
            <div style={styles.header}>
                <h3 style={styles.title}>{issue}</h3>
                <span style={styles.status}>{status}</span>
            </div>
            <p style={styles.description}>{description}</p>
        </div>
    );
}

export default EnvironmentalPollutionCard;