import React from "react";
import {useTranslation} from "react-i18next";

type RentalEstimate = {
    low: number
    average: number
    high: number
}


function RentalEstimateCard({
    low,
    average,
    high
}: RentalEstimate) {
    const styles = {
        rentRow: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '4px',
        },
        rentLabel: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            color: '#161A1D',
        },
        rentValue: {
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '18px',
            color: '#161A1D',
            textAlign: 'right' as const,
            marginRight: '12px',
            minWidth: '150px',  // Ensures all values are aligned on the right
        },
    };

    const rent_average = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // Removes cents
        maximumFractionDigits: 0  // Removes cents
    }).format(average);

    const { t, i18n } = useTranslation();

    return (
        <>
            <div style={styles.rentRow}>
                <div style={styles.rentLabel}>{t('propertyRentalEstimateLongTermRentLabel')}</div>
                <div style={styles.rentValue}>{rent_average}/{t('monthUnitLabel')}</div>
            </div>
            <div style={styles.rentRow}>
                <div style={styles.rentLabel}>{t('propertyRentalEstimateShortTermRentLabel')}</div>
                <div style={styles.rentValue}>{rent_average}/{t('monthUnitLabel')}</div>
            </div>
        </>
    );
}

export default RentalEstimateCard;