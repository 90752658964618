import { useMutation } from '@tanstack/react-query'
import useAgentInfoStore from '../store/agentInfo'
import useAuthStore from '../store/auth'
import {axiosAdminWithoutAuth, axiosWithAuth} from '../utils/axios'
interface ReviewPropertyRequest {
    propertyId: string
}

export const useAdminReviewProperty = () => {
    const axios = axiosAdminWithoutAuth()
    const unpublishProperty = async ({ propertyId }: ReviewPropertyRequest) => {
        try {
            const data = {
                'property_id': propertyId,
            }
            const res = await axios.post('review_property', data)
            return res
        } catch (e) {
            return null
        } finally {
        }
    }

    const { mutateAsync } = useMutation(unpublishProperty)
    return { mutateAsync }
}