

type UtilityProvider = {
    category: string
    vendor: string
    contact: string
    website: string
};

function UtilityCard({
    category,
    vendor,
    contact,
    website
}: UtilityProvider) {
    const styles = {
        container: {
            fontFamily: 'Arial, sans-serif',
            maxWidth: '900px',
            margin: '0 auto',
            padding: '20px',
        },
        header: {
            marginBottom: '20px',
        },
        title: {
            fontSize: '24px',
            fontWeight: 'bold',
        },
        dataSource: {
            fontSize: '14px',
            color: '#5D6B79',
        },
        lastUpdate: {
            fontSize: '14px',
            color: '#5D6B79',
            marginTop: '4px',
        },
        grid: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '20px',
        },
        card: {
            backgroundColor: '#F6F7F8',
            padding: '15px',
            borderRadius: '12px',
        },
        cardTitle: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '700',
            color: '#161A1D',
            marginBottom: '16px',
        },
        cardList: {
            listStyleType: 'none',
            padding: 0,
        },
        cardItem: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            marginBottom: '8px',
            color: '#161A1D',
        },
        link: {
            color: '#007BFF',
            textDecoration: 'none',
        },
        label: {
            color: '#B1BAC3',
            marginRight: '12px'
        }
    };

    return (
        <div style={styles.card}>
            <h3 style={styles.cardTitle}>{category}</h3>
            <ul style={styles.cardList}>
                <li style={styles.cardItem}><span style={styles.label}>●</span>{vendor}</li>
                <li style={styles.cardItem}><span style={styles.label}>●</span>
                    <a href={`${website}`} style={styles.link}>{website}</a>
                </li>
                <li style={styles.cardItem}><span style={styles.label}>●</span>{contact}</li>
            </ul>
        </div>
    );
}

export default UtilityCard;