import { useParams } from 'react-router-dom'
import { MoonLoader } from 'react-spinners'
import Navbar from '../components/Navbar'
import { useAdminConversationHistoryById } from '../query/useAdminConversationHistory'
import agentAvatar from '../static/AgentAvatar.svg';
import customerAvatar from '../static/CustomerAvatar.svg';
import useAuthStore from '../store/auth';
import Login from './Login';

function AdminConversationHistory() {
  const { conversationId } = useParams()
  const { data, isFetching, isLoading } = useAdminConversationHistoryById(conversationId)
  const { isLogin } = useAuthStore()
  if (!isLogin) {
    return <Login/>
  }
  return (
    <div className="flex justify-center pt-[156px] pb-[200px]">
      <Navbar />
      <div className="flex flex-col items-center w-[1024px] gap-6">
        <div className="flex items-center justify-start mb-[40px] w-full px-6 py-4">
          <div className="text-[40px] font-bold">Property chat transcript</div>
        </div>
        {isFetching || isLoading ? (
          <div className="flex min-h-[200px] items-center">
            <MoonLoader size={24} />
          </div>
        ) : (
          <div className="flex flex-col gap-6 w-full">
            {data.conversation.map((message: any) => {
              if (message.role !== 'human' && message.role != 'ai') {
                return null
              }
              return <div className='flex items-start w-full gap-3'>
                {message.role === 'ai' ? <img src={agentAvatar} /> : <img src={customerAvatar} />}
                <div className='grow flex flex-col gap-1'>
                  <div className='text-base font-bold'>{message.role === 'ai' ? "Property Bot" : "Customer"}</div>
                  <div className='text-base'>{message.content}</div>
                </div>
              </div>
            })}
          </div>
        )}
      </div>
    </div>
  )
}
export default AdminConversationHistory
