import Cookies from 'js-cookie'
import { create } from 'zustand'

type AuthStore = {
  loading: boolean
  setLoading: (value: boolean) => void
  successModalOpen: boolean
  setSuccessModalOpen: (value: boolean) => void
  errorModalOpen: boolean
  setErrorModalOpen: (value: boolean) => void
  isLogin: boolean
  setIsLogin: (value: boolean) => void
}

export interface User {
  id: number
  email: string
}

const useAuthStore = create<AuthStore>((set) => ({
  loading: false,
  setLoading: (value: boolean) => set({ loading: value }),
  successModalOpen: false,
  setSuccessModalOpen: (value: boolean) => set({ successModalOpen: value }),
  errorModalOpen: false,
  setErrorModalOpen: (value: boolean) => set({ errorModalOpen: value }),
  isLogin: Boolean(Cookies.get('admin-auth')),
  setIsLogin: (value: boolean) => set({ isLogin: value }),

}))

export default useAuthStore
