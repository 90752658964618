import Button, { ButtonType } from './Button'
import { ReactComponent as Add } from '../static/Add.svg'
import { ReactComponent as Agent } from '../static/Agent.svg'
import { ReactComponent as Callhistory } from '../static/Callhistory.svg'
import { useNavigate } from 'react-router-dom'
import {useAdminConversationHistoryByAgent} from "../query/useAdminConversationHistory";

const Navbar = ({ isPublic = false }: { isPublic?: boolean }) => {
  const navigate = useNavigate()
  const { data, isFetching, isLoading, isFetched } = useAdminConversationHistoryByAgent()
  console.log(data)
  return (
    <div className="fixed flex items-center justify-center w-full py-6 top-0 border-b border-b-gray-200 bg-white">
      <div className="flex w-[1024px] items-center justify-between">
        <div className="text-xl font-bold">Home8 Admin</div>
        {isPublic ? <></> : <div className="flex gap-4">
          {isFetched && data.length > 0 && <Button
            type={
              location.pathname === '/admin/history/all/All%20Properties'
                ? ButtonType.SELECTED
                : ButtonType.SECONDARY
            }
            Icon={Callhistory}
            content="All Chat history"
            onClick={() => {
              navigate('/admin/history/all/All Properties')
            }}
          />}
            <Button
                type={
                    location.pathname === '/admin/offer_reivew'
                        ? ButtonType.SELECTED
                        : ButtonType.SECONDARY
                }
                Icon={Agent}
                content="Review offers"
                onClick={() => { navigate('/admin/offer_reivew') }}
            />
          <Button
            type={
              location.pathname === '/admin/properties'
                ? ButtonType.SELECTED
                : ButtonType.SECONDARY
            }
            Icon={Agent}
            content="All properties"
            onClick={() => { navigate('/admin/properties') }}
          />
          <Button
            type={
              location.pathname === '/admin/create'
                ? ButtonType.SELECTED
                : ButtonType.SECONDARY
            }
            Icon={Add}
            content="Create property"
            onClick={() => { navigate('/admin/create') }}
          />
        </div>
        }
      </div>
    </div>
  )
}

export default Navbar
