import ReactMarkdown from 'react-markdown'
import { useParams } from 'react-router-dom'
import { MoonLoader } from 'react-spinners'
import remarkGfm from 'remark-gfm'
import { Message, useToaster } from 'rsuite'
import { queryClient } from '../App'
import Button, { ButtonType } from '../components/Button'
import Navbar from '../components/Navbar'
import {
  Question,
  useHouseReport,
  useRegenerateReportQuestion,
  useShareToken,
} from '../query/useHouseReport'
import useAuthStore from '../store/auth'
import Login from './Login'

function HouseReport() {
  const { agentId } = useParams()
  const { data } = useHouseReport(agentId)
  const { mutateAsync } = useRegenerateReportQuestion()
  const { data: token } = useShareToken(agentId)
  const questionList = data ?? []
  const LinkRenderer = ({ node, ...props }: any) => (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  )
  const toaster = useToaster()
  const { isLogin } = useAuthStore()
  if (!isLogin) {
    return <Login/>
  }
  return (
    <div className="flex justify-center pt-[100px] pb-5 h-full min-h-0">
      <Navbar />
      <div className="flex flex-col w-[1024px] gap-8 py-5">
        {questionList?.map((question: Question, index: number) => {
          if (question.have_future_question) {
            return null
          }
          if (question.is_generating) {
            return (
              <div className="h-[76px] flex items-center justify-center">
                <MoonLoader size={16} />
              </div>
            )
          } else {
            return (
              <div className="flex flex-col gap-3">
                <div className="flex justify-between">
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      a: LinkRenderer,
                    }}
                  >
                    {question.title_name}
                  </ReactMarkdown>
                  <div className='flex items-center gap-5'>
                    {index === 0 ? (
                      <Button
                        type={ButtonType.SECONDARY}
                        content="Share report"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            encodeURI(`https://servicegenie.ai/public-report/${token}`)
                          )
                          toaster.push(<Message type='info'>
                            Sharable link copied to clipboard.
                          </Message>, { placement: 'topCenter', duration: 2000 })
                        }}
                      />
                    ) : null}
                    {question.query !== '' ? (
                      <Button
                        type={ButtonType.SECONDARY}
                        content="Regenerate"
                        loading={question.is_generating}
                        onClick={async () => {
                          queryClient.setQueryData(
                            ['house_report', agentId],
                            (data: any) => {
                              const res = data.map((q: Question) => {
                                if (q.id === question.id) {
                                  return {
                                    ...q,
                                    is_generating: true,
                                  }
                                } else {
                                  return q
                                }
                              })
                              return res
                            },
                          )
                          await mutateAsync({
                            agentId: agentId ?? '',
                            id: question.id,
                          })
                        }}
                      />
                    ) : null}
                  </div>
                </div>
                {question.query !== '' ? (
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      a: LinkRenderer,
                    }}
                  >{question.answer}</ReactMarkdown>
                ) : (
                  <></>
                )}
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default HouseReport
