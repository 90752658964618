import React, { useState } from 'react';
import { useSendCode } from '../query/useSendCode';
import Button, { ButtonType } from './Button';
import Input from './Input';
import useAuthStore from './store/auth';
import houseimg from '../static/house.jpeg'
import { useNavigate } from 'react-router';

export type House = {
  id: string,
  bedrooms: number,
  full_bathrooms: number,
  half_bathrooms: number,
  listing_price: number,
  living_sqft: number,
  lot_size: number,
  address: string,
  property_thumbnail_url?: string
}

type Props = {
  house: House;
};

function HouseCard({ house }: Props) {
  const navigate = useNavigate()
  const formattedListingPrice= house.listing_price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0, // Removes cents
    maximumFractionDigits: 0  // Removes cents
  });
  return (
    <div className='flex flex-col gap-5 h-[362px] w-[355px] rounded-xl bg-white overflow-hidden cursor-pointer' onClick={()=> {
      navigate(`/home/property/${house.id}`)
    }}>
      <img src={house.property_thumbnail_url} className="w-auto h-[240px] object-cover" />
      <div className='flex flex-col gap-2 items-start px-4' >
        <div className='text-2xl font-bold'>{`${formattedListingPrice}`}</div>
        <div className='text-xs text-gray-600'>{`${house.address}`}</div>
        <div className='flex items-center gap-10'>
          <div className='text-xs text-gray-600'>
            {`${house.bedrooms}B${house.full_bathrooms + 0.5 * house.half_bathrooms}B`}
          </div>
          <div className='text-xs text-gray-600'>
            {`${house.living_sqft} sqft`}
          </div>
          <div className='text-xs text-gray-600'>
            {`${house.lot_size} sqft`}
          </div>
        </div>

      </div>
    </div>
  );
}

export default HouseCard;
