import { useMutation } from '@tanstack/react-query';
import qs from 'qs';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../store/auth';
import { axiosWithAuth, axiosWithoutAuth } from '../utils/axios';

interface InputCodeRequest {
  username: string;
  password: string;
}

export const useInputCode = () => {
  const axios = axiosWithoutAuth();
  const signIn = useSignIn();
  const navigate = useNavigate();

  const inputCode = async ({ username, password }: InputCodeRequest) => {
    try {
      const res = await axios.post(
        'auth/login',
        qs.stringify({
          username,
          password,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      if (res.data?.access_token) {
        signIn({
          auth: {
            token: res.data?.access_token,
          },
        });
        const redirectURL = localStorage.getItem("home8_last_visited_page")
        if (!redirectURL) {
          navigate('/home/all_properties');
        } else {
          navigate(redirectURL)
        }
        
      }
    } catch {}
  };
  const { mutateAsync } = useMutation(inputCode);
  return { mutateAsync };
};
