import {useQuery} from '@tanstack/react-query'
import {axiosAdminWithoutAuth, axiosWithAuth} from '../utils/axios'

export const useAdminPropertyReport = (propertyId: number, lang: string) => {
    const axios = axiosAdminWithoutAuth()
    return useQuery({
        queryKey: ['property_report', propertyId, lang],
        queryFn: async () => {
            try {
                const data = {
                    property_id: propertyId,
                    lang: lang,
                }
                const result = await axios.post('property_report', data)
                return result.data
            } catch (e: any) {
                return null
            }
        },
        refetchOnWindowFocus: false,
    })
}