import {useTranslation} from "react-i18next";


type ListingAgent = {
    name: string
    phone_number: string
    email: string
    company: string
    profile_url: string
}

function ListingAgentInfo({
    name,
    phone_number,
    email,
    company,
    profile_url
}: ListingAgent) {
    const { t, i18n } = useTranslation();
    const styles = {
        agentContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        agentImage: {
            width: '94px',
            height: '94px',
            borderRadius: '50%',
            marginRight: '16px',
        },
        agentDetails: {
            display: 'flex',
            flexDirection: 'column' as const,
        },
        agentName: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '700',
            color: '#161A1D',
            marginBottom: '12px',
        },
        contactDetail: {
            fontSize: '12px',
            lineHeight: '16.34px',
            fontWeight: '400',
            color: '#161A1D',
        },
        email: {
            fontSize: '14px',
            color: '#007BFF',
            textDecoration: 'none',
            margin: '5px 0',
        },
        companyName: {
            fontSize: '14px',
            color: '#5D6B79',
            margin: 0,
        },
    };

    return (
        <div style={styles.agentContainer}>
            {profile_url &&
            <img
                src={profile_url} // Replace with the actual image source
                alt="N/A"
                style={styles.agentImage}
            />
            }
            <div style={styles.agentDetails}>
                <div style={styles.agentName}>{name}</div>
                <p style={styles.contactDetail}>{t('listingAgentPhoneLabel')}: {phone_number}</p>
                <p style={styles.contactDetail}>{email}</p>
                <p style={styles.contactDetail}>{company}</p>
            </div>
        </div>
    );
}

export default ListingAgentInfo;