import { useState } from 'react';
import TreeView, {
  CLICK_ACTIONS,
  flattenTree,
  NodeId,
} from 'react-accessible-treeview';
import { ReactComponent as ArrowBottom } from '../static/ArrowBottom.svg';
import { ReactComponent as ArrowUp } from '../static/ArrowUp.svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useChatBotStore from '../store/chatbot';

type Props = {
  scrollToTarget: (ref: React.MutableRefObject<HTMLDivElement | null>) => void;
  refLists: React.MutableRefObject<HTMLDivElement | null>[];
  selectedIds: NodeId[];
  setSelectedIds: React.Dispatch<React.SetStateAction<NodeId[]>>;
  onNodeClick: () => void;
};

function PropertyDetailSidebar({
  scrollToTarget,
  refLists,
  selectedIds,
  setSelectedIds,
  onNodeClick,
}: Props) {
  const [hidden, setHidden] = useState(false);
  const { t, i18n } = useTranslation();
  const { dragging } = useChatBotStore();

  const menuItems = {
    name: '',
    children: [
      {
        name: t('propertyHighlightLabel'),
      },
      {
        name: t('propertyDeepdiveLabel'),
        children: [
          { name: t('propertyInspectionReportLabel') },
          { name: t('propertyTermiteReportLabel') },
          { name: t('propertyBackgroundStoriesLabel') },
          //{ name: t('propertyUpgradesAndRenovationsLabel') },
          { name: t('propertyPermitHistoryLabel') },
          { name: t('propertyDiscrepanciesLabel') },
          { name: t('propertyLegalAndTileLabel') },
          { name: t('propertyNatureHazardRisksLabel') },
          { name: t('propertyEnvironmentalPollutionLabel') },
          //{ name: 'Noise level' },
          //{ name: 'T section' },
          //{ name: 'Nearest High Voltage Lines' },
          //{ name: 'Potential drivers of depreciation' },
          //{ name: 'Potential drivers of appreciation' },
        ],
      },
      {
        name: t('communityDeepdiveLabel'),
        children: [
          { name: t('communityNeighborhoodLabel') },
          { name: t('communitySchoolLabel') },
          { name: t('communityCommuteTimeLabel') },
          { name: t('communityAmenitiesLabel') },
          { name: t('communityUnityProvidersLabel') },
        ],
      },
      {
        name: t('financialAndMarketAnalysisLabel'),
        children: [
          { name: t('propertyEstimateValueLabel') },
          { name: t('propertyComparableSalesLabel') },
          { name: t('propertyMarketTrendLabel') },
          { name: t('propertyCostOfOwnershipLabel') },
          { name: t('propertyRentalEstimateLabel') },
          { name: t('propertyListingAndTransactionHistoryLabel') },
        ],
      },
      {
        name: t('propertyListingAgentContactLabel'),
      },
      {
        name: t('propertyOfferInsightsLabel'),
      },
    ],
  };

  const data = flattenTree(menuItems);

  return (
    <div
      className={classNames('h-full shrink-0 bg-gray-50', {
        'overflow-auto': !dragging,
        'overflow-hidden': dragging,
      })}
    >
      <TreeView
        data={data}
        className="property-report-menu"
        multiSelect={false}
        selectedIds={selectedIds}
        onNodeSelect={(props) => {
          console.log('node seleced');
          scrollToTarget(refLists[Number(props.element.id) - 1]);
          setSelectedIds([props.element.id]);
          onNodeClick();
        }}
        clickAction={'SELECT'}
        nodeRenderer={({
          element,
          isBranch,
          isExpanded,
          isDisabled,
          getNodeProps,
          level,
          handleExpand,
          handleSelect,
        }) => {
          return (
            <div
              {...getNodeProps({
                onClick: (e) => {
                  if (!isBranch) {
                    handleSelect(e);
                  } else {
                    handleExpand(e);
                  }
                },
              })}
            >
              {isBranch && <ArrowIcon isOpen={isExpanded} />}
              <div
                className="name py-2 pr-4"
                style={{ paddingLeft: isBranch ? 0 : 24 + (level - 1) * 12 }}
              >
                {element.name}
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}

const ArrowIcon = ({ isOpen }: { isOpen: boolean }) => {
  return isOpen ? <ArrowUp /> : <ArrowBottom />;
};

export default PropertyDetailSidebar;
