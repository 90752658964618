import React from 'react';
import { MoonLoader } from 'react-spinners';

export enum ButtonType {
  PRIMARY,
  SECONDARY,
  SELECTED,
  BORDERED,
  DARK,
  LIGHT,
  BORDERED_SM,
  EXPIRED,
  WARNING,
  DONE,
  DARK_SM,
  LIGHT_SM,
  GREY
}

type Props = {
  type: ButtonType;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  content: string;
  subContent?: string;
  disabled?: boolean;
  loading?: boolean;
  widthAuto?: boolean;
  highetOverride?: string;
  widthMin?: string;
  unreadMessage?: boolean;
  onClick: () => void;
};

function Button(props: Props) {
  const {
    Icon,
    type,
    content,
    onClick,
    disabled,
    loading,
    widthAuto,
    highetOverride,
    subContent,
    widthMin,
    unreadMessage,
  } = props;
  let widthSetting = 'w-full';
  if (widthAuto) {
    widthSetting = 'w-auto';
  }
  let highetSetting = 'h-10';
  if (highetOverride && highetOverride !== '') {
    highetSetting = highetOverride;
  }
  let widthMinSetting = 'min-w-24';
  if (widthMin && widthMin !== '') {
    widthMinSetting = widthMin;
  }
  if (loading) {
    return (
      <div
        className={`bg-gray-200 ${highetSetting} ${widthMinSetting} rounded-[32px] px-4 py-2 flex gap-1 items-center cursor-not-allowed ${widthSetting} justify-center`}
      >
        {Icon && <Icon className="fill-white" />}
        <MoonLoader size={16} />
        <div className="text-gray-500">{content}</div>
      </div>
    );
  }
  if (disabled) {
    return (
      <div
        className={`bg-gray-200 ${highetSetting} ${widthMinSetting} rounded-[32px] px-4 py-2 flex gap-1 items-center cursor-not-allowed ${widthSetting} justify-center`}
      >
        {Icon && <Icon className="fill-white" />}
        <div className="text-base font-semibold text-white">{content}</div>
      </div>
    );
  }
  switch (type) {
    case ButtonType.PRIMARY:
      return (
        <div
          className={`bg-blue-500 hover:bg-blue-600 h-10 ${widthMinSetting} rounded-[32px] px-4 py-2 flex gap-1 items-center cursor-pointer w-full justify-center`}
          onClick={onClick}
        >
          {Icon && <Icon className="fill-white" />}
          <div className="text-base font-semibold text-white">{content}</div>
        </div>
      );
    case ButtonType.SECONDARY:
      return (
        <div
          className={`bg-blue-50 hover:bg-blue-50 h-10 ${widthMinSetting} rounded-[32px] px-4 py-2 flex gap-1 items-center border-blue-50 border-solid border cursor-pointer w-full justify-center`}
          onClick={onClick}
        >
          {Icon && <Icon className="fill-blue-500" />}
          <div className="text-base font-semibold text-blue-500">{content}</div>
        </div>
      );
    case ButtonType.BORDERED:
      return (
        <div
          className={`bg-white hover:bg-white h-[52px] ${widthMinSetting} rounded-[32px] px-4 py-2 flex gap-1 items-center border-gray-900 border-solid border cursor-pointer w-fit justify-center`}
          onClick={onClick}
        >
          {Icon && <Icon className="fill-gray-900" />}
          <div className="text-base font-semibold text-gray-900">{content}</div>
        </div>
      );
    case ButtonType.DARK:
      if (!subContent) {
        return (
          <div
            className={`bg-gray-900 hover:bg-gray-900 h-[52px] ${widthMinSetting} rounded-[32px] px-4 py-2 flex gap-1 items-center border-gray-900 border-solid border cursor-pointer justify-center ${widthAuto ? 'w-fit' : 'w-full'}`}
            onClick={onClick}
          >
            {Icon && <Icon className="fill-white" />}
            <div className="text-sm font-semibold text-white">{content}</div>
          </div>
        );
      }

      return (
        <div
          className={`flex items-center justify-center bg-gray-900 hover:bg-gray-900 h-[52px] ${widthMinSetting} rounded-[32px] px-6 py-2 border-gray-900 border-solid border cursor-pointer ${widthAuto ? 'w-fit' : 'w-full'} gap-3`}
          onClick={onClick}
        >
          {Icon && <Icon className="fill-white" />}
          <div className={`flex flex-col items-center justify-center`}>
            <div className="text-sm font-semibold text-white">{content}</div>
            <div className="text-xs font-light text-white">{subContent}</div>
          </div>
        </div>
      );
    case ButtonType.LIGHT:
      if (!subContent) {
        return (
          <div
            className={`bg-white hover:bg-white h-[52px] ${widthMinSetting} rounded-[32px] px-4 py-2 flex gap-1 items-center border-gray-900 border-solid border cursor-pointer justify-center ${widthAuto ? 'w-fit' : 'w-full'}`}
            onClick={onClick}
          >
            {Icon && <Icon className="fill-gray-900" />}
            <div className="text-base font-semibold text-gray-900">
              {content}
            </div>
          </div>
        );
      }

      return (
        <div
          className={`relative flex bg-white hover:bg-white h-[52px] ${widthMinSetting} rounded-[32px] px-6 py-2 items-center border-gray-900 border-solid border cursor-pointer justify-center ${widthAuto ? 'w-fit' : 'w-full'}`}
          onClick={onClick}
        >
          {Icon && <Icon className="fill-gray-900 mr-3" />}
          <div className={`flex flex-col items-center justify-center`}>
            <div className="text-sm font-semibold text-gray-900">{content}</div>
            <div className="text-xs font-light text-gray-900">{subContent}</div>
            {unreadMessage && (
              <span className="absolute top-0 right-0 block h-3 w-3 rounded-full bg-red-500"></span>
            )}
          </div>
        </div>
      );

    case ButtonType.SELECTED:
      return (
        <div className="bg-blue-50 hover:bg-blue-50 h-10 min-w-24 rounded-[32px] px-4 py-2 flex gap-1 items-center border-blue-500 border-solid border cursor-not-allowed w-full justify-center">
          {Icon && <Icon className="fill-blue-500" />}
          <div className="text-base font-semibold text-blue-500">{content}</div>
        </div>
      );

    case ButtonType.BORDERED_SM:
      return (
        <div
          className="bg-white hover:bg-white h-[42px] min-w-16 rounded-[32px] p-2 flex gap-1 items-center border-gray-900 border-solid border cursor-pointer w-fit justify-center"
          onClick={onClick}
        >
          {Icon && <Icon className="fill-gray-900" />}
          <div className="text-base font-semibold text-gray-900">{content}</div>
        </div>
      );
    case ButtonType.EXPIRED:
      if (!subContent) {
        return (
          <div
            className={`bg-white hover:bg-white h-[52px] min-w-24 rounded-[32px] px-4 py-2 flex gap-1 items-center border-gray-900 border-solid border cursor-pointer w-fit justify-center ${widthAuto ? 'w-fit' : 'w-full'}`}
            onClick={onClick}
          >
            {Icon && <Icon className="fill-gray-900" />}
            <div className="text-base font-semibold text-gray-900">
              {content}
            </div>
          </div>
        );
      }

      return (
        <div
          className={`bg-white hover:bg-white h-[52px] ${widthMinSetting} rounded-[32px] px-6 py-2 flex flex-col gap-1 items-center border-[#FF7A00] border-solid border cursor-pointer w-fit justify-center ${widthAuto ? 'w-fit' : 'w-full'}`}
          onClick={onClick}
        >
          {Icon && <Icon className="fill-gray-900" />}
          <div className="text-sm font-semibold text-[#FF7A00]">{content}</div>
          <div className="text-xs font-light text-[#FF7A00]">{subContent}</div>
        </div>
      );
    case ButtonType.WARNING:
      if (!subContent) {
        return (
          <div
            className={`bg-white hover:bg-white h-[52px] min-w-24 rounded-[32px] px-4 py-2 flex gap-1 items-center border-gray-900 border-solid border cursor-pointer w-fit justify-center ${widthAuto ? 'w-fit' : 'w-full'}`}
            onClick={onClick}
          >
            {Icon && <Icon className="fill-gray-900" />}
            <div className="text-base font-semibold text-gray-900">
              {content}
            </div>
          </div>
        );
      }

      return (
        <div
          className={`bg-white hover:bg-white h-[52px] min-w-24 rounded-[32px] px-6 py-2 flex flex-col gap-1 items-center border-[#C40612] border-solid border cursor-pointer w-fit justify-center ${widthAuto ? 'w-fit' : 'w-full'}`}
          onClick={onClick}
        >
          {Icon && <Icon className="fill-gray-900" />}
          <div className="text-sm font-semibold text-[#C40612]">{content}</div>
          <div className="text-xs font-light text-[#C40612]">{subContent}</div>
        </div>
      );
    case ButtonType.DONE:
      if (!subContent) {
        return (
          <div
            className={`bg-white hover:bg-white h-[52px] min-w-24 rounded-[32px] px-4 py-2 flex gap-1 items-center border-gray-900 border-solid border cursor-pointer w-fit justify-center ${widthAuto ? 'w-fit' : 'w-full'}`}
            onClick={onClick}
          >
            {Icon && <Icon className="fill-gray-900" />}
            <div className="text-base font-semibold text-gray-900">
              {content}
            </div>
          </div>
        );
      }

      return (
        <div
          className={`bg-white hover:bg-white h-[52px] min-w-24 rounded-[32px] px-6 py-2 flex flex-col gap-1 items-center border-[#13986A] border-solid border cursor-pointer w-fit justify-center ${widthAuto ? 'w-fit' : 'w-full'}`}
          onClick={onClick}
        >
          {Icon && <Icon className="fill-gray-900" />}
          <div className="text-sm font-semibold text-[#13986A]">{content}</div>
          <div className="text-xs font-light text-[#13986A]">{subContent}</div>
        </div>
      );

    case ButtonType.DARK_SM:
      return (
        <div
          className="btn bg-gray-600 hover:bg-gray-600 h-[42px] min-w-16 rounded-[32px] px-4 py-2 flex gap-1 items-center border-gray-600 border-solid border cursor-pointer w-fit justify-center shrink-0"
          onClick={onClick}
        >
          {Icon && <Icon className="fill-white" />}
          <div className="text-sm text-white">{content}</div>
        </div>
      );
    case ButtonType.LIGHT_SM:
      return (
        <div
          className="btn bg-white hover:bg-white h-[42px] min-w-16 rounded-[32px] px-4 py-2 flex gap-1 items-center border-white border-solid border cursor-pointer w-fit justify-center shrink-0"
          onClick={onClick}
        >
          {Icon && <Icon className="fill-gray-600" />}
          <div className="text-sm text-gray-600">{content}</div>
        </div>
      );

    case ButtonType.GREY:
      if (!subContent) {
        return (
          <div
            className={`bg-gray-50 h-[52px] ${widthMinSetting} rounded-[32px] px-4 py-2 flex gap-1 items-center  cursor-pointer justify-center ${widthAuto ? 'w-fit' : 'w-full'}`}
            onClick={onClick}
          >
            {Icon && <Icon className="fill-gray-900" />}
            <div className="text-base font-semibold text-gray-900">
              {content}
            </div>
          </div>
        );
      }

      return (
        <div
          className={`relative flex bg-gray-50 h-[52px] ${widthMinSetting} rounded-[32px] px-6 py-2 items-center cursor-pointer justify-center ${widthAuto ? 'w-fit' : 'w-full'}`}
          onClick={onClick}
        >
          {Icon && <Icon className="fill-gray-900 mr-3" />}
          <div className={`flex flex-col items-center justify-center`}>
            <div className="text-sm font-semibold text-gray-900">{content}</div>
            <div className="text-xs font-light text-gray-900">{subContent}</div>
            {unreadMessage && (
              <span className="absolute top-0 right-0 block h-3 w-3 rounded-full bg-red-500"></span>
            )}
          </div>
        </div>
      );

    default:
      return (
        <div
          className="bg-blue-500 hover:bg-blue-600 h-10 min-w-24 rounded-[32px] px-4 py-2 flex gap-1 items-center cursor-pointer w-full justify-center"
          onClick={onClick}
        >
          {Icon && <Icon className="fill-white" />}
          <div className="text-base font-semibold text-white">{content}</div>
        </div>
      );
  }
}

export default Button;
