import React, { useState, useRef, useEffect } from 'react';
import remarkBreaks from 'remark-breaks';
import { useParams } from 'react-router';
import { useHomeProperties } from '../query/useHomeProperties';
import { useHomePropertyDetail } from '../query/useHomePropertyDetail';
import Button, { ButtonType } from './Button';
import HouseCard, { House } from './HouseCard';
import PermitCard from './PermitCard';
import UtilityCard from './UtilityCard';
import PublicSchoolCard from './PublicSchoolCard';
import NatureHazardRiskCard from './NatureHazardRiskCard';
import EnvironmentalPollutionCard from './EnvironmentalPollutionCard';
import Input from './Input';
import InputCode from './InputCode';
import SendCode from './SendCode';
import Sidebar from './Sidebar';
import PropertyDetailSidebar from './PropertyDetailSidebar';
import { scrollTop } from 'rsuite/esm/DOMHelper';
import school from '../static/school.png';
import privateSchool from '../static/privateSchool.png';
import commuteTime from '../static/commuteTime.png';
import utilityProvider from '../static/utilityProvider.png';
import inspectionReport from '../static/InspectionReport.png';
import permitHistory from '../static/permitHistory.png';
import naturalHazard from '../static/naturalHazard.png';
import noiseLevel from '../static/NoiseLevel.png';
import tSection from '../static/TSection.png';
import voltageLines from '../static/voltageLines.png';
import ComparableSales from '../static/comparableSales.png';
import cost from '../static/cost.png';
import history from '../static/history.png';
import expert from '../static/expert.png';
import { useAdminPropertyReport } from '../query/useAdminPropertyReport';
import {
  EnvironmentalPollution,
  NatureHazardRisk,
  OfferType,
  PermitRead,
  Property,
  PropertyReport
} from '../store/agentInfo';
import { usePropertyReport } from '../query/usePropertyReport';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import CustomReactionMarkdown from './CustomReactMarkdown';
import {Document, Page} from "react-pdf";
import classnames from "classnames";
import ComparableSaleCard from "./ComparableSaleCard";
import CostOfOwnershipCard from "./CostOfOwnershipCard";
import RentalEstimateCard from "./RentalEstimateCard";
import TransactionCard from "./TransactionHistoryCard";
import ListingCard from "./ListingHistoryCard";
import ListingAgentCard from "./ListingAgentCard";
import PropertyHighlightCard from "./PropertyHighlightCard";
import { ReactComponent as OfferCreate } from '../static/OfferCreate.svg';
import InspectionCard from "./InspectionCard";
import TermiteCard from "./TermiteCard";
import { useInView } from 'react-intersection-observer';
import { NodeId } from 'react-accessible-treeview';
import ChatbotBottom from './ChatbotBottom';
import {useTranslation} from "react-i18next";
import { sectionToQuestionArray } from './utils/sectionToQuestionArray';
import {axiosWithAuth} from "../utils/axios";
import LogRocket from "logrocket";
import { useNavigate } from 'react-router-dom';
import {useHomeOffersByProperty} from "../query/useHomeOffersByProperty";
import ChatBottom from "./ChatBottom";
import classNames from 'classnames';
import useChatBotStore from '../store/chatbot';

function PropertyDetail() {
  const { propertyId } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { data } = useHomePropertyDetail(propertyId);
  const propertyInfo = data as Property;
  const { dragging } = useChatBotStore();
  const { data: offerData } = useHomeOffersByProperty(propertyId);
  const offersList = offerData as OfferType[]
  console.log(offersList)

  //const houseResult = data?.result;
  console.log(propertyInfo);
  const propertyIdInt = parseInt(propertyId ?? '');
  const {
    data: reportData,
    isFetching,
    isLoading,
    refetch,
  } = usePropertyReport(propertyIdInt, i18n.language);
  const propertyReportInfo = reportData as PropertyReport;
  console.log(propertyReportInfo);
  const [selectedIds, setSelectedIds] = useState<NodeId[]>([1]);
  const allowAutoFocusRef = useRef<boolean>(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const permit_history_data = propertyReportInfo?.permits.map((permit: PermitRead) => ({
    type: permit.type,
    description: permit.description,
    permit_classifications: permit.permit_classifications,
    permit_number: permit.permit_number,
    permit_status: permit.permit_status,
    permit_effective_date: permit.permit_effective_date,
    job_value: permit.job_value,
    contractors: permit.contractors.map(u => u.name).join(', ')
  }))
  const nature_hazard_risks_data = propertyReportInfo?.nature_hazard_risks?.risks.map((natureHazardRisk: NatureHazardRisk) => ({
    issue: natureHazardRisk.issue,
    status: natureHazardRisk.status,
    description: natureHazardRisk.description
  }))
  const environmental_pollutions_data = propertyReportInfo?.environmental_pollutions?.pollutions.map((environmentalPollution: EnvironmentalPollution) => ({
    issue: environmentalPollution.issue,
    status: environmentalPollution.status,
    description: environmentalPollution.description
  }))

  const inviewLists = Array.from({ length: 26 }).map(() => {
    const { ref, inView } = useInView({
      threshold: 0.8,
      triggerOnce: false
    });
    const elementRef = React.useRef<HTMLDivElement | null>(null);

    return {
      setRef: (node: HTMLDivElement | null) => {
        ref(node);
        elementRef.current = node;
      },
      inView,
      elementRef
    };
  });

  const refLists = inviewLists.map((obj)=> obj.setRef)
  const elementRefLists = inviewLists.map((obj)=> obj.elementRef)

  inviewLists.forEach((item, index) => {
    React.useEffect(() => {
      if (item.inView && item.elementRef.current && allowAutoFocusRef.current) {
        console.log("in view:",index)
        setSelectedIds([index + 1])
      }
    }, [item.inView, item.elementRef]);
  });

  useEffect(()=> {
    setTimeout(()=> {
      allowAutoFocusRef.current = true
    }, 3000)
  }, [])


  const scrollToTarget = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const styles = {
    dataSource: {
      fontSize: '12px',
      fontWeight: '400',
      color: '#5D6B79',
      lineHeight: '16.34px',
      marginTop: '4px',
      marginBottom: '20px'
    },
    dataSourceUrl: {
      color: '#126FD6',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '20px',
    },
    superSectionHeader: {
      fontSize: '20px',
      fontWeight: '700',
      color: '#192D1D',
      lineHeight: '28px',
      paddingTop: '20px'
    },
    sectionHeader: {
      fontSize: '16px',
      fontWeight: '700',
      color: '#192D1D',
      lineHeight: '21.79px',
      paddingTop: '20px'
    },
    subSectionHeader: {
      fontSize: '14px',
      fontWeight: '700',
      color: '#161A1D',
      lineHeight: '18px',
      marginTop: '20px'
    },
    text: {
      fontSize: '14px',
      fontWeight: '400',
      color: '#161A1D',
      lineHeight: '18px',
    },
    inspectionContainer: {
      display: 'flex',
      flexDirection: 'column' as const,
      width: '100%'
    },
    termiteContainer: {
      display: 'flex',
      flexDirection: 'column' as const,
      width: '100%'
    }
  };

  useEffect(() => {
    const identifyUser = async() => {
      const axios = axiosWithAuth()
      // Assuming you have user data in your state or props
      const {data} = await axios.get('user/me')
      const user = data?.result
      LogRocket.identify(user.id, {
        email: user.email,
      });
    }

    identifyUser();
  }, []);

  if (!propertyInfo || !data) {
    return <></>;
  }
  
  return (
    <div className="flex bg-black min-h-full w-full h-full">
      <Sidebar />
      <div className="flex flex-col min-h-screen w-full bg-black">
      <div className="mt-3 mr-3 rounded-[20px] bg-gray-50 grow flex flex-col relative overflow-hidden">
        <div className="flex items-center justify-between py-3 px-6 border-b border-solid border-gray-200">
          <div className="flex text-xl font-bold gap-4">
            {data.address}
            <span className="text-xl text-[#13986A]">{t('onMarketLabel')}</span>
          </div>
          { offersList && offersList.length === 0 &&
            <div className="flex gap-2">
              {/*<Button
                type={ButtonType.DARK}
                loading={false}
                disabled={false}
                content={t('expertOfferLabel')}
                subContent={t('expertOfferNote')}
                widthAuto
                onClick={async () => {
                  window.open(
                      `https://calendly.com/home8-support/30min`,
                      '_blank',
                  );
                }}
              />*/}
              <Button
                  Icon={OfferCreate}
                  type={ButtonType.LIGHT}
                  loading={false}
                  disabled={false}
                  content={t('draftNewOfferLabel')}
                  subContent={t('draftNewOfferNote')}
                  widthAuto
                  onClick={async () => {
                    window.open(
                        `/home/offer_draft?property_id=${propertyId}`,
                        '_blank',
                    );
                  }}
              />
            </div>
          }
          { offersList && offersList.length > 0 &&
              <div className="flex gap-2">
                <Button
                    type={ButtonType.DARK}
                    loading={false}
                    disabled={false}
                    content={t('propertyViewOfferLabel')}
                    subContent={t('propertyViewOfferHintLabel')}
                    onClick={() => navigate('/home/offers')}
                />
              </div>
          }
        </div>

        <div className="flex bg-gray-50 min-h-0">
          <PropertyDetailSidebar
            scrollToTarget={scrollToTarget}
            refLists={elementRefLists}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            onNodeClick={()=> {
              console.log("no allow focus")
              allowAutoFocusRef.current = false
              console.log("no allow focus:", timeoutRef.current)
              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
              }
              timeoutRef.current = setTimeout(()=> {
                console.log("allow focus")
                allowAutoFocusRef.current = true
              }, 1000)
            }}
          ></PropertyDetailSidebar>
          <div className={classNames("flex h-full", {
              'overflow-auto': !dragging,
              'overflow-hidden': dragging,
            })}>
            <div className="white w-full px-5">
              <div className="text-3xl font-bold text-[#000000] mt-4 ">
                <div ref={refLists[0]} style={styles.superSectionHeader}>{t('propertyHighlightLabel')}</div>
                {propertyInfo && propertyReportInfo && <PropertyHighlightCard
                  property={propertyInfo}
                  report={propertyReportInfo}
                />}
              </div>
              <div className="text-3xl font-bold text-[#000000] mt-4">
                <div style={styles.superSectionHeader}>{t('propertyDeepdiveLabel')}</div>

                <div>
                  <div style={styles.sectionHeader} ref={refLists[2]}>{t('propertyInspectionReportLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}:{' '}
                    <a
                        onClick={async () => {
                          try {
                            const response = await fetch(
                                propertyReportInfo?.inspection_report.source,
                            );
                            const blob = await response.blob();
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(blob);
                            link.download = `Inspection Report-${propertyInfo.address}.pdf`; // Replace 'filename.ext' with your desired filename
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          } catch (error) {
                            console.error('Error downloading file:', error);
                          }
                        }}
                        style={{
                          textDecoration: 'underline',
                          color: '#3b82f6',
                          cursor: 'pointer',
                        }}
                    >
                      {t('propertyInspectionReportLabel')}
                    </a>
                  </div>
                  <div style={styles.inspectionContainer}>
                    {
                        propertyReportInfo && propertyReportInfo?.inspection_report && propertyReportInfo?.inspection_report.problems.map((problem, index) =>
                            <InspectionCard
                                category={problem.category}
                                status={problem.status}
                                defects={problem.defects}
                                recommendations={problem.recommendations}
                                repair_cost={problem.repair_cost}
                            />)
                    }
                  </div>
                </div>
                <div>
                  <div style={styles.sectionHeader} ref={refLists[3]}>{t('propertyTermiteReportLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}:{' '}
                    <a
                        onClick={async () => {
                          try {
                            const response = await fetch(
                                propertyReportInfo?.termite_report.source,
                            );
                            const blob = await response.blob();
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(blob);
                            link.download = `Termite Report-${propertyInfo.address}.pdf`; // Replace 'filename.ext' with your desired filename
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          } catch (error) {
                            console.error('Error downloading file:', error);
                          }
                        }}
                        style={{
                          textDecoration: 'underline',
                          color: '#3b82f6',
                          cursor: 'pointer',
                        }}
                    >
                      {t('propertyTermiteReportLabel')}
                    </a>
                  </div>
                  <div style={styles.termiteContainer}>
                    {
                        propertyReportInfo && propertyReportInfo?.termite_report && propertyReportInfo?.termite_report.problems.map((problem, index) =>
                            <TermiteCard
                                category={problem.category}
                                status={problem.status}
                                defects={problem.defects}
                                recommendations={problem.recommendations}
                                repair_cost={problem.repair_cost}
                            />)
                    }
                  </div>
                </div>
                <div>
                  <div style={styles.sectionHeader} ref={refLists[4]}>{t('propertyBackgroundStoriesLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}:{' '}
                    <a
                        onClick={async () => {
                          try {
                            const response = await fetch(
                                propertyReportInfo?.background_stories
                                    .seller_property_questionaire?.source!,
                            );
                            const blob = await response.blob();
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(blob);
                            link.download = `SPQ-${propertyInfo.address}.pdf`; // Replace 'filename.ext' with your desired filename
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          } catch (error) {
                            console.error('Error downloading file:', error);
                          }
                        }}
                        style={{
                          textDecoration: 'underline',
                          color: '#3b82f6',
                          cursor: 'pointer',
                        }}
                    >
                      {t('propertySellerPropertyQuestionaireLabel')} (SPQ)
                    </a>
                    ,{' '}
                    <a
                        onClick={async () => {
                          try {
                            const response = await fetch(
                                propertyReportInfo?.background_stories
                                    .transaction_disclosure_statement?.source!,
                            );
                            const blob = await response.blob();
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(blob);
                            link.download = `TDS-${propertyInfo.address}.pdf`; // Replace 'filename.ext' with your desired filename
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          } catch (error) {
                            console.error('Error downloading file:', error);
                          }
                        }}
                        style={{
                          textDecoration: 'underline',
                          color: '#3b82f6',
                          cursor: 'pointer',
                        }}
                    >
                      {t('propertyTransactionDisclosureStatementLabel')} (TDS)
                    </a>
                    {/*<div className="mt-1">Last updated: xxxx/xx/xx</div>*/}
                  </div>
                  <div style={styles.subSectionHeader}>{t('propertyReasonForSellingLabel')}</div>
                  <CustomReactionMarkdown
                      content={
                        propertyReportInfo?.background_stories.reason_for_selling
                      }
                  />

                  <div style={styles.subSectionHeader}>
                    {t('propertySellerPropertyQuestionaireLabel')}:
                  </div>
                  <ul style={{
                    listStyleType: 'none',
                    padding: 0
                  }}>
                    {propertyReportInfo && propertyReportInfo.background_stories.seller_property_questionaire && propertyReportInfo.background_stories.seller_property_questionaire.summary.map((spq_item, index) => (
                          <li style={{
                            fontSize: '14px',
                            lineHeight: '18px',
                            fontWeight: '400',
                            color: '#161A1D',
                            listStyleType: 'disc', /* default bullet points */
                            marginLeft: '20px'
                          }}>{spq_item}</li>
                    ))}
                  </ul>
                    <div style={styles.subSectionHeader}>
                      {t('propertyTransactionDisclosureStatementLabel')}:
                    </div>
                    <div style={styles.text}>
                      {
                        propertyReportInfo?.background_stories
                            .transaction_disclosure_statement
                            ?.seller_items_description
                      }
                    </div>
                    <img
                        src={
                          propertyReportInfo?.background_stories
                              .transaction_disclosure_statement?.seller_items_url
                        }
                        alt="new"
                    />
                  <ul style={{
                    listStyleType: 'none',
                    padding: 0
                  }}>
                    {propertyReportInfo && propertyReportInfo.background_stories.transaction_disclosure_statement && propertyReportInfo.background_stories.transaction_disclosure_statement.summary?.map((tds_item, index) => (
                        <li style={{
                          fontSize: '14px',
                          lineHeight: '18px',
                          fontWeight: '400',
                          color: '#161A1D',
                          listStyleType: 'disc', /* default bullet points */
                          marginLeft: '20px'
                        }}>{tds_item}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <div style={styles.sectionHeader} ref={refLists[5]}>{t('propertyPermitHistoryLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: {t('propertyPublicDataLabel')}
                  </div>
                  <div style={styles.grid}>
                    {permit_history_data && permit_history_data.map((permit, index) => (
                        <PermitCard
                            key={index}
                            type={permit.type}
                            description={permit.description}
                            permit_classifications={permit.permit_classifications}
                            permit_number={permit.permit_number}
                            permit_status={permit.permit_status}
                            permit_effective_date={permit.permit_effective_date}
                            job_value={permit.job_value}
                            contractors={permit.contractors}
                        />
                    ))}
                  </div>
                </div>
                <div>
                  <div style={styles.sectionHeader} ref={refLists[6]}>{t('propertyDiscrepanciesLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: {t('propertyPublicDataLabel')}
                  </div>
                  <CustomReactionMarkdown
                      content={
                        propertyReportInfo?.discrepancies.description
                      }
                  />
                </div>
                <div>
                  <div style={styles.sectionHeader} ref={refLists[7]}>{t('propertyLegalAndTileLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: {' '}
                    <a
                        onClick={async () => {
                          try {
                            const response = await fetch(
                                propertyReportInfo?.legal_and_title?.source,
                            );
                            const blob = await response.blob();
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(blob);
                            link.download = `Prelim-${propertyInfo.address}.pdf`; // Replace 'filename.ext' with your desired filename
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          } catch (error) {
                            console.error('Error downloading file:', error);
                          }
                        }}
                        style={{
                          textDecoration: 'underline',
                          color: '#3b82f6',
                          cursor: 'pointer',
                        }}
                    >
                      {t('propertyPrelimReportLabel')}
                    </a>
                  </div>
                  <div style={styles.subSectionHeader}>{t('propertyPlotMapIntroduction')}</div>
                  <div>
                    <Document file={propertyReportInfo?.legal_and_title?.plot_map_url}>
                      <Page pageNumber={1}/>
                    </Document>
                  </div>
                  <CustomReactionMarkdown
                      content={
                        propertyReportInfo?.legal_and_title?.prelim_info
                      }
                  />
                </div>
                <div>
                  <div style={styles.sectionHeader} ref={refLists[8]}>{t('propertyNatureHazardRisksLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: {' '}
                    <a
                        onClick={async () => {
                          try {
                            const response = await fetch(
                                propertyReportInfo?.nature_hazard_risks?.source,
                            );
                            const blob = await response.blob();
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(blob);
                            link.download = `NHD-${propertyInfo.address}.pdf`; // Replace 'filename.ext' with your desired filename
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          } catch (error) {
                            console.error('Error downloading file:', error);
                          }
                        }}
                        style={{
                          textDecoration: 'underline',
                          color: '#3b82f6',
                          cursor: 'pointer',
                        }}
                    >
                      {t('propertyNatureHazardDisclosureLabel')}
                    </a>
                  </div>
                  <div style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: '20px',
                  }}>
                    {nature_hazard_risks_data && nature_hazard_risks_data.map((nature_hazard_risk, index) => (
                        <NatureHazardRiskCard
                            key={index}
                            issue={nature_hazard_risk.issue}
                            status={nature_hazard_risk.status}
                            description={nature_hazard_risk.description}
                        />
                    ))}
                  </div>
                </div>

                <div>
                  <div style={styles.sectionHeader} ref={refLists[9]}>{t('propertyEnvironmentalPollutionLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: {' '}
                    <a
                        onClick={async () => {
                          try {
                            const response = await fetch(
                                propertyReportInfo?.environmental_pollutions?.source,
                            );
                            const blob = await response.blob();
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(blob);
                            link.download = `NHD-${propertyInfo.address}.pdf`; // Replace 'filename.ext' with your desired filename
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          } catch (error) {
                            console.error('Error downloading file:', error);
                          }
                        }}
                        style={{
                          textDecoration: 'underline',
                          color: '#3b82f6',
                          cursor: 'pointer',
                        }}
                    >
                      {t('propertyNatureHazardDisclosureLabel')}
                    </a>
                  </div>
                  <div style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: '20px',
                  }}>
                    {environmental_pollutions_data && environmental_pollutions_data.map((environmental_pollution, index) => (
                        <EnvironmentalPollutionCard
                            key={index}
                            issue={environmental_pollution.issue}
                            status={environmental_pollution.status}
                            description={environmental_pollution.description}
                        />
                    ))}
                  </div>
                </div>
                {/*<div>
                  <div className="text-2xl mt-4">Noise level</div>
                  <div className="text-sm font-normal text-[#5D6B79] pt-2 mt-4">
                    Data source:
                    <a className="ml-2" href={houseResult.disclosure_url}>
                      Public record
                    </a>
                    <div className="mt-1">Last updated: xxxx/xx/xx</div>
                  </div>
                  <img src={noiseLevel} className="mt-4" alt="" />
                </div>
                <div>
                  <div className="text-2xl mt-4">T section</div>
                  <div className="text-sm font-normal text-[#5D6B79] pt-2 mt-4">
                    Data source:
                    <a className="ml-2" href={houseResult.disclosure_url}>
                      Public record
                    </a>
                    <div className="mt-1">Last updated: xxxx/xx/xx</div>
                  </div>
                  <div className="text-sm font-normal">
                    The property is not at a T section. It is situated within a
                    block of buildings and not at an intersection of roads.
                  </div>
                  <img src={tSection} className="mt-4" alt="" />
                </div>
                <div>
                  <div className="text-2xl mt-4">
                    Nearest High Voltage Lines
                  </div>
                  <div className="text-sm font-normal text-[#5D6B79] pt-2 mt-4">
                    Data source:
                    <a className="ml-2" href={houseResult.disclosure_url}>
                      Public record
                    </a>
                    <div className="mt-1">Last updated: xxxx/xx/xx</div>
                  </div>
                  <div className="text-sm font-normal">
                    The house is roughly 5 houses away from the high voltage
                    power line
                  </div>
                  <img src={voltageLines} className="mt-4" alt="" />
                </div>
                <div>
                  <div className="text-2xl mt-4">
                    Potential drivers of depreciation
                  </div>
                  <div className="text-sm font-normal text-[#5D6B79] pt-2 mt-4">
                    Data source:
                    <a className="ml-2" href={houseResult.disclosure_url}>
                      Public record
                    </a>
                    <div className="mt-1">Last updated: xxxx/xx/xx</div>
                  </div>
                  <div className="text-sm font-normal">
                    <div className="mt-6">
                      <span className="font-bold text-red-500">
                        Proximity to commercial area 
                      </span>{" "}
                      The property is slightly close to a commercial area, which
                      may impact its future appreciation potential
                    </div>
                    <div className="mt-6">
                      <span className="font-bold text-red-500">
                        Size and layout
                      </span>
                       The house is somewhat small, and the master bedroom faces
                      the street rather than the backyard{" "}
                    </div>
                    <div className="mt-6">
                      <span className="font-bold text-red-500">
                        Basic renovations
                      </span>
                       The renovations done by the seller before listing were
                      quite basic, and more expensive updates such as electrical
                      and plumbing systems were not addressed
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-2xl mt-4">
                    Potential drivers of appreciation
                  </div>
                  <div className="text-sm font-normal text-[#5D6B79] pt-2 mt-4">
                    Data source:
                    <a className="ml-2" href={houseResult.disclosure_url}>
                      Public record
                    </a>
                    <div className="mt-1">Last updated: xxxx/xx/xx</div>
                  </div>
                  <div className="text-sm font-normal">
                    <div className="mt-6">
                      <span className="font-bold text-green-500">
                        Proximity to commercial area 
                      </span>{" "}
                      The property is slightly close to a commercial area, which
                      may impact its future appreciation potential
                    </div>
                    <div className="mt-6">
                      <span className="font-bold text-green-500">
                        Size and layout
                      </span>
                       The house is somewhat small, and the master bedroom faces
                      the street rather than the backyard{" "}
                    </div>
                    <div className="mt-6">
                      <span className="font-bold text-green-500">
                        Basic renovations
                      </span>
                       The renovations done by the seller before listing were
                      quite basic, and more expensive updates such as electrical
                      and plumbing systems were not addressed
                    </div>
                  </div>
                </div>*/}
              </div>
              <div
                  className="text-3xl font-bold text-[#000000] mt-4"
                  ref={refLists[11]}
              >
                <div>
                  <div style={styles.sectionHeader}>{t('communityNeighborhoodLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: {t('propertyPublicDataLabel')}
                  </div>
                  <div className="text-base"><a
                      href={propertyReportInfo?.neighborhood?.economy_url}>Economy</a>
                  </div>
                  <div className="text-base"><a
                      href={propertyReportInfo?.neighborhood?.housing_url}>Housing</a>
                  </div>
                  <div className="text-base"><a
                      href={propertyReportInfo?.neighborhood?.people_url}>People</a>
                  </div>
                  <div className="text-base"><a
                      href={propertyReportInfo?.neighborhood?.quality_url}>Quality of Life</a>
                  </div>
                </div>
                <div>
                  <div style={styles.sectionHeader} ref={refLists[12]}>{t('communitySchoolLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: {' '}
                    <a style={styles.dataSourceUrl} href={propertyReportInfo?.school.public_school_source}>
                      Great school
                    </a>
                  </div>
                  <h3 style={{fontSize: '14px', fontWeight: '700', marginBottom: '8px', lineHeight: '18px'}}>{t('propertyPublicSchoolLabel')}</h3>
                  <div style={{marginTop: '10px'}}>
                    {
                        propertyReportInfo?.school.public_school && propertyReportInfo?.school.public_school.map((public_school, index) =>
                            <PublicSchoolCard
                                key={public_school.name}
                                name={public_school.name}
                                score={public_school.score}
                                address={public_school.address}
                                district={public_school.district}
                                number_of_student={public_school.number_of_student}
                          student_to_teacher_ratio={public_school.student_to_teacher_ratio}
                      />)
                    }
                  </div>
                </div>
                <div>
                  <div style={styles.sectionHeader} ref={refLists[13]}>{t('communityCommuteTimeLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: {t('propertyPublicDataLabel')}
                  </div>
                  <CustomReactionMarkdown
                      content={
                        propertyReportInfo?.commute_time
                      }
                  />
                </div>
                <div>
                  <div style={styles.sectionHeader} ref={refLists[14]}>{t('communityAmenitiesLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: {t('propertyPublicDataLabel')}
                  </div>
                  <CustomReactionMarkdown
                      content={
                        propertyReportInfo?.amenities
                      }
                  />
                </div>
                <div>
                  <div style={styles.sectionHeader} ref={refLists[15]}>{t('communityUnityProvidersLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: {t('propertyPublicDataLabel')}
                  </div>
                  <div style={styles.grid}>
                    {
                      propertyReportInfo?.utility_provider && propertyReportInfo?.utility_provider.map((utility_provider, index) => <UtilityCard
                          category={utility_provider.category}
                          vendor={utility_provider.vendor}
                          contact={utility_provider.contact}
                          website={utility_provider.website}
                      />)
                    }
                  </div>
                </div>
              </div>
              <div
                  className="text-3xl font-bold text-[#000000] mt-4"
              >
                <div>
                  <div style={styles.sectionHeader} ref={refLists[17]}>{t('propertyEstimateValueLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: Home8
                  </div>
                  <CustomReactionMarkdown
                      content={
                        propertyReportInfo?.estimate_price.description
                      }
                  />
                </div>
                <div>
                  <div style={styles.sectionHeader} ref={refLists[18]}>{t('propertyComparableSalesLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: Home8
                  </div>
                  <div style={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: '700',
                    color: '#161A1D',
                    marginBottom: '8px',
                  }}>
                    {t('propertyComparableSalesHistoricalHighestLabel')}
                  </div>
                  {propertyReportInfo && propertyReportInfo.comparable_sales?.comps_highest &&
                      <ComparableSaleCard
                          address={propertyReportInfo.comparable_sales.comps_highest.address}
                          bedrooms={propertyReportInfo.comparable_sales.comps_highest.bedrooms}
                          full_bathrooms={propertyReportInfo.comparable_sales.comps_highest.full_bathrooms}
                          half_bathrooms={propertyReportInfo.comparable_sales.comps_highest.half_bathrooms}
                          lot_size={propertyReportInfo.comparable_sales.comps_highest.lot_size}
                          living_sqft={propertyReportInfo.comparable_sales.comps_highest.living_sqft}
                          off_market_date={propertyReportInfo.comparable_sales.comps_highest.off_market_date}
                          mls_listing_id={propertyReportInfo.comparable_sales.comps_highest.mls_listing_id}
                          original_listing_price={propertyReportInfo.comparable_sales.comps_highest.original_listing_price}
                          close_price={propertyReportInfo.comparable_sales.comps_highest.close_price}
                      />
                  }
                  <div style={{
                    fontSize: '14px',
                    lineHeight: '18px',
                    fontWeight: '700',
                    color: '#161A1D',
                    marginTop: '12px',
                    marginBottom: '8px',
                  }}>
                    {t('propertyComparableSalesCurrentComparableSalesLabel')}
                  </div>
                  {propertyReportInfo && propertyReportInfo.comparable_sales?.comps1 &&
                      <ComparableSaleCard
                          address={propertyReportInfo.comparable_sales.comps1.address}
                          bedrooms={propertyReportInfo.comparable_sales.comps1.bedrooms}
                          full_bathrooms={propertyReportInfo.comparable_sales.comps1.full_bathrooms}
                          half_bathrooms={propertyReportInfo.comparable_sales.comps1.half_bathrooms}
                          lot_size={propertyReportInfo.comparable_sales.comps1.lot_size}
                          living_sqft={propertyReportInfo.comparable_sales.comps1.living_sqft}
                          off_market_date={propertyReportInfo.comparable_sales.comps1.off_market_date}
                          mls_listing_id={propertyReportInfo.comparable_sales.comps1.mls_listing_id}
                          original_listing_price={propertyReportInfo.comparable_sales.comps1.original_listing_price}
                          close_price={propertyReportInfo.comparable_sales.comps1.close_price}
                      />
                  }
                  {propertyReportInfo && propertyReportInfo.comparable_sales?.comps2 &&
                      <ComparableSaleCard
                          address={propertyReportInfo.comparable_sales.comps2.address}
                          bedrooms={propertyReportInfo.comparable_sales.comps2.bedrooms}
                          full_bathrooms={propertyReportInfo.comparable_sales.comps2.full_bathrooms}
                          half_bathrooms={propertyReportInfo.comparable_sales.comps2.half_bathrooms}
                          lot_size={propertyReportInfo.comparable_sales.comps2.lot_size}
                          living_sqft={propertyReportInfo.comparable_sales.comps2.living_sqft}
                          off_market_date={propertyReportInfo.comparable_sales.comps2.off_market_date}
                          mls_listing_id={propertyReportInfo.comparable_sales.comps2.mls_listing_id}
                          original_listing_price={propertyReportInfo.comparable_sales.comps2.original_listing_price}
                          close_price={propertyReportInfo.comparable_sales.comps2.close_price}
                      />
                  }
                  {propertyReportInfo && propertyReportInfo.comparable_sales?.comps3 &&
                      <ComparableSaleCard
                          address={propertyReportInfo.comparable_sales.comps3.address}
                          bedrooms={propertyReportInfo.comparable_sales.comps3.bedrooms}
                          full_bathrooms={propertyReportInfo.comparable_sales.comps3.full_bathrooms}
                          half_bathrooms={propertyReportInfo.comparable_sales.comps3.half_bathrooms}
                          lot_size={propertyReportInfo.comparable_sales.comps3.lot_size}
                          living_sqft={propertyReportInfo.comparable_sales.comps3.living_sqft}
                          off_market_date={propertyReportInfo.comparable_sales.comps3.off_market_date}
                          mls_listing_id={propertyReportInfo.comparable_sales.comps3.mls_listing_id}
                          original_listing_price={propertyReportInfo.comparable_sales.comps3.original_listing_price}
                          close_price={propertyReportInfo.comparable_sales.comps3.close_price}
                      />
                  }
                  {propertyReportInfo && propertyReportInfo.comparable_sales?.comps4 &&
                      <ComparableSaleCard
                          address={propertyReportInfo.comparable_sales.comps4.address}
                          bedrooms={propertyReportInfo.comparable_sales.comps4.bedrooms}
                          full_bathrooms={propertyReportInfo.comparable_sales.comps4.full_bathrooms}
                          half_bathrooms={propertyReportInfo.comparable_sales.comps4.half_bathrooms}
                          lot_size={propertyReportInfo.comparable_sales.comps4.lot_size}
                          living_sqft={propertyReportInfo.comparable_sales.comps4.living_sqft}
                          off_market_date={propertyReportInfo.comparable_sales.comps4.off_market_date}
                          mls_listing_id={propertyReportInfo.comparable_sales.comps4.mls_listing_id}
                          original_listing_price={propertyReportInfo.comparable_sales.comps4.original_listing_price}
                          close_price={propertyReportInfo.comparable_sales.comps4.close_price}
                      />
                  }
                  {propertyReportInfo && propertyReportInfo.comparable_sales?.comps5 &&
                      <ComparableSaleCard
                          address={propertyReportInfo.comparable_sales.comps5.address}
                          bedrooms={propertyReportInfo.comparable_sales.comps5.bedrooms}
                          full_bathrooms={propertyReportInfo.comparable_sales.comps5.full_bathrooms}
                          half_bathrooms={propertyReportInfo.comparable_sales.comps5.half_bathrooms}
                          lot_size={propertyReportInfo.comparable_sales.comps5.lot_size}
                          living_sqft={propertyReportInfo.comparable_sales.comps5.living_sqft}
                          off_market_date={propertyReportInfo.comparable_sales.comps5.off_market_date}
                          mls_listing_id={propertyReportInfo.comparable_sales.comps5.mls_listing_id}
                          original_listing_price={propertyReportInfo.comparable_sales.comps5.original_listing_price}
                          close_price={propertyReportInfo.comparable_sales.comps5.close_price}
                      />
                  }
                  {propertyReportInfo && propertyReportInfo.comparable_sales?.comps6 &&
                      <ComparableSaleCard
                          address={propertyReportInfo.comparable_sales.comps6.address}
                          bedrooms={propertyReportInfo.comparable_sales.comps6.bedrooms}
                          full_bathrooms={propertyReportInfo.comparable_sales.comps6.full_bathrooms}
                          half_bathrooms={propertyReportInfo.comparable_sales.comps6.half_bathrooms}
                          lot_size={propertyReportInfo.comparable_sales.comps6.lot_size}
                          living_sqft={propertyReportInfo.comparable_sales.comps6.living_sqft}
                          off_market_date={propertyReportInfo.comparable_sales.comps6.off_market_date}
                          mls_listing_id={propertyReportInfo.comparable_sales.comps6.mls_listing_id}
                          original_listing_price={propertyReportInfo.comparable_sales.comps6.original_listing_price}
                          close_price={propertyReportInfo.comparable_sales.comps6.close_price}
                      />
                  }
                </div>
                <div>
                  <div style={styles.sectionHeader} ref={refLists[19]}>{t('propertyMarketTrendLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: {t('propertyPublicDataLabel')}
                  </div>
                  <div className="text-base"><a
                      href={propertyReportInfo?.market_trend?.market_trend_url}>Market Trend</a>
                  </div>
                </div>
                <div>
                  <div style={styles.sectionHeader} ref={refLists[20]}>{t('propertyCostOfOwnershipLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: {t('propertyPublicDataLabel')}
                  </div>
                  {propertyReportInfo && propertyReportInfo.cost_of_ownership && propertyReportInfo.rental_estimate && <CostOfOwnershipCard
                      down_percentage={propertyReportInfo.cost_of_ownership.down_percentage}
                      interest={propertyReportInfo.cost_of_ownership.interest}
                      mortgage={propertyReportInfo.cost_of_ownership.mortgage}
                  />}
                </div>
                <div>
                  <div style={styles.sectionHeader} ref={refLists[21]}>{t('propertyRentalEstimateLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: {t('propertyPublicDataLabel')}
                  </div>
                  {propertyReportInfo && propertyReportInfo.rental_estimate && <RentalEstimateCard
                      low={propertyReportInfo.rental_estimate.low}
                      average={propertyReportInfo.rental_estimate.average}
                      high={propertyReportInfo.rental_estimate.high}
                  />}
                </div>
                <div>
                  <div style={styles.sectionHeader} ref={refLists[22]}>
                    {t('propertyListingAndTransactionHistoryLabel')}
                  </div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: Home8
                  </div>
                  {
                    propertyReportInfo?.listing_and_transaction_history.map((history, index) => {
                      if (history?.type === 'listing') {
                        return <ListingCard
                            mls_listing_id={history.listing?.mls_listing_id!}
                            on_market_date={history.listing?.on_market_date}
                            off_market_date={history.listing?.off_market_date}
                            original_listing_price={history.listing?.original_listing_price}
                            final_listing_price={history.listing?.final_listing_price}
                            close_price={history.listing?.close_price}
                        />
                      } else {
                        return <TransactionCard
                            sale_date={history.transaction?.sale_date}
                            sale_amount={history.transaction?.sale_amount}
                            title_company={history.transaction?.title_company}
                            buyer_names={history.transaction?.buyer_names.map(u => u).join(', ')}
                            seller_names={history.transaction?.seller_names.map(u => u).join(', ')}
                        />
                      }
                    })
                  }

                </div>
              </div>
              <div
                  className="text-3xl font-bold text-[#000000] mt-4"
              >
                <div>
                  <div style={styles.sectionHeader} ref={refLists[23]}>
                    {t('propertyListingAgentContactLabel')}
                  </div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: {t('propertyPublicDataLabel')}
                  </div>
                  {propertyReportInfo && propertyReportInfo.listing_agent && <ListingAgentCard
                      name={propertyReportInfo.listing_agent.name}
                      phone_number={propertyReportInfo.listing_agent.phone_number}
                      email={propertyReportInfo.listing_agent.email}
                      company={propertyReportInfo.listing_agent.company}
                      profile_url={propertyReportInfo.listing_agent.profile_url}
                    />
                  }
                </div>
              </div>
              <div
                className="text-3xl font-bold text-[#000000] mt-4"
              >
                <div>
                  <div style={styles.sectionHeader} ref={refLists[24]}>{t('propertyOfferInsightsLabel')}</div>
                  <div style={styles.dataSource}>
                    {t('propertyDataSourceLabel')}: Home8
                  </div>

                  <CustomReactionMarkdown
                      content={
                        propertyReportInfo?.offer_related_insights
                      }
                  />
                </div>
                <div style={{height: '300px'}}></div>
              </div>
            </div>
          </div>
        </div>
        {/*<ChatbotBottom propertyId={propertyId} sampleQuestions={(sectionToQuestionArray[Number(selectedIds[0]) - 1] ?? []).map((value)=> t(value))}/>*/}
      </div>
      <ChatBottom disableAI={false} sampleQuestions={(sectionToQuestionArray[Number(selectedIds[0]) - 1] ?? []).map((value)=> t(value))} propertyId={propertyId} />
      </div>
    </div>
  );
}

export default PropertyDetail;
