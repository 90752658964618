import { useMutation, useQuery } from '@tanstack/react-query'
import { axiosWithAuth } from '../utils/axios'

export const useHomeRequestProperty = () => {
  const axios = axiosWithAuth()
  const requestProperty = async ({
    address, email
  }: {address: string, email:string}) => {
    try {
      const res = await axios.post(
        'request_property',
        {
          email,
          address
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

    } catch {
    } 
  };
  const { mutateAsync } = useMutation(requestProperty);
  return { mutateAsync };
};

