import React from "react";
import {useTranslation} from "react-i18next";

type CostOfOwnership = {
    down_percentage: string
    interest: string
    mortgage: number
}


function CostOfOwnershipCard({
    down_percentage,
    interest,
    mortgage
}: CostOfOwnership) {
    const styles = {
        totalMonthlyRow: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '4px',
        },
        totalMonthlyLabel: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            color: '#161A1D',
        },
        totalMonthlyValue: {
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: '27.24px',
            color: '#161A1D',
            textAlign: 'right' as const,
            marginRight: '12px',
            minWidth: '150px',  // Ensures all values are aligned on the right
        },
        mortgageMonthlyLabel: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            color: '#161A1D',
        },
        mortgageMonthlyValue: {
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '18px',
            color: '#161A1D',
            textAlign: 'right' as const,
            marginRight: '12px',
            minWidth: '150px',  // Ensures all values are aligned on the right
        },
    };

    const monthly_mortgage = mortgage.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // Removes cents
        maximumFractionDigits: 0  // Removes cents
    });

    const { t, i18n } = useTranslation();

    return (
        <>
            <div style={styles.totalMonthlyRow}>
                <div style={styles.totalMonthlyLabel}>{t('propertyCostOfOwnershipTotalMonthlyCostLabel')}</div>
                <div style={styles.totalMonthlyValue}>{monthly_mortgage}/{t('monthUnitLabel')}</div>
            </div>
            <div style={styles.totalMonthlyRow}>
                <div style={styles.mortgageMonthlyLabel}>{t('propertyCostOfOwnershipDownPaymentLabel')} {down_percentage}%</div>
                <div style={styles.mortgageMonthlyValue}>{t('comingSoonLabel')}</div>
            </div>
            <div style={styles.totalMonthlyRow}>
                <div style={styles.mortgageMonthlyLabel}>{t('propertyCostOfOwnershipLoanAmountLabel')} ({interest}% interest rate for a 30-year mortgage)</div>
                <div style={styles.mortgageMonthlyValue}>{t('comingSoonLabel')}</div>
            </div>
            <div style={styles.totalMonthlyRow}>
                <div style={styles.mortgageMonthlyLabel}>{t('propertyCostOfOwnershipMonthlyMortgagePaymentLabel')}</div>
                <div style={styles.mortgageMonthlyValue}>{monthly_mortgage}/{t('monthUnitLabel')}</div>
            </div>
            <div style={styles.totalMonthlyRow}>
                <div style={styles.mortgageMonthlyLabel}>{t('propertyCostOfOwnershipMonthlyPropertyTaxLabel')} (X% annual rate)</div>
                <div style={styles.mortgageMonthlyValue}>{t('comingSoonLabel')}</div>
            </div>
            <div style={styles.totalMonthlyRow}>
                <div style={styles.mortgageMonthlyLabel}>{t('propertyCostOfOwnershipMonthlyInsuranceLabel')}</div>
                <div style={styles.mortgageMonthlyValue}>{t('comingSoonLabel')}</div>
            </div>
        </>
    );
}

export default CostOfOwnershipCard;