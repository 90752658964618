export const sectionToQuestionArray = [
  ['sampleQuestionProblem'],
  [],
  [
    'sampleQuestionStructure',
    'sampleQuestionHVAC',
    'sampleQuestionRoof',
    'sampleQuestionLeakage',
    'sampleQuestionElectrical',
  ],
  ['sampleQuestionTermite'],
  ['sampleQuestionDeath', 'sampleQuestionRenovation', 'sampleQuestionModification'],
  [],
  [],
  ['sampleQuestionEasement', 'sampleQuestionNoise'],
  ['sampleQuestionFireZone'],
  ['sampleQuestionPollution'],
];
