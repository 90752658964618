import { useMutation } from '@tanstack/react-query'
import {axiosAdminWithoutAuth, axiosWithAuth} from '../utils/axios'
import useReportInfoStore from "../store/reportInfo";

export const useAdminUpdatePropertyReport = () => {
    const axios = axiosAdminWithoutAuth()
    const {
        propertyId,
        reasonForSelling,
        driversOfAppreciation,
        driversOfDepreciation,
        compsHighestMlsListingId,
        compsMlsListingId1,
        compsMlsListingId2,
        compsMlsListingId3,
        compsMlsListingId4,
        compsMlsListingId5,
        compsMlsListingId6,
        estimatePrice,
        offerInsights
    } = useReportInfoStore()
    const updatePropertyReport = async () => {
        try {
            const data = {
                property_id: propertyId,
                reason_for_selling: reasonForSelling,
                drivers_of_depreciation: driversOfDepreciation,
                drivers_of_appreciation: driversOfAppreciation,
                comps_highest_mls_listing_id: compsHighestMlsListingId,
                comps1_mls_listing_id: compsMlsListingId1,
                comps2_mls_listing_id: compsMlsListingId2,
                comps3_mls_listing_id: compsMlsListingId3,
                comps4_mls_listing_id: compsMlsListingId4,
                comps5_mls_listing_id: compsMlsListingId5,
                comps6_mls_listing_id: compsMlsListingId6,
                estimate_price: estimatePrice,
                offer_insights: offerInsights
            }

            const res = await axios.post('update_property_report', data)
            return res
        } catch (e) {
            return null
        } finally {
        }
    }

    const { mutateAsync, isLoading } = useMutation(updatePropertyReport)
    return { mutateAsync, isLoading }
}