import {useQuery} from '@tanstack/react-query'
import {axiosWithAuth} from '../utils/axios'

export const useHomeUser = () => {
  const axios = axiosWithAuth()
  return useQuery({
    queryKey: ['home8_user'],
    queryFn: async () => {
      try {
        const { data } = await axios.get('user/me')
        return data?.result
        
      } catch (e: any) {
        return []
      }
    },
    refetchOnWindowFocus: false,
  })
}
