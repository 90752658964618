import {useTranslation} from "react-i18next";


type ComparableSale = {
    address: string
    bedrooms: number
    full_bathrooms: number
    half_bathrooms: number
    lot_size: number
    living_sqft: number
    off_market_date: string
    mls_listing_id: string
    original_listing_price: number
    close_price: number
}


function ComparableSaleCard({
    address,
    bedrooms,
    full_bathrooms,
    half_bathrooms,
    lot_size,
    living_sqft,
    off_market_date,
    mls_listing_id,
    original_listing_price,
    close_price
}: ComparableSale) {
    const styles = {
        container: {
            fontFamily: 'Arial, sans-serif',
            maxWidth: '900px',
            margin: '0 auto',
            padding: '20px',
        },
        sectionTitle: {
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '10px',
            marginTop: '20px',
        },
        card: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#F6F7F8',
            borderRadius: '12px',
            marginBottom: '8px',
            paddingTop: '8px',
            paddingBottom: '8px',
            paddingLeft: '12px'
        },
        cardInfo: {
            flex: 3,
        },
        address: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '700',
            margin: 0,
            marginBottom: '12px',
        },
        floorPlan: {
            fontSize: '14px',
            lineHeight: '18px',
            marginRight: '35px'
        },
        size: {
            fontSize: '14px',
            lineHeight: '18px',
            marginRight: '35px'
        },
        marketTime: {
            fontSize: '14px',
            lineHeight: '18px',
            marginRight: '35px'
        },
        soldDate: {
            fontSize: '14px',
            lineHeight: '18px',
            marginRight: '35px'
        },
        mls: {
            fontSize: '14px',
            lineHeight: '18px',
            marginRight: '35px'
        },
        cardPrice: {
            flex: 1,
            marginRight: '12px'
        },
        soldPrice: {
            fontSize: '12px',
            fontWeight: '400',
            margin: 0,
            color: '#5D6B79',
            lineHeight: '16.34px',
            textAlign: 'left' as const,
        },
        smallPrice: {
            fontSize: '12px',
            color: '#5D6B79',
            fontWeight: '400',
            lineHeight: '16.34px',
            textAlign: 'right' as const,
        },
        bigPrice: {
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: '28px',
            color: '#161A1D',
        },
        detailsRow: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            color: '#161A1D',
        },
        priceRow: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        }
    };

    const { t, i18n } = useTranslation();

    const listing_price= original_listing_price?.toLocaleString(i18n.language, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // Removes cents
        maximumFractionDigits: 0  // Removes cents
    });
    const close_price_str = close_price?.toLocaleString(i18n.language, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // Removes cents
        maximumFractionDigits: 0  // Removes cents
    });

    return (
        <div style={styles.card}>
            <div style={styles.cardInfo}>
                <h3 style={styles.address}>{address}</h3>
                <div style={styles.detailsRow}>
                    <p style={styles.floorPlan}>{bedrooms}{t('bedsShortLabel')}{full_bathrooms + half_bathrooms * 0.5}{t('bathsShortLabel')}</p>
                    <p style={styles.size}>{living_sqft}{t('sqftLabel')}/{lot_size}{t('sqftLabel')}</p>
                    <p style={styles.mls}>{mls_listing_id}</p>
                </div>
            </div>
            <div style={styles.cardPrice}>
                <div style={styles.priceRow}>
                    <span style={styles.soldPrice}>{t('propertyComparableSalesListingLabel')}</span>
                    <span style={styles.smallPrice}>{listing_price}</span>
            </div>

            <div style={styles.priceRow}>
                    <span style={styles.soldPrice}>{t('propertyComparableSalesSoldLabel')}</span>
                    <span style={styles.bigPrice}>{close_price_str}</span></div>
            </div>
        </div>
    );
}

export default ComparableSaleCard;