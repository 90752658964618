import {useTranslation} from "react-i18next";


type TransactionRead = {
    sale_date?: string
    sale_amount?: string
    title_company?: string
    buyer_names?: string
    seller_names?: string
}

function TransactionCard({
    sale_date,
    sale_amount,
    title_company,
    buyer_names,
    seller_names
}: TransactionRead ) {
    const { t, i18n } = useTranslation();
    const styles = {
        card: {
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#F6F7F8',
            paddingTop: '8px',
            paddingLeft: '12px',
            borderRadius: '12px',
            marginBottom: '8px',
        },
        dateColumn: {
            flex: 1,
            marginRight: '20px',
        },
        detailsColumn: {
            flex: 2,
            textAlign: 'right' as const,
        },
        date: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '700',
            marginBottom: '10px',
        },
        detail: {
            fontSize: '12px',
            color: '#000000',
            marginBottom: '8px',
            lineHeight: '16.34px',
            fontWeight: '400',
        },
        price: {
            fontSize: '20px',
            lineHeight: '28px',
            fontWeight: '700',
            marginRight: '12px',
            color: '#161A1D',
        },
    };

    var sale_amount_currency = 'N/A'
    if (sale_amount) {
        const sale_amount_number = parseFloat(sale_amount);
        sale_amount_currency = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0, // Removes cents
            maximumFractionDigits: 0  // Removes cents
        }).format(sale_amount_number);
    }

    var sale_date_str = 'N/A'
    if (sale_date) {
        sale_date_str = sale_date
    }

    var buyer_names_str = 'N/A'
    if (buyer_names) {
        buyer_names_str = buyer_names
    }

    var seller_names_str = 'N/A'
    if (seller_names) {
        seller_names_str = seller_names
    }

    return (
        <div style={styles.card}>
            <div style={styles.dateColumn}>
                <p style={styles.date}>{sale_date_str}</p>
                <p style={styles.detail}>{`${t('transactionBuyerLabel')}: ${buyer_names_str}`}</p>
                <p style={styles.detail}>{`${t('transactionSellerLabel')} : ${seller_names_str}`}</p>
                <p style={styles.detail}>{`${t('transactionTitleCompany')}: ${title_company}`}</p>
            </div>
            <div style={styles.detailsColumn}>
                <p style={styles.price}>{sale_amount_currency}</p>
            </div>
        </div>
    );
}

export default TransactionCard;