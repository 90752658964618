import { useMutation, useQuery } from '@tanstack/react-query'
import { Status } from '../home8/OfferCard';
import { axiosWithAuth } from '../utils/axios'

export const useHomeUpdateOfferStatus = () => {
  const axios = axiosWithAuth()
  const updateOfferStatus = async ({
    offerId, status
  }: {offerId: number, status: Status}) => {
    try {
      await axios.put(
        `offer/${offerId}/status/${status.toString()}`,
        //{
        //  status
        //},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

    } catch {
    } 
  };
  const { mutateAsync } = useMutation(updateOfferStatus);
  return { mutateAsync };
};

