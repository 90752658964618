import React from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import { useParams, useSearchParams } from 'react-router-dom';
import { useHomeUser } from '../query/useHomeUser';
import {Property, PropertyReport} from "../store/agentInfo";
import {useHomePropertyDetail} from "../query/useHomePropertyDetail";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import ChatBottom from "./ChatBottom";
import {OFFER_AGENT_API_KEY} from "../utils/constant";

function OfferIframe() {
  //const { propertyId , offerId} = useParams()
  const { t, i18n } = useTranslation();
  const token = Cookies.get('auth');
  const [searchParams] = useSearchParams();
  const { data: propertyData } = useHomePropertyDetail(searchParams.get('property_id')!);
  const propertyInfo = propertyData as Property;
  const quickPriceCheckRaw = searchParams.get('quick_price_check') ?? '';
  const quickPriceCheck = quickPriceCheckRaw === "true" ? '&quickpricecheck=yes' : ''


  const { data: user } = useHomeUser();

  if (!user) {
    return <></>
  }

  console.log('--------------------------')
  const iframeUrl = `https://xiaxiangzhou.retool.com/p/offer_prep_app?userid=${user.id ?? ''}&propertyid=${searchParams.get('property_id') ?? ''}&offerid=${searchParams.get('offer_id') ?? ''}&accesstoken=${token!}&lang=${i18n.language}${quickPriceCheck}`
  //const iframeUrl = `https://xiaxiangzhou.retool.com/app/offer_fast_path?userid=${user.id ?? ''}&propertyid=${searchParams.get('property_id') ?? ''}&offerid=${searchParams.get('offer_id') ?? ''}&accesstoken=${token!}&lang=${i18n.language}${quickPriceCheck}`
  console.log(iframeUrl)

  /*Intercom({
    app_id: 'a9av5dxc',
    user_id: user.id ?? 'Unknown User', // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: propertyInfo?.address, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: user?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    //created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  });*/

  return (
    <div className="w-full h-full">
        <iframe
          src={iframeUrl}
          className="w-full h-full pb-20"
        ></iframe>
        <div className="absolute bottom-0 flex flex-col max-h-[76px] w-full bg-black">
          <ChatBottom disableAI={false} agentApiKey={OFFER_AGENT_API_KEY} />
        </div>
    </div>
  );
}

export default OfferIframe;
