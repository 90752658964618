import React, { HTMLProps, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

type Props = { isError: boolean; errMsg?: string } & HTMLProps<HTMLInputElement>;

const Input = ({
  isError,
  errMsg,
  placeholder,
  value,
  onFocus,
  onBlur,
  className,
  onChange,
  onSubmit,
  ...props
}: Props) => {

  return (
    <div
      className={classnames(
        'rounded-[32px] h-[52px] px-4 py-3',
        {
          'ug-input-ctn--focused': true,
        },
        className,
      )}
    >
      <input
        className="border-none outline-none h-full w-full bg-transparent text-sm"
        placeholder={placeholder}
        onChange={e => {
          onChange?.(e);
        }}
        value={value}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            onSubmit?.(e)
          }
        }}
        {...props}
      />
    </div>
  );
};

export default Input;
