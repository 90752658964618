import React, { useEffect, useState } from 'react';
import Button, { ButtonType } from '../components/Button';
import { useProperties } from '../query/useProperties';
import { ReactComponent as Add } from '../static/Add.svg';
import PropertyList from '../components/PropertyList';
import { MoonLoader } from 'react-spinners';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { useQuery } from '../utils/query';
import { useAdminProperties } from '../query/useAdminProperties';
import { useAdminPendingReviewOffers } from '../query/useAdminPendingReviewOffers';
import ReviewPendingOfferList from '../components/ReviewPendingOfferList';
import useAuthStore from '../store/auth';
import Login from './Login';

function AdminOfferReview() {
  const { data, isFetching, isLoading, isFetched } =
    useAdminPendingReviewOffers();
  console.log(data);
  const navigate = useNavigate();
  const query = useQuery();
  const { isLogin } = useAuthStore();
  if (!isLogin) {
    return <Login />;
  }
  return (
    <div className="flex justify-center pt-[156px] pb-[100px]">
      <Navbar />
      <div className="flex flex-col items-center w-[1024px]">
        <div className="flex items-center justify-between mb-[60px] w-full px-6 py-4">
          <div className="text-[40px] font-bold">Review offers</div>
        </div>
        {isFetching || isLoading ? (
          <div className="flex min-h-[200px] items-center">
            <MoonLoader size={24} />
          </div>
        ) : (
          <ReviewPendingOfferList offers={data} />
        )}
      </div>
    </div>
  );
}

export default AdminOfferReview;
