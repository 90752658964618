import React, { useState, useEffect } from 'react';
import { useHomeProperties } from '../query/useHomeProperties';
import Button, { ButtonType } from './Button';
import HouseCard, { House } from './HouseCard';
import Input from './Input';
import Sidebar from './Sidebar';
import Modal from 'react-modal';
import { useRequestPropertyStore } from '../store/requestProperty';
import { useHomeRequestProperty } from '../query/useHomeRequestProperty';
import AutoComplete from './AutoComplete';
import ChatbotBottom from './ChatbotBottom';
import { useTranslation } from 'react-i18next';
import LogRocket from 'logrocket';
import { useHomeUser } from '../query/useHomeUser';
import { axiosWithAuth } from '../utils/axios';
import ChatBottom from './ChatBottom';
import classNames from 'classnames';
import useChatBotStore from '../store/chatbot';
import {OFFER_AGENT_API_KEY} from "../utils/constant";
LogRocket.init('9ngrsj/home8');

function Properties() {
  const { data } = useHomeProperties(0, 20);
  const [search, setSearch] = useState('');
  const houseResult = data?.result;
  const { dragging } = useChatBotStore();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    streetLineOne,
    streetLineTwo,
    city,
    state,
    zipCode,
    email,
    phone,
    setStreetLineOne,
    setStreetLineTwo,
    setCity,
    setState,
    setZipCode,
    setEmail,
    setPhone,
    clear,
  } = useRequestPropertyStore();
  const { mutateAsync: requestProperty } = useHomeRequestProperty();
  console.log(houseResult);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const identifyUser = async () => {
      const axios = axiosWithAuth();
      // Assuming you have user data in your state or props
      const { data } = await axios.get('user/me');
      const user = data?.result;
      LogRocket.identify(user.id, {
        email: user.email,
      });
    };

    identifyUser();
  }, []);

  if (!houseResult) {
    return <></>;
  }

  return (
    <div className="flex bg-black min-h-full w-full h-full">
      <Sidebar />
      <Modal
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        isOpen={modalOpen}
        onRequestClose={() => {
          setModalOpen(false);
          clear();
        }}
        style={{
          content: {
            width: 600,
            height: 600,
            inset: 'unset',
            padding: 0,
            border: 'none',
            borderRadius: '16px',
            boxShadow:
              '0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)',
            overflow: 'hidden',
          },
          overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0, 0, 0, 0.55)',
            zIndex: 100,
          },
        }}
        htmlOpenClassName="overflow-hidden"
        shouldCloseOnEsc={true}
      >
        <div className="flex flex-col items-start justify-center h-full w-full gap-4 p-6">
          <div className="text-xl font-bold">{t('requestPropertyReport')}</div>
          <div className="text-sm">{t('requestPropertyReportNote')}</div>
          <div className="text-sm">
            {t('requestPropertyReportAddressLabel')}
          </div>
          <Input
            className="w-full bg-gray-100"
            placeholder="Street address"
            value={streetLineOne}
            isError={false}
            onChange={(e) => {
              setStreetLineOne((e.target as HTMLInputElement).value);
            }}
          />
          <Input
            className="w-full bg-gray-100"
            placeholder="Apt, Suite, Unit, Building (optional)"
            value={streetLineTwo}
            isError={false}
            onChange={(e) => {
              setStreetLineTwo((e.target as HTMLInputElement).value);
            }}
          />
          <div className="flex w-full gap-3">
            <Input
              className="w-1/2 bg-gray-100"
              placeholder="City"
              value={city}
              isError={false}
              onChange={(e) => {
                setCity((e.target as HTMLInputElement).value);
              }}
            />
            <Input
              className="w-1/4 bg-gray-100"
              placeholder="State"
              value={state}
              isError={false}
              onChange={(e) => {
                setState((e.target as HTMLInputElement).value);
              }}
            />
            <Input
              className="w-1/4 bg-gray-100"
              placeholder="Zip code"
              value={zipCode}
              isError={false}
              onChange={(e) => {
                setZipCode((e.target as HTMLInputElement).value);
              }}
            />
          </div>
          <div className="text-sm">{t('requestPropertyReportNotifNote')}</div>
          <Input
            className="w-full bg-gray-100"
            placeholder="Email"
            value={email}
            isError={false}
            onChange={(e) => {
              setEmail((e.target as HTMLInputElement).value);
            }}
          />
          <Input
            className="w-full bg-gray-100"
            placeholder="Phone number"
            value={phone}
            isError={false}
            onChange={(e) => {
              setPhone((e.target as HTMLInputElement).value);
            }}
          />
          <div className="flex w-full justify-end">
            <Button
              type={ButtonType.DARK}
              content={t('requestPropertyReportSendNote')}
              onClick={async () => {
                requestProperty({
                  address: `${streetLineOne}, ${streetLineTwo}, ${city}, ${state}, ${zipCode}`,
                  email: email,
                });
                setModalOpen(false);
                clear();
              }}
            />
          </div>
        </div>
      </Modal>
      <div className="flex flex-col min-h-screen w-full bg-black">
        <div className="relative mt-3 mr-3 rounded-[20px] bg-gray-50 grow flex flex-col overflow-hidden">
          <div className="px-3 py-4 flex gap-4 border-b border-solid">
            <Button
              type={ButtonType.BORDERED}
              loading={false}
              disabled={false}
              content={t('requestPropertyReport')}
              onClick={async () => {
                setModalOpen(true);
              }}
            />
            {/*<Input
            className="grow"
            placeholder="Search property by address, zip code, city"
            value={search}
            isError={false}
            onChange={(e) => {
              setSearch((e.target as HTMLInputElement).value);
            }}
          />*/}
            <AutoComplete></AutoComplete>
          </div>
          <div
            className={classNames('h-full', {
              'overflow-auto': !dragging,
              'overflow-hidden': dragging,
            })}
          >
            <div className="p-3 flex flex-wrap gap-3">
              {houseResult.map((house: House) => (
                <HouseCard house={house} />
              ))}
            </div>
          </div>
        </div>
        <ChatBottom disableAI={false} agentApiKey={OFFER_AGENT_API_KEY} />
      </div>
    </div>
  );
}

export default Properties;
