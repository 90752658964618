import "react-pdf/dist/esm/Page/TextLayer.css";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import { ReactComponent as Error } from '../static/Error.svg'
import { ReactComponent as Success } from '../static/Success.svg'
import Input from '../components/Input'
import { Document, Page, pdfjs } from 'react-pdf'
import { useNavigate, useParams } from 'react-router-dom'
import Navbar from '../components/Navbar'
import agentAvatar from '../static/AgentAvatar.svg'
import customerAvatar from '../static/CustomerAvatar.svg'
import { usePropertyDetail } from '../query/usePropertyDetail'
import Button, { ButtonType } from '../components/Button'
import { ReactComponent as Download } from '../static/Download.svg'
import { axiosWithAuth } from '../utils/axios'
import Cookies from 'js-cookie'
import Table from 'rc-table'
import TextArea from '../components/TextArea'
import { MoonLoader, PulseLoader, SyncLoader } from 'react-spinners'
import ReactMarkdown from 'react-markdown'
import { TextWithLinks } from '../components/TextWithLink'
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import {
  Question,
  useHouseReport,
  useRegenerateReportQuestion,
} from '../query/useHouseReport'
import { Message, useToaster } from 'rsuite'
import { queryClient } from '../App'
import { usePropertyReport } from "../query/usePropertyReport";
import {
  ComparableSale,
  EnvironmentalPollution,
  InspectionProblem,
  TermiteProblem,
  PermitRead,
  Property,
  PropertyReport, NatureHazardRisk, FileStatus, SellingPoint, Concern
} from "../store/agentInfo";
import classnames from "classnames";
import useReportInfoStore from "../store/reportInfo";
import {useAdminUpdatePropertyReport} from "../query/useAdminUpdatePropertyReport";
import {useAdminPropertyDetail} from "../query/useAdminPropertyDetail";
import {useAdminPropertyReport} from "../query/useAdminPropertyReport";
import classNames from "classnames";
import useAuthStore from "../store/auth";
import Login from "./Login";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

export type Sentence = {
  role: 'human' | 'ai'
  content: string
  finished: boolean
}

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

function Test() {
  const { propertyId } = useParams()
  const { data } = useAdminPropertyDetail(propertyId)

  const {
    reasonForSelling,
    setReasonForSelling,
    driversOfDepreciation,
    setDriversOfDepreciation,
    driversOfAppreciation,
    setDriversOfAppreciation,
    compsHighestMlsListingId,
    setCompsHighestMlsListingId,
    compsMlsListingId1,
    setCompsMlsListingId1,
    compsMlsListingId2,
    setCompsMlsListingId2,
    compsMlsListingId3,
    setCompsMlsListingId3,
    compsMlsListingId4,
    setCompsMlsListingId4,
    compsMlsListingId5,
    setCompsMlsListingId5,
    compsMlsListingId6,
    setCompsMlsListingId6,
    estimatePrice,
    setEstimatePrice,
    offerInsights,
    setOfferInsights,
    initForUpdate,
    successModalOpen,
    setSuccessModalOpen,
    errorModalOpen,
    setErrorModalOpen,
  } = useReportInfoStore()

  const [chatHistory, setChatHistory] = useState<Sentence[]>([])
  const [conversationId, setConversationId] = useState(crypto.randomUUID())
  const [instruction, setInstruction] = useState('')
  const scrollRef = useRef<HTMLDivElement>(null)
  const propertyInfo = data as Property
  const addToHistory = (content: Sentence[]) =>
    setChatHistory((history) => {
      return [...history, ...content]
    })
  useEffect(() => {
    if (data) {
      setChatHistory([
          {
            role: 'ai',
            content: data?.open_message,
            finished: true,
          }
      ])
    }
  }, [data])

  const LinkRenderer = ({ node, ...props }: any) => (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  )

  const handleSubmit = async () => {
    if (scrollRef.current) {
      scrollRef.current.style.overflow = 'hidden'
    }
    const text = instruction.toString()
    setInstruction('')
    addToHistory([
      { role: 'human', content: text, finished: true },
      { role: 'ai', content: '', finished: false },
    ])
    const token = Cookies.get('auth')
    const response = await fetch(
      'https://admin-o574.onrender.com/property_chat',
      {
        method: 'POST',
        body: JSON.stringify({
          property_id: parseInt(propertyId ?? ''),
          user_input: text,
          chat_history: chatHistory.map((sentence) => {
            return {
              role: sentence.role,
              content: sentence.content,
            }
          }),
          conversation_id: conversationId,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: token ? `Bearer ${token}` : '',
        },
      },
    )
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
    const reader = response.body
      ?.pipeThrough(new TextDecoderStream())
      .getReader()
    if (reader) {
      while (true) {
        const { value, done } = await reader.read()
        if (done) {
          setChatHistory((history) => {
            const newHistory = [...history]

            if (!newHistory[newHistory.length - 1].finished) {
              newHistory[newHistory.length - 1].finished = true
              try {
                fetch(
                    'https://admin-o574.onrender.com/save_property_chat_history',
                    {
                      method: 'POST',
                      body: JSON.stringify({
                        property_id: parseInt(propertyId ?? ''),
                        user_id: 3,
                        chat_history: newHistory.map((sentence) => {
                          return {
                            role: sentence.role,
                            content: sentence.content,
                          }
                        }),
                        conversation_id: conversationId
                      }),
                      headers: {
                        'Content-Type': 'application/json',
                      },
                    },
                )
              } catch {

              }
            }

            return newHistory
          })
          break
        }
        if (scrollRef.current) {
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight
        }
        setChatHistory((history) => {
          const newHistory = [...history]
          if (process.env.NODE_ENV === 'development') {
            console.log('check')
            if (!newHistory[newHistory.length - 1].content.endsWith(value)) {
              newHistory[newHistory.length - 1].content += value
            }
          } else {
            newHistory[newHistory.length - 1].content += value
          }

          return newHistory
        })
        await timeout(100)
      }
    }
    if (scrollRef.current) {
      scrollRef.current.style.overflow = 'auto'
    }
  }

  //const { data: reportData } = useHouseReport(propertyId)
  const propertyIdInt = parseInt(propertyId ?? '')
  const { data: reportData, isFetching, isLoading, refetch } = useAdminPropertyReport(propertyIdInt, 'en')
  const propertyReportInfo = reportData as PropertyReport
  console.log(propertyReportInfo)
  const { mutateAsync } = useRegenerateReportQuestion()
  //const questionList = reportData ?? {}
  //const questionList = []
  const toaster = useToaster()
  const property_highlight_columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 20,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      width: 20,
    },
  ];
  const property_highlight_data = [
    { name: 'Property type', value: propertyInfo?.property_type },
    { name: 'Bedrooms', value: propertyReportInfo?.bedrooms },
    { name: 'Full bathrooms', value: propertyReportInfo?.full_bathrooms },
    { name: 'Half bathrooms', value: propertyReportInfo?.half_bathrooms },
    { name: 'Living area (sqft)', value: propertyReportInfo?.living_sqft },
    { name: 'Lot size (sqft)', value: propertyReportInfo?.lot_size },
    { name: 'Built year', value: propertyReportInfo?.built_year },
    { name: 'Rental estimate', value: `average $${propertyReportInfo?.rental_estimate.average}, low $${propertyReportInfo?.rental_estimate.low}, high $${propertyReportInfo?.rental_estimate.high}` },
    { name: 'On market date', value: propertyReportInfo?.on_market_date },
  ];

  const permit_history_columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 20,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 20,
    },
    {
      title: 'Permit number',
      dataIndex: 'permit_number',
      key: 'permit_number',
      width: 20,
    },
    {
      title: 'Permit status',
      dataIndex: 'permit_status',
      key: 'permit_status',
      width: 20,
    },
    {
      title: 'Permit effective date',
      dataIndex: 'permit_effective_date',
      key: 'permit_effective_date',
      width: 20,
    },
    {
      title: 'Job value',
      dataIndex: 'job_value',
      key: 'job_value',
      width: 20,
    },
    {
      title: 'Contractors',
      dataIndex: 'contractors',
      key: 'contractors',
      width: 20,
    },
  ];
  const permit_history_data = propertyReportInfo?.permits.map((permit: PermitRead) => ({
    type: permit.type,
    description: permit.description,
    permit_number: permit.permit_number,
    permit_status: permit.permit_status,
    permit_effective_date: permit.permit_effective_date,
    job_value: permit.job_value,
    contractors: permit.contractors.map(u => u.name).join(', ')
  }))

  const nature_hazard_risks_columns = [
    {
      title: 'Issue',
      dataIndex: 'issue',
      key: 'issue',
      width: 15,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 15,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 40,
    },
  ];
  const nature_hazard_risks_data = propertyReportInfo?.nature_hazard_risks?.risks.map((natureHazardRisk: NatureHazardRisk) => ({
    issue: natureHazardRisk.issue,
    status: natureHazardRisk.status,
    description: natureHazardRisk.description
  }))

  const environmental_pollutions_columns = [
    {
      title: 'Issue',
      dataIndex: 'issue',
      key: 'issue',
      width: 15,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 15,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 40,
    },
  ];
  const environmental_pollutions_data = propertyReportInfo?.environmental_pollutions?.pollutions.map((environmentalPollution: EnvironmentalPollution) => ({
    issue: environmentalPollution.issue,
    status: environmentalPollution.status,
    description: environmentalPollution.description
  }))

  const inspection_report_columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: 20,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 20,
    },
    {
      title: 'Defects',
      dataIndex: 'defects',
      key: 'defects',
      width: 20,
    },
    {
      title: 'Recommendations',
      dataIndex: 'recommendations',
      key: 'recommendations',
      width: 20,
    },
    {
      title: 'Repair Cost',
      dataIndex: 'repair_cost',
      key: 'repair_cost',
      width: 20,
    },
  ];
  const inspection_report_data = propertyReportInfo?.inspection_report.problems.map((problem: InspectionProblem) => ({
    category: problem.category,
    status: problem.status,
    defects: problem.defects,
    recommendations: problem.recommendations,
    repair_cost: problem.repair_cost
  }))

  const termite_report_columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: 20,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 20,
    },
    {
      title: 'Defects',
      dataIndex: 'defects',
      key: 'defects',
      width: 20,
    },
    {
      title: 'Recommendations',
      dataIndex: 'recommendations',
      key: 'recommendations',
      width: 20,
    },
    {
      title: 'Repair Cost',
      dataIndex: 'repair_cost',
      key: 'repair_cost',
      width: 20,
    },
  ];
  const termite_report_data = propertyReportInfo?.termite_report.problems.map((problem: TermiteProblem) => ({
    category: problem.category,
    status: problem.status,
    defects: problem.defects,
    recommendations: problem.recommendations,
    repair_cost: problem.repair_cost
  }))

  const selling_points_columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: 20,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'status',
      width: 20,
    },
  ];
  const selling_points_data = propertyReportInfo?.selling_point.map((selling_point: SellingPoint) => ({
    category: selling_point.category,
    description: selling_point.description
  }))

  const concerns_columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: 20,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'status',
      width: 20,
    },
  ];
  const concerns_data = propertyReportInfo?.concerns.map((concern: Concern) => ({
    category: concern.category,
    description: concern.description
  }))

  const [isEditing, setIsEditing] = useState(false)
  const { mutateAsync: updatePropertyReport, isLoading: isUpdating } = useAdminUpdatePropertyReport()

  useEffect(() => {
    if (isEditing) {
      initForUpdate(propertyReportInfo)
    }
  }, [isEditing])

  const { isLogin } = useAuthStore()
  if (!isLogin) {
    return <Login/>
  }

  if (isEditing) {
    return (
        <div className="flex justify-center pt-[156px] pb-[200px]">
          <Navbar />
          <div className="flex flex-col items-center w-[1024px] gap-6">
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-col gap-2 px-6 w-full">
                <div className="text-base font-bold">Reason for selling</div>
                <div className="text-base">Required, 1000 characters max</div>
              </div>
              <TextArea
                  value={reasonForSelling}
                  isError={false}
                  onChange={(e) => {
                    setReasonForSelling((e.target as HTMLInputElement).value)
                  }}
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-col gap-2 px-6 w-full">
                <div className="text-base font-bold">Drivers of depreciation</div>
                <div className="text-base">Required, 1000 characters max</div>
              </div>
              <TextArea
                  value={driversOfDepreciation}
                  isError={false}
                  onChange={(e) => {
                    setDriversOfDepreciation((e.target as HTMLInputElement).value)
                  }}
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-col gap-2 px-6 w-full">
                <div className="text-base font-bold">Drivers of appreciation</div>
                <div className="text-base">Required, 1000 characters max</div>
              </div>
              <TextArea
                  value={driversOfAppreciation}
                  isError={false}
                  onChange={(e) => {
                    setDriversOfAppreciation((e.target as HTMLInputElement).value)
                  }}
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-col gap-2 px-6 w-full">
                <div className="text-base font-bold">Comparable Sales</div>
                <div className="text-base">Historical Highest (MLS Listing ID)</div>
                <Input
                    value={compsHighestMlsListingId}
                    isError={false}
                    onChange={(e) => {
                      setCompsHighestMlsListingId((e.target as HTMLInputElement).value)
                    }}
                />
                <div className="text-base">Comparable Sales 1 (MLS Listing ID)</div>
                <Input
                    value={compsMlsListingId1}
                    isError={false}
                    onChange={(e) => {
                      setCompsMlsListingId1((e.target as HTMLInputElement).value)
                    }}
                />
                <div className="text-base">Comparable Sales 2 (MLS Listing ID)</div>
                <Input
                    value={compsMlsListingId2}
                    isError={false}
                    onChange={(e) => {
                      setCompsMlsListingId2((e.target as HTMLInputElement).value)
                    }}
                />
                <div className="text-base">Comparable Sales 3 (MLS Listing ID)</div>
                <Input
                    value={compsMlsListingId3}
                    isError={false}
                    onChange={(e) => {
                      setCompsMlsListingId3((e.target as HTMLInputElement).value)
                    }}
                />
                <div className="text-base">Comparable Sales 4 (MLS Listing ID)</div>
                <Input
                    value={compsMlsListingId4}
                    isError={false}
                    onChange={(e) => {
                      setCompsMlsListingId4((e.target as HTMLInputElement).value)
                    }}
                />
                <div className="text-base">Comparable Sales 5 (MLS Listing ID)</div>
                <Input
                    value={compsMlsListingId5}
                    isError={false}
                    onChange={(e) => {
                      setCompsMlsListingId5((e.target as HTMLInputElement).value)
                    }}
                />
                <div className="text-base">Comparable Sales 6 (MLS Listing ID)</div>
                <Input
                    value={compsMlsListingId6}
                    isError={false}
                    onChange={(e) => {
                      setCompsMlsListingId6((e.target as HTMLInputElement).value)
                    }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-col gap-2 px-6 w-full">
                <div className="text-base font-bold">Estimate price</div>
                <div className="text-base">Required, 100 characters max</div>
              </div>
              <Input
                  value={estimatePrice}
                  isError={false}
                  onChange={(e) => {
                    setEstimatePrice(Number((e.target as HTMLInputElement).value))
                  }}
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-col gap-2 px-6 w-full">
                <div className="text-base font-bold">Offer related insights</div>
                <div className="text-base">Required, 1000 characters max</div>
              </div>
              <TextArea
                  value={offerInsights}
                  isError={false}
                  onChange={(e) => {
                    setOfferInsights((e.target as HTMLInputElement).value)
                  }}
              />
            </div>

            <div
                className="fixed flex items-center justify-center w-full py-6 bottom-0 gap-4 border-t border-t-gray-200 bg-white">
              <Button
                  type={ButtonType.SECONDARY}
                  content="Discard changes"
                  loading={isUpdating}
                  onClick={() => {
                    setIsEditing(false)
                  }}
              />
              <Button
                  type={ButtonType.PRIMARY}
                  content="Save changes"
                  loading={isUpdating}
                  onClick={async () => {
                    await updatePropertyReport()
                    setIsEditing(false)
                    refetch()
                  }}
              />
            </div>
          </div>
        </div>
    )
  }

  return (
      <div className="flex w-full h-screen">
        <Navbar/>
        <div className="w-2/3 h-full overflow-scroll border-r border-solid">
          <div className="flex flex-col gap-8 p-10 pt-[120px]">
            <div className="flex flex-col gap-3">
              <div className="flex justify-between">
                <div className="flex flex-col gap-1 px-6 w-full">
                  <div className="text-base font-bold">Need Manual Input</div>
                </div>
                <div className="flex items-center gap-5">
                  <Button
                      type={ButtonType.PRIMARY}
                      content="Edit"
                      onClick={async () => {
                        setIsEditing(true)
                      }}
                  />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">Reason for selling (Background stories)</div>
                    <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                          a: LinkRenderer,
                        }}
                    >
                      {propertyReportInfo?.background_stories.reason_for_selling}
                    </ReactMarkdown>
                  </div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">Potential drivers of depreciation</div>
                    <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                          a: LinkRenderer,
                        }}
                    >
                      {propertyReportInfo?.potential_drivers_of_depreciation}
                    </ReactMarkdown>
                  </div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">Potential drivers of appreciation</div>
                    <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                          a: LinkRenderer,
                        }}
                    >
                      {propertyReportInfo?.potential_drivers_of_appreciation}
                    </ReactMarkdown>
                  </div>
            <div className="flex flex-col gap-1 px-6 w-full">
              <div className="text-base font-bold">Comparable sales</div>
              <div
                  key="highest_comps"
                  className={classnames("h-[80px] px-6 py-4 flex items-start justify-between", {
                    "bg-gray-50": true,
                    "bg-white": false,
                  })}>
                <div className="flex flex-col gap-2 items-start">
                  <div className="text-base font-bold">
                    historical_highest_comps: {propertyReportInfo?.comparable_sales?.comps_highest?.address} (sold on {propertyReportInfo?.comparable_sales?.comps_highest?.off_market_date}, price: ${propertyReportInfo?.comparable_sales?.comps_highest?.close_price})
                  </div>
                  <div>{propertyReportInfo?.comparable_sales?.comps_highest?.bedrooms}B/{propertyReportInfo?.comparable_sales?.comps_highest?.full_bathrooms}B {propertyReportInfo?.comparable_sales?.comps_highest?.living_sqft}sqft/{propertyReportInfo?.comparable_sales?.comps_highest?.lot_size}sqft {propertyReportInfo?.comparable_sales?.comps_highest?.mls_listing_id}</div>
                </div>
              </div>
              <div
                  key="comps1"
                  className={classnames("h-[80px] px-6 py-4 flex items-start justify-between", {
                    "bg-gray-50": false,
                    "bg-white": true,
                  })}>
                <div className="flex flex-col gap-2 items-start">
                  <div className="text-base font-bold">
                    comps1: {propertyReportInfo?.comparable_sales?.comps1?.address} (sold on {propertyReportInfo?.comparable_sales?.comps1?.off_market_date}, price: ${propertyReportInfo?.comparable_sales?.comps1?.close_price})
                  </div>
                  <div>{propertyReportInfo?.comparable_sales?.comps1?.bedrooms}B/{propertyReportInfo?.comparable_sales?.comps1?.full_bathrooms}B {propertyReportInfo?.comparable_sales?.comps1?.living_sqft}sqft/{propertyReportInfo?.comparable_sales?.comps1?.lot_size}sqft {propertyReportInfo?.comparable_sales?.comps1?.mls_listing_id}</div>
                </div>
              </div>
              <div
                  key="comps2"
                  className={classnames("h-[80px] px-6 py-4 flex items-start justify-between", {
                    "bg-gray-50": true,
                    "bg-white": false,
                  })}>
                <div className="flex flex-col gap-2 items-start">
                  <div className="text-base font-bold">
                    comps2: {propertyReportInfo?.comparable_sales?.comps2?.address} (sold
                    on {propertyReportInfo?.comparable_sales?.comps2?.off_market_date}, price:
                    ${propertyReportInfo?.comparable_sales?.comps2?.close_price})
                  </div>
                  <div>{propertyReportInfo?.comparable_sales?.comps2?.bedrooms}B/{propertyReportInfo?.comparable_sales?.comps2?.full_bathrooms}B {propertyReportInfo?.comparable_sales?.comps2?.living_sqft}sqft/{propertyReportInfo?.comparable_sales?.comps2?.lot_size}sqft {propertyReportInfo?.comparable_sales?.comps2?.mls_listing_id}</div>
                </div>
              </div>
              <div
                  key="comps3"
                  className={classnames("h-[80px] px-6 py-4 flex items-start justify-between", {
                    "bg-gray-50": false,
                    "bg-white": true,
                  })}>
                <div className="flex flex-col gap-2 items-start">
                  <div className="text-base font-bold">
                    comps3: {propertyReportInfo?.comparable_sales?.comps3?.address} (sold
                    on {propertyReportInfo?.comparable_sales?.comps3?.off_market_date}, price:
                    ${propertyReportInfo?.comparable_sales?.comps3?.close_price})
                  </div>
                  <div>{propertyReportInfo?.comparable_sales?.comps3?.bedrooms}B/{propertyReportInfo?.comparable_sales?.comps3?.full_bathrooms}B {propertyReportInfo?.comparable_sales?.comps3?.living_sqft}sqft/{propertyReportInfo?.comparable_sales?.comps3?.lot_size}sqft {propertyReportInfo?.comparable_sales?.comps3?.mls_listing_id}</div>
                </div>
              </div>
              <div
                  key="comps4"
                  className={classnames("h-[80px] px-6 py-4 flex items-start justify-between", {
                    "bg-gray-50": true,
                    "bg-white": false,
                  })}>
                <div className="flex flex-col gap-2 items-start">
                  <div className="text-base font-bold">
                    comps4: {propertyReportInfo?.comparable_sales?.comps4?.address} (sold
                    on {propertyReportInfo?.comparable_sales?.comps4?.off_market_date}, price:
                    ${propertyReportInfo?.comparable_sales?.comps4?.close_price})
                  </div>
                  <div>{propertyReportInfo?.comparable_sales?.comps4?.bedrooms}B/{propertyReportInfo?.comparable_sales?.comps4?.full_bathrooms}B {propertyReportInfo?.comparable_sales?.comps4?.living_sqft}sqft/{propertyReportInfo?.comparable_sales?.comps4?.lot_size}sqft {propertyReportInfo?.comparable_sales?.comps4?.mls_listing_id}</div>
                </div>
              </div>
              <div
                  key="comps5"
                  className={classnames("h-[80px] px-6 py-4 flex items-start justify-between", {
                    "bg-gray-50": false,
                    "bg-white": true,
                  })}>
                <div className="flex flex-col gap-2 items-start">
                  <div className="text-base font-bold">
                    comps5: {propertyReportInfo?.comparable_sales?.comps5?.address} (sold
                    on {propertyReportInfo?.comparable_sales?.comps5?.off_market_date}, price:
                    ${propertyReportInfo?.comparable_sales?.comps5?.close_price})
                  </div>
                  <div>{propertyReportInfo?.comparable_sales?.comps5?.bedrooms}B/{propertyReportInfo?.comparable_sales?.comps5?.full_bathrooms}B {propertyReportInfo?.comparable_sales?.comps5?.living_sqft}sqft/{propertyReportInfo?.comparable_sales?.comps5?.lot_size}sqft {propertyReportInfo?.comparable_sales?.comps5?.mls_listing_id}</div>
                </div>
              </div>
              <div
                  key="comps6"
                  className={classnames("h-[80px] px-6 py-4 flex items-start justify-between", {
                    "bg-gray-50": true,
                    "bg-white": false,
                  })}>
                <div className="flex flex-col gap-2 items-start">
                  <div className="text-base font-bold">
                    comps6: {propertyReportInfo?.comparable_sales?.comps6?.address} (sold
                    on {propertyReportInfo?.comparable_sales?.comps6?.off_market_date}, price:
                    ${propertyReportInfo?.comparable_sales?.comps6?.close_price})
                  </div>
                  <div>{propertyReportInfo?.comparable_sales?.comps6?.bedrooms}B/{propertyReportInfo?.comparable_sales?.comps6?.full_bathrooms}B {propertyReportInfo?.comparable_sales?.comps6?.living_sqft}sqft/{propertyReportInfo?.comparable_sales?.comps6?.lot_size}sqft {propertyReportInfo?.comparable_sales?.comps6?.mls_listing_id}</div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-1 px-6 w-full">
              <div className="text-base font-bold">Estimate price</div>
              <div className="text-base">${propertyReportInfo?.estimate_price.price}</div>
            </div>
            <div className="flex flex-col gap-1 px-6 w-full">
              <div className="text-base font-bold">Offer related insights</div>
              <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    a: LinkRenderer,
                  }}
              >
                {propertyReportInfo?.offer_related_insights}
              </ReactMarkdown>
                  </div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">###################(AI generated)Need Manual
                      Check###################
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">Selling point</div>
                    <Table
                        columns={selling_points_columns}
                        data={selling_points_data}
                    />
                  </div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">Concerns</div>
                    <Table
                        columns={concerns_columns}
                        data={concerns_data}
                    />
                  </div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">Summary</div>
                    <div className="text-base"> Coming soon...</div>
                  </div>
                  <div>==================================================================================================</div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">Inspection report</div>
                    <div className="text-base">
                      <div
                          onClick={async () => {
                            try {
                              const response = await fetch(
                                  propertyReportInfo?.inspection_report.source,
                              )
                              const blob = await response.blob()
                              const link = document.createElement('a')
                              link.href = URL.createObjectURL(blob)
                              link.download = `Inspection Report-${propertyInfo.address}.pdf` // Replace 'filename.ext' with your desired filename
                              document.body.appendChild(link)
                              link.click()
                              document.body.removeChild(link)
                            } catch (error) {
                              console.error('Error downloading file:', error)
                            }
                          }}
                          style={{textDecoration: "underline", color: "#3b82f6", cursor: "pointer"}}
                      >
                        Source: Inspection Report
                      </div>
                    </div>
                    <Table
                        columns={inspection_report_columns}
                        data={inspection_report_data}
                    />
                  </div>
                  <div>==================================================================================================</div>
            <div className="flex flex-col gap-1 px-6 w-full">
              <div className="text-base font-bold">Termite report</div>
              <div
                  onClick={async () => {
                    try {
                      const response = await fetch(
                          propertyReportInfo?.termite_report.source,
                      )
                      const blob = await response.blob()
                      const link = document.createElement('a')
                      link.href = URL.createObjectURL(blob)
                      link.download = `Termite Report-${propertyInfo.address}.pdf` // Replace 'filename.ext' with your desired filename
                      document.body.appendChild(link)
                      link.click()
                      document.body.removeChild(link)
                    } catch (error) {
                      console.error('Error downloading file:', error)
                    }
                  }}
                  style={{textDecoration: "underline", color: "#3b82f6", cursor: "pointer"}}
              >
                Source: Termite Report
              </div>
              <Table
                  columns={termite_report_columns}
                  data={termite_report_data}
              />
            </div>
            <div>==================================================================================================</div>
            <div className="flex flex-col gap-1 px-6 w-full">
              <div className="text-base font-bold">Seller property questionaire (Background stories)</div>
              {propertyReportInfo?.background_stories.seller_property_questionaire?.source !== null && <div
                  onClick={async () => {
                    try {
                      const response = await fetch(
                          propertyReportInfo?.background_stories.seller_property_questionaire?.source!,
                      )
                      const blob = await response.blob()
                      const link = document.createElement('a')
                      link.href = URL.createObjectURL(blob)
                      link.download = `SPQ-${propertyInfo.address}.pdf` // Replace 'filename.ext' with your desired filename
                      document.body.appendChild(link)
                      link.click()
                      document.body.removeChild(link)
                    } catch (error) {
                      console.error('Error downloading file:', error)
                    }
                  }}
                  style={{textDecoration: "underline", color: "#3b82f6", cursor: "pointer"}}
              >
                Source: Seller Property Questionaire
              </div>}
              <ul style={{
                listStyleType: 'none',
                padding: 0
              }}>
                {propertyReportInfo && propertyReportInfo.background_stories.seller_property_questionaire && propertyReportInfo.background_stories.seller_property_questionaire.summary.map((spq_item, index) => (
                    <li style={{
                      fontSize: '14px',
                      lineHeight: '18px',
                      fontWeight: '400',
                      color: '#161A1D',
                      listStyleType: 'disc', /* default bullet points */
                      marginLeft: '20px'
                    }}>{spq_item}</li>
                ))}
              </ul>
            </div>
            <div>==================================================================================================</div>
            <div className="flex flex-col gap-1 px-6 w-full">
              <div className="text-base font-bold">Transaction disclosure statement (Background stories)</div>
              {propertyReportInfo?.background_stories.transaction_disclosure_statement?.source !== null && <div
                  onClick={async () => {
                    try {
                      const response = await fetch(
                          propertyReportInfo?.background_stories.transaction_disclosure_statement?.source!,
                      )
                      const blob = await response.blob()
                      const link = document.createElement('a')
                      link.href = URL.createObjectURL(blob)
                      link.download = `TDS-${propertyInfo.address}.pdf` // Replace 'filename.ext' with your desired filename
                      document.body.appendChild(link)
                      link.click()
                      document.body.removeChild(link)
                    } catch (error) {
                      console.error('Error downloading file:', error)
                    }
                  }}
                  style={{textDecoration: "underline", color: "#3b82f6", cursor: "pointer"}}
              >
                Source: Transaction Disclosure Statement
              </div>}

              <div
                  className="text-base">{propertyReportInfo?.background_stories.transaction_disclosure_statement?.seller_items_description}</div>
              <img
                  src={propertyReportInfo?.background_stories.transaction_disclosure_statement?.seller_items_url}
                  alt="new"
              />
              <ul style={{
                listStyleType: 'none',
                padding: 0
              }}>
                {propertyReportInfo && propertyReportInfo.background_stories.transaction_disclosure_statement && propertyReportInfo.background_stories.transaction_disclosure_statement.summary?.map((tds_item, index) => (
                    <li style={{
                      fontSize: '14px',
                      lineHeight: '18px',
                      fontWeight: '400',
                      color: '#161A1D',
                      listStyleType: 'disc', /* default bullet points */
                      marginLeft: '20px'
                    }}>{tds_item}</li>
                ))}
              </ul>
            </div>
            <div>==================================================================================================</div>
            <div className="flex flex-col gap-1 px-6 w-full">
              <div className="text-base font-bold">Legal and title</div>
              <div
                  onClick={async () => {
                    try {
                      const response = await fetch(
                          propertyReportInfo?.legal_and_title?.source,
                      )
                      const blob = await response.blob()
                      const link = document.createElement('a')
                      link.href = URL.createObjectURL(blob)
                      link.download = `Prelim-${propertyInfo.address}.pdf` // Replace 'filename.ext' with your desired filename
                      document.body.appendChild(link)
                      link.click()
                      document.body.removeChild(link)
                    } catch (error) {
                      console.error('Error downloading file:', error)
                    }
                  }}
                  style={{textDecoration: "underline", color: "#3b82f6", cursor: "pointer"}}
              >
                Source: Prelim Report
              </div>

              <div className="text-base">Below is plot map</div>
              <div>
                <Document file={propertyReportInfo?.legal_and_title?.plot_map_url}>
                  <Page pageNumber={1}/>
                </Document>
              </div>
            </div>
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  a: LinkRenderer,
                }}
            >
              {propertyReportInfo?.legal_and_title?.prelim_info}
            </ReactMarkdown>
            <div>==================================================================================================</div>
            <div className="flex flex-col gap-1 px-6 w-full">
              <div className="text-base font-bold">Nature hazard risks</div>
              <div
                  onClick={async () => {
                    try {
                      const response = await fetch(
                          propertyReportInfo?.nature_hazard_risks?.source,
                      )
                      const blob = await response.blob()
                      const link = document.createElement('a')
                      link.href = URL.createObjectURL(blob)
                      link.download = `NHD-${propertyInfo.address}.pdf` // Replace 'filename.ext' with your desired filename
                      document.body.appendChild(link)
                      link.click()
                      document.body.removeChild(link)
                    } catch (error) {
                      console.error('Error downloading file:', error)
                    }
                  }}
                  style={{textDecoration: "underline", color: "#3b82f6", cursor: "pointer"}}
              >
                Source: Nature Hazard Disclosure
              </div>
              <Table
                  columns={nature_hazard_risks_columns}
                  data={nature_hazard_risks_data}
              />
            </div>
            <div>==================================================================================================</div>
            <div className="flex flex-col gap-1 px-6 w-full">
              <div className="text-base font-bold">Environmental pollution</div>
              <div
                  onClick={async () => {
                    try {
                      const response = await fetch(
                          propertyReportInfo?.environmental_pollutions?.source,
                      )
                      const blob = await response.blob()
                      const link = document.createElement('a')
                      link.href = URL.createObjectURL(blob)
                      link.download = `NHD-${propertyInfo.address}.pdf` // Replace 'filename.ext' with your desired filename
                      document.body.appendChild(link)
                      link.click()
                      document.body.removeChild(link)
                    } catch (error) {
                      console.error('Error downloading file:', error)
                    }
                  }}
                  style={{textDecoration: "underline", color: "#3b82f6", cursor: "pointer"}}
              >
                Source: Nature Hazard Disclosure
              </div>
              <Table
                  columns={environmental_pollutions_columns}
                  data={environmental_pollutions_data}
              />
            </div>
            {/*<div className="flex flex-col gap-1 px-6 w-full">
            <div className="text-base font-bold">Noise level</div>
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  a: LinkRenderer,
                }}
            >
              {propertyReportInfo?.noise_level}
            </ReactMarkdown>
          </div>
          <div className="flex flex-col gap-1 px-6 w-full">
            <div className="text-base font-bold">T section</div>
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  a: LinkRenderer,
                }}
            >
              {propertyReportInfo?.t_section}
            </ReactMarkdown>
          </div>
          <div className="flex flex-col gap-1 px-6 w-full">
            <div className="text-base font-bold">Nearest high voltage lines</div>
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  a: LinkRenderer,
                }}
            >
              {propertyReportInfo?.voltage_lines}
            </ReactMarkdown>
          </div> */}
                  <div>==================================================================================================</div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">Neighborhood</div>
                    <div className="text-base"><a
                        href={propertyReportInfo?.neighborhood?.economy_url}>Economy</a>
                    </div>
                    <div className="text-base"><a
                        href={propertyReportInfo?.neighborhood?.housing_url}>Housing</a>
                    </div>
                    <div className="text-base"><a
                        href={propertyReportInfo?.neighborhood?.people_url}>People</a>
                    </div>
                    <div className="text-base"><a
                        href={propertyReportInfo?.neighborhood?.quality_url}>Quality of Life</a>
                    </div>
                  </div>
                  <div>==================================================================================================</div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">Commute time</div>
                  </div>
                  <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      components={{
                        a: LinkRenderer,
                      }}
                  >
                    {propertyReportInfo?.commute_time}
                  </ReactMarkdown>
                  <div>==================================================================================================</div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">Amenities</div>
                  </div>
                  <ReactMarkdown remarkPlugins={[remarkGfm]} components={{
                    a: LinkRenderer,
                  }} children={propertyReportInfo?.amenities}/>
                  <div>==================================================================================================</div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">Utility provider</div>
                    {
                      propertyReportInfo?.utility_provider.map((utility_provider, index) => <div
                          key={utility_provider.category}
                          className={classnames("h-[80px] px-6 py-4 flex items-start justify-between", {
                            "bg-gray-50": index % 2 === 0,
                            "bg-white": index % 2 === 1,
                          })}>
                        <div className="flex flex-col gap-2 items-start">
                          <div className="text-base font-bold">
                            {utility_provider.category}
                          </div>
                          <div><a
                              href={utility_provider.website}>{utility_provider.vendor}</a> ({utility_provider.contact})
                          </div>
                        </div>
                      </div>)
                    }

                  </div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">###################(From 3rd data source)Need Manual
                      Check###################
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">Property highlight</div>
                    <Table
                        columns={property_highlight_columns}
                        data={property_highlight_data}
                    />
                  </div>
            <div className="flex flex-col gap-1 px-6 w-full">
              <div className="text-base font-bold">Monthly mortage payment</div>
              <div className="text-base"> 30 Years fixed rate mortgage with real time rate {propertyReportInfo?.cost_of_ownership.interest}% and {propertyReportInfo?.cost_of_ownership.down_percentage}% down payment:</div>
              <div className="text-base"> Monthly payment: ${propertyReportInfo?.cost_of_ownership.mortgage} </div>
            </div>
            <div className="flex flex-col gap-1 px-6 w-full">
            <div className="text-base font-bold">Discrepancies</div>
                    <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                          a: LinkRenderer,
                        }}
                    >
                      {propertyReportInfo?.discrepancies.description}
                    </ReactMarkdown>
                  </div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">Images</div>
                    {
                      propertyReportInfo?.images.map((image, index) => {
                        return <img
                            src={propertyReportInfo?.images[0]}
                            alt="new"
                        />
                      })
                    }
                  </div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">School infomation</div>
                    <div className="text-base"><a href={propertyReportInfo?.school.public_school_source}>Source Link</a>
                    </div>
                    {
                      propertyReportInfo?.school.public_school.map((public_school, index) => <div
                          key={public_school.address}
                          className={classnames("h-[80px] px-6 py-4 flex items-start justify-between", {
                            "bg-gray-50": index % 2 === 0,
                            "bg-white": index % 2 === 1,
                          })}>
                        <div className="flex flex-col gap-2 items-start">
                          <div className="text-base font-bold">
                            {public_school.name} ({public_school.score}/10)
                          </div>
                          <div>{public_school.address}. {public_school.district}</div>
                        </div>
                      </div>)
                    }
                  </div>
                  <div>==================================================================================================</div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">Market trend</div>
                    <div className="text-base"><a
                        href={propertyReportInfo?.market_trend?.market_trend_url}>Market Trend</a>
                    </div>
                  </div>
                  <div>==================================================================================================</div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">Permit history</div>
                    <Table
                        columns={permit_history_columns}
                        data={permit_history_data}
                    />
                  </div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">Listing history + Transaction history</div>
                    {
                      propertyReportInfo?.listing_and_transaction_history.map((history, index) => {
                        if (history?.type === 'listing') {
                          return <div key={history.listing?.mls_listing_id}
                                      className={classnames("h-[80px] px-6 py-4 flex items-start justify-between", {
                                        "bg-gray-50": index % 2 === 0,
                                        "bg-white": index % 2 === 1,
                                      })}>
                            <div className="flex flex-col gap-2 items-start">
                              <div className="text-base font-bold">
                                list on {history.listing?.on_market_date} at ${history.listing?.original_listing_price}
                              </div>
                              <div>close price: {history.listing?.close_price}</div>
                            </div>
                          </div>
                        } else {
                          return <div key={history.transaction?.sale_date}
                                      className={classnames("h-[80px] px-6 py-4 flex items-start justify-between", {
                                        "bg-gray-50": index % 2 === 0,
                                        "bg-white": index % 2 === 1,
                                      })}>
                            <div className="flex flex-col gap-2 items-start">
                              <div className="text-base font-bold">
                                sale on {history.transaction?.sale_date} at ${history.transaction?.sale_amount}
                              </div>
                              <div>buyer names: {history.transaction?.buyer_names}, title
                                company: {history.transaction?.title_company}</div>
                            </div>
                          </div>
                        }
                      })
                    }

                  </div>
                  <div className="flex flex-col gap-1 px-6 w-full">
                    <div className="text-base font-bold">Listing agent contact information</div>
                    <div className="text-base"> name: {propertyReportInfo?.listing_agent.name} </div>
                    <div className="text-base"> company: {propertyReportInfo?.listing_agent.company} </div>
                    <div className="text-base"> email: {propertyReportInfo?.listing_agent.email} </div>
                    <div className="text-base"> phone_number: {propertyReportInfo?.listing_agent.phone_number} </div>
                  </div>
                </div>
              </div>
              <div ref={scrollRef} className="w-1/3 h-full overflow-scroll">
                <div className="flex pt-[120px] items-center justify-between mb-5 w-full px-10">
                  {data && (
                      <div className="text-[20px] font-bold">{`Test Property Bot (${data?.address})`}</div>
                  )}
                </div>
                <div className="flex flex-col gap-6 w-full pt-0 pb-[150px] p-10">
                  {chatHistory.map((message: any) => {
                    if (message.role !== 'human' && message.role != 'ai') {
                      return null
                    }
                    if (message.role === 'ai') {
                      return (
                          <div className="flex items-start w-full gap-3">
                            <img src={agentAvatar}/>
                            <div className="px-3 py-2 rounded-2xl text-base max-w-[750px] bg-gray-50 conversation">
                              {message.content ? (
                                  <ReactMarkdown
                                      remarkPlugins={[remarkGfm]}
                                      components={{
                                        a: LinkRenderer,
                                      }}
                                  >
                                    {message.content}
                                  </ReactMarkdown>
                              ) : (
                                  <SyncLoader size={8}/>
                              )}
                            </div>
                          </div>
                      )
                    } else {
                      return (
                          <div className="flex items-start w-full gap-3 flex-row-reverse">
                            <img src={customerAvatar}/>
                            <div className="px-3 py-2 rounded-2xl text-base max-w-[750px] bg-gray-50 conversation">
                              {message.content ? (
                                  <ReactMarkdown
                                      components={{
                                        a: LinkRenderer,
                                      }}
                                  >
                                    {message.content}
                                  </ReactMarkdown>
                              ) : (
                                  <SyncLoader size={8}/>
                              )}
                            </div>
                          </div>
                      )
                    }
                  })}
                </div>
                <div
                    className="fixed flex items-center justify-center w-1/3 py-6 px-10 bottom-0 gap-4 border-t border-t-gray-200 bg-white">
                  <div className="grow">
                    <TextArea
                        rows={1}
                        height={24}
                        value={instruction}
                        isError={false}
                        onChange={(e) => {
                          setInstruction((e.target as HTMLInputElement).value)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            handleSubmit()
                          }
                        }}
                        fixHeight={false}
                        maxHeight={96}
                    />
                  </div>
                  <Button
                      type={ButtonType.PRIMARY}
                      content="Send"
                      onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
            )
            }

            export default Test
