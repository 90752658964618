import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import Input from './Input';
import { axiosWithAuth } from '../utils/axios';
import { useNavigate } from 'react-router';
import { useHomeAutoCompleteHouse } from '../query/useHomeAutoCompleteHouse';
import { AutoComplete, InputPicker } from 'rsuite';
import { BeatLoader } from 'react-spinners';
import {useTranslation} from "react-i18next";

function AutoCompleteComp() {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const { data, isFetching, isLoading } = useHomeAutoCompleteHouse(query);
  const { t, i18n } = useTranslation();

  const options = data?.map(
    (item: { address: string; property_id: number }) => ({
      label: item.address,
      value: item.property_id,
    }),
  );

  return (
    <InputPicker
      className="grow items-center justify-start home8-picker"
      style={{ display: 'inline-flex', border: "none", background: "#f3f4f6", borderRadius: 32, boxShadow: "none" }}
      data={options ?? []}
      value={query}
      placeholder={t('searchPropertyHint')}
      onSearch={(word: string) => setQuery(word)}
      menuClassName="home8-menu mt-1"
      renderMenuItem={(label, item)=> <div onClick={()=>navigate(`/property/${item.value}`)}>{label}</div>}
      renderMenu={(menu) => {
        if (isFetching || isLoading) {
          return (
            <div className="w-full min-h-[60px] flex items-center justify-center">
              <BeatLoader size={12} color="#D9D9D9" />
            </div>
          );
        }
        return menu;
      }}
      virtualized={false}
    />
  );
}

export default AutoCompleteComp;
