import React, { useState } from 'react';
import { useHomeProperties } from '../query/useHomeProperties';
import InputCode from './InputCode';
import SendCode from './SendCode';
import { ReactComponent as Account } from '../static/Account.svg';
import { ReactComponent as HumanService } from '../static/HumanService.svg';
import { ReactComponent as ChatHistory } from '../static/ChatHistory.svg';
import { ReactComponent as Offer } from '../static/Offer.svg';
import { ReactComponent as WatchList } from '../static/WatchList.svg';
import { ReactComponent as Properties } from '../static/Properties.svg';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSelector from "./LanguageSelector";

function Sidebar() {
  const isPropertiesTab =
    location.pathname.startsWith('/home/property') ||
    location.pathname === '/home/all_properties';
  const isOfferTab = location.pathname === '/home/offers';
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
      <div className="flex flex-col p-8 items-center gap-8 shrink-0">
          <div className="text-2xl font-extrabold text-white">Home8</div>
          <div
              className="flex flex-col items-center cursor-pointer"
              onClick={() => navigate('/home/all_properties')}
          >
              <Properties
                  className={classNames('w-8 h-8', {
                      'fill-white': isPropertiesTab,
                      'fill-[#B1BAC3]': !isPropertiesTab,
                  })}
              />
              <div
                  className={classNames('text-xs', {
                      'text-white': isPropertiesTab,
                      'text-[#B1BAC3]': !isPropertiesTab,
                      'font-bold': isPropertiesTab
                  })}
              >
                  {t('menuAllProperties')}
              </div>
          </div>
          {/*<div className="flex flex-col items-center">
        <WatchList className="w-8 h-8 fill-[#B1BAC3]" />
        <div className="text-xs font-bold text-[#B1BAC3]">Watch list</div>
      </div>*/}
          <div
              className="flex flex-col items-center cursor-pointer"
              onClick={() => navigate('/home/offers')}
          >
              <Offer
                  className={classNames('w-8 h-8', {
                      'fill-white': isOfferTab,
                      'fill-[#B1BAC3]': !isOfferTab,
                  })}
              />
              <div
                  className={classNames('text-xs', {
                      'text-white': isOfferTab,
                      'text-[#B1BAC3]': !isOfferTab,
                      'font-bold': isOfferTab
                  })}
              >
                  {t('menuMyOffer')}
              </div>
          </div>
          <div
              className="flex flex-col items-center cursor-pointer"
              onClick={() => {
              }}
          >
              <HumanService
                  className={classNames('w-8 h-8 fill-[#B1BAC3]')}
              />
              <div
                  className={classNames('text-xs text-[#B1BAC3]')}
              >
                  {t('menuTransaction')}
              </div>
          </div>
          <div
              className="flex flex-col items-center cursor-pointer"
              onClick={() => {
              }}
          >
              <HumanService
                  className={classNames('w-8 h-8 fill-[#B1BAC3]')}
              />
              <div
                  className={classNames('text-xs text-[#B1BAC3]')}
              >
                  {t('menuHouseTour')}
              </div>
          </div>
          <div
              className="flex flex-col items-center cursor-pointer"
              onClick={() => {
              }}
          >
              <HumanService
                  className={classNames('w-8 h-8 fill-[#B1BAC3]')}
              />
              <div
                  className={classNames('text-xs text-[#B1BAC3]')}
              >
                  {t('menuRenovationConsultation')}
              </div>
          </div>
          <div
              className="flex flex-col items-center cursor-pointer"
              onClick={() => {
              }}
          >
              <HumanService
                  className={classNames('w-8 h-8 fill-[#B1BAC3]')}
              />
              <div
                  className={classNames('text-xs text-[#B1BAC3]')}
              >
                  {t('menuMortgage')}
              </div>
          </div>
          <LanguageSelector/>
          {/*<div className="flex flex-col items-center">
        <ChatHistory className="w-8 h-8 fill-[#B1BAC3]" />
        <div className="text-xs font-bold text-[#B1BAC3]">Chat history</div>
      </div>
      <div className="flex flex-col items-center">
        <HumanService className="w-8 h-8 fill-[#B1BAC3]" />
        <div className="text-xs font-bold text-[#B1BAC3]">
          Real human service
        </div>
      </div>
      <div className="flex flex-col items-center">
        <Account className="w-8 h-8 fill-[#B1BAC3]" />
        <div className="text-xs font-bold text-[#B1BAC3]">Account</div>
      </div>*/}
      </div>
  );
}

export default Sidebar;
