import {useQuery} from '@tanstack/react-query'
import {axiosWithAuth} from '../utils/axios'

export const useHomeProperties = (offset?: number, limit?: number) => {
  const axios = axiosWithAuth()
  return useQuery({
    queryKey: ['home8_properties'],
    queryFn: async () => {
      try {
        const data = {
          limit: limit,
          offset: offset,
        }
        const result = await axios.post('properties', data)
        return result.data
      } catch (e: any) {
        return []
      }
    },
    refetchOnWindowFocus: false,
  })
}
