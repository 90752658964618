import { off } from 'process';
import GooglePlacesAutocomplete from 'react-google-autocomplete';
import { useHomeOffers } from '../query/useHomeOffers';
import { useHomeUser } from '../query/useHomeUser';
import Button, { ButtonType } from './Button';
import ChatbotBottom from './ChatbotBottom';
import OfferCard, { PropertyStatus, Status } from './OfferCard';
import Sidebar from './Sidebar';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { axiosWithAuth } from '../utils/axios';
import LogRocket from 'logrocket';
import { OfferType, Property } from '../store/agentInfo';
import Input from './Input';
import Modal from 'react-modal';
import { useRequestPropertyStore } from '../store/requestProperty';
import { useSearchPropertyStore } from './store/offerSearchProperty';
import { ReactComponent as Close } from '../static/Close.svg';
import { ReactComponent as OfferCreate } from '../static/OfferCreate.svg';
import { useHomeProperties } from '../query/useHomeProperties';
import ChatBottom from './ChatBottom';
import classnames from 'classnames';
import classNames from 'classnames';
import useChatBotStore from '../store/chatbot';
import {OFFER_AGENT_API_KEY} from "../utils/constant";
import {useHomePropertyDetail} from "../query/useHomePropertyDetail";
import {useHomeBuyingPower} from "../query/useHomeBuyingPower";

/*type EmailActivity = {
  event: string;
  time: number;
  email?: string;
};

type OfferType = {
  buyer1_ds_user_id: number;
  buyer2_ds_user_id: number;
  content: string;
  documents: any[];
  id: number;
  last_envelope_id: number;
  last_submitted_time: number;
  property_id: number;
  status: Status;
  user_id: number;
  last_offer_email_activities: EmailActivity[];
};*/

type Suggestion = {
  address: string;
  property: Property;
};

function Offer() {
  const { data, refetch } = useHomeOffers();
  const [propertySuggestionData, setPropertySuggestionData] = useState([]);
  const [offers, setOffers] = useState(data);
  const { data: user } = useHomeUser();
  const { data: buyingPower } = useHomeBuyingPower(user?.id)
  console.log('----------')
  console.log(buyingPower)
  //const propertyInfo = data as Property;
  const { t, i18n } = useTranslation();
  const [searchPropertyModalOpen, setSearchPropertyModalOpen] = useState(false);
  const [selectPropertyModalOpen, setSelectPropertyModalOpen] = useState(false);
  const [editPropertyModalOpen, setEditPropertyModalOpen] = useState(false);
  const [isPropertyLoading, setIsPropertyLoading] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [selectedEdit, setSelectedEdit] = useState('');
  const [selectedEditOfferId, setSelectedEditOfferId] = useState(-1);
  const [selectedEditPropertyId, setSelectedEditPropertyId] = useState('');
  const { dragging } = useChatBotStore();
  const {
    streetLineOne,
    streetLineTwo,
    city,
    state,
    zipCode,
    setStreetLineOne,
    setStreetLineTwo,
    setCity,
    setState,
    setZipCode,
    clear,
  } = useSearchPropertyStore();
  const [address, setAddress] = useState('');
  const removeCountryFromAddress = (place: google.maps.places.PlaceResult) => {
    const addressComponents = place.formatted_address
      ?.split(',')
      .map((part) => part.trim());
    console.log(addressComponents);
    const countryComponentShortName = place.address_components?.find(
      (component) => component.types.includes('country'),
    )?.short_name;
    console.log(countryComponentShortName);

    const clearedAddress = addressComponents
      ?.filter(
        (component) =>
          component !== countryComponentShortName && component !== 'USA',
      )
      .join(', ');

    // Filter out the country from the address
    return clearedAddress;
  };
  const handlePlaceSelected = (
    place: google.maps.places.PlaceResult | null,
  ) => {
    if (place && place.formatted_address) {
      const filteredAddress = removeCountryFromAddress(place);
      if (filteredAddress) {
        setAddress(filteredAddress);
      }
    }
  };

  const switchPropertyStatus = (status: string) => {
    if (status === 'Active') {
      return PropertyStatus.ONSALE;
    }

    if (status === 'Contingent') {
      return PropertyStatus.CONTINGENT;
    }

    if (status === 'Pending') {
      return PropertyStatus.PENDING;
    }

    return PropertyStatus.SOLD;
  };

  useEffect(() => {
    const identifyUser = async () => {
      const axios = axiosWithAuth();
      // Assuming you have user data in your state or props
      const { data } = await axios.get('user/me');
      const user = data?.result;
      LogRocket.identify(user.id, {
        email: user.email,
      });
    };

    identifyUser();
  }, []);

  useEffect(() => {
    // Set interval to poll every 3 seconds
    const interval = setInterval(() => {
      refetch(); // Trigger a re-fetch of data
    }, 3000);

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [refetch]); // Only run the effect once on mount, and whenever refetch changes

  // Optionally update state when data changes
  useEffect(() => {
    setOffers(data);
  }, [data]);

  const fetchSuggestionData = async () => {
    //setIsLoading(true);
    //setError(null);
    setIsPropertyLoading(true);
    try {
      // Simulate fetching data from an API
      const axios = axiosWithAuth();
      //const address = `${streetLineOne}`;
      //console.log(address)
      //const address = `${streetLineOne}`;
      const body = {
        address: address,
      };
      const { data } = await axios.post('mls_property_search', body);
      //const user = data?.result
      console.log(data?.result.suggestions);
      setPropertySuggestionData(data?.result.suggestions);
    } catch (error) {
      //setError(error.message);
    } finally {
      //setIsLoading(false);
      setIsPropertyLoading(false);
    }
  };

  const openEditPropertyModal = (offerId: number, propertyID: string) => {setEditPropertyModalOpen(true); setSelectedEditOfferId(offerId); setSelectedEditPropertyId(propertyID)}
  const closeEditPropertyModal = () => {setEditPropertyModalOpen(false);
    setSelectedEditPropertyId('');
    setSelectedEditOfferId(-1);
    setSelectedEdit('')};

  // Use effect to load data when modal opens
  //useEffect(() => {
  //  if (selectPropertyModalOpen) {
  //    fetchSuggestionData();
  //  }
  //}, [selectPropertyModalOpen]); // Only run when isOpen changes

  if (!data || !user) {
    return (
      <div className="flex bg-black min-h-full w-full">
        <Sidebar />
        <div className="m-3 rounded-[20px] bg-gray-50 grow flex flex-col"></div>
      </div>
    );
  }
  return (
    <div className="flex bg-black min-h-full w-full h-full">
      <Sidebar />
      <Modal
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        isOpen={searchPropertyModalOpen}
        onRequestClose={() => {
          setSearchPropertyModalOpen(false);
          setPropertySuggestionData([]);
          clear();
        }}
        style={{
          content: {
            width: 600,
            inset: 'unset',
            padding: 0,
            border: 'none',
            borderRadius: '16px',
            boxShadow:
              '0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)',
            overflow: 'hidden',
          },
          overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0, 0, 0, 0.55)',
            zIndex: 100,
          },
        }}
        htmlOpenClassName="overflow-hidden"
        shouldCloseOnEsc={true}
      >
        <div className="flex flex-col items-start justify-top h-full w-full p-5">
          <Close
            className="absolute top-5 right-5 text-gray-900 cursor-pointer"
            onClick={() => {
              setSearchPropertyModalOpen(false);
              setPropertySuggestionData([]);
              setSelectedProperty('');
              clear();
            }}
          />
          <div className="text-xl font-bold">
            {t('offerSearchPropertyTitle')}
          </div>
          <div className="text-sm">{t('offerSearchPropertyNoteLabel')}</div>
          <div className="text-sm mt-10 mb-3">
            {t('requestPropertyReportAddressLabel')}
          </div>
          {/*<Input
                  className="w-full mb-2"
                  placeholder="Street address, City, State, and Zipcode *"
                  value={streetLineOne}
                  isError={false}
                  onChange={(e) => {
                      setStreetLineOne((e.target as HTMLInputElement).value);
                  }}
              />*/}
          <div
            className={classnames(
              'bg-gray-100 rounded-[32px] h-[52px] px-4 py-3',
              {
                'ug-input-ctn--focused': true,
              },
              'w-full mb-2',
            )}
          >
            <GooglePlacesAutocomplete
              apiKey="AIzaSyCTnqm8H8nbkWCJXYBOlOuAY7C83GHCe8A" // Optional, if already in the HTML script tag
              onPlaceSelected={handlePlaceSelected}
              className="border-none outline-none h-full w-full bg-transparent text-sm"
              placeholder="Street address, City, State, and Zipcode *"
              options={{
                types: ['address'], // Limit results to addresses
                componentRestrictions: { country: 'us' }, // Restrict results to the US (optional)
              }}
              defaultValue={address}
            />
          </div>
          <div className="flex w-full justify-end mt-8">
            <Button
              type={ButtonType.DARK}
              loading={isPropertyLoading}
              disabled={address === ''}
              content={t('offerCheckAvailabilityLabel')}
              widthAuto={true}
              highetOverride={'h-[52px]'}
              onClick={async () => {
                //requestProperty({
                //  address: `${streetLineOne} ${streetLineTwo}, ${city}, ${state}, ${zipCode}`,
                //});
                await fetchSuggestionData();
                setSearchPropertyModalOpen(false);
                setSelectPropertyModalOpen(true);
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        isOpen={selectPropertyModalOpen}
        onRequestClose={() => {
          setSelectPropertyModalOpen(false);
          setPropertySuggestionData([]);
          setSelectedProperty('');
          clear();
        }}
        style={{
          content: {
            width: 600,
            //height: 600,
            inset: 'unset',
            padding: 0,
            border: 'none',
            borderRadius: '16px',
            boxShadow:
              '0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)',
            overflow: 'hidden',
            overflowY: 'auto',
          },
          overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0, 0, 0, 0.55)',
            zIndex: 100,
          },
        }}
        htmlOpenClassName="overflow-hidden"
        shouldCloseOnEsc={true}
      >
        <div className="flex flex-col items-start justify-start h-full w-full p-5">
          <Close
            className="absolute top-5 right-5 text-gray-900 cursor-pointer"
            onClick={() => {
              setSelectPropertyModalOpen(false);
              setPropertySuggestionData([]);
              setSelectedProperty('');
              clear();
            }}
          />
          <div className="text-xl font-bold">
            {t('offerSearchPropertyTitle')}
          </div>
          <div className="text-sm mb-10">
            {t('offerSearchPropertyNoteLabel')}
          </div>
          <div className={`flex flex-col justify-between items-center w-full`}>
            {propertySuggestionData &&
              propertySuggestionData.length > 0 &&
              propertySuggestionData.map((suggestion: Suggestion) => (
                <div
                  className={`bg-gray-100 pt-3 pb-3 pl-4 pr-4 mb-2 rounded-xl flex-grow ${
                    selectedProperty === suggestion.property.id
                      ? 'border-2 border-blue-500'
                      : ''
                  } cursor-pointer w-full`}
                  onClick={() => setSelectedProperty(suggestion.property.id)}
                >
                  <div className="flex justify-between items-start">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        <img
                          src="https://home8-gswe.onrender.com/apartment.png"
                          alt="Apartment"
                          className="w-6 h-6"
                        />
                      </div>
                      <div className="ml-2">
                        <h3 className="text-sm mb-2 font-normal text-gray-900">
                          {suggestion.address}
                        </h3>
                        <p className="text-xs font-normal text-gray-600">
                          {suggestion.property.bedrooms} {t('bedsLabel')} |{' '}
                          {suggestion.property.full_bathrooms +
                            suggestion.property.half_bathrooms * 0.5}{' '}
                          {t('bathsLabel')} | {suggestion.property.living_sqft}{' '}
                          {t('sqftLabel')}
                        </p>
                        <p className="text-xs font-normal text-gray-600">
                          {t('listingAgentLabel')}:{' '}
                          {suggestion.property.listing_agent_name} | DRE #
                          {suggestion.property.listing_agent_license_number}
                        </p>
                      </div>
                    </div>
                    <div className="text-sm font-bold text-gray-900">
                      {suggestion.property.listing_price.toLocaleString(
                        'en-US',
                        {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 0, // Removes cents
                          maximumFractionDigits: 0, // Removes cents
                        },
                      )}
                    </div>
                  </div>
                </div>
              ))}
            {propertySuggestionData && propertySuggestionData.length === 0 && (
              <div className="text-center mb-4">
                <p className="text-xl font-bold text-gray-900">
                  {t('offerSearchNoResultLabel')}
                </p>
                <p className="text-sm text-gray-900">
                  {t('offerSearchNoResultHintLabel')}{' '}
                  {`${streetLineOne} ${streetLineTwo}, ${city}, ${state}, ${zipCode}`}
                  .
                </p>
              </div>
            )}
          </div>
          <div className="flex mt-8 w-full justify-between">
            <Button
              type={ButtonType.LIGHT}
              content={t('offerSearchAnotherPropertyLabel')}
              onClick={async () => {
                setSearchPropertyModalOpen(true);
                setSelectPropertyModalOpen(false);
                //clear();
                setPropertySuggestionData([]);
                setSelectedProperty('');
              }}
            />
            {propertySuggestionData && propertySuggestionData.length === 1 && (
              <Button
                type={ButtonType.DARK}
                loading={false}
                disabled={false}
                content={t('offerSearchPropertyContinueLabel')}
                onClick={async () => {
                  const firstSuggestion: Suggestion = propertySuggestionData[0];
                  window.open(
                    `/home/offer_draft?property_id=${firstSuggestion.property.id}`,
                    '_blank',
                  );
                }}
              />
            )}
            {propertySuggestionData &&
              propertySuggestionData.length > 1 &&
              selectedProperty !== '' && (
                <Button
                  type={ButtonType.DARK}
                  loading={false}
                  disabled={false}
                  content={t('offerSearchPropertyContinueLabel')}
                  onClick={async () => {
                    window.open(
                      `/home/offer_draft?property_id=${selectedProperty}`,
                      '_blank',
                    );
                  }}
                />
              )}
          </div>
        </div>
      </Modal>
      <Modal
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
          isOpen={editPropertyModalOpen}
          onRequestClose={() => {
            closeEditPropertyModal()
          }}
          style={{
            content: {
              width: 600,
              inset: 'unset',
              padding: 0,
              border: 'none',
              borderRadius: '16px',
              boxShadow:
                  '0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)',
              overflow: 'hidden',
            },
            overlay: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(0, 0, 0, 0.55)',
              zIndex: 100,
            },
          }}
          htmlOpenClassName="overflow-hidden"
          shouldCloseOnEsc={true}
      >
        <div className="flex flex-col items-start justify-top h-full w-full p-5">
          <Close
              className="absolute top-5 right-5 text-gray-900 cursor-pointer"
              onClick={() => {
                closeEditPropertyModal()
              }}
          />
          <div className="text-xl mb-5 font-bold">
            {t('offerEditLabel')}
          </div>
          <div className="flex gap-4">
            {/* Minimum Changes */}
            <div className={`flex-1 bg-gray-100 p-4 rounded-lg ${
                selectedEdit === "minimum"
                    ? 'border-2 border-blue-500'
                    : ''
            }`} onClick={() => setSelectedEdit('minimum')}>
              <div className="font-bold mb-2 text-gray-900">{t('offerEditMinimumChangeLabel')}</div>
              <div className="font-normal text-gray-600">{t('offerEditMinimumChangeScopeLabel')}</div>
              <ul className="list-disc list-inside font-normal text-gray-600">
                <li className="font-normal text-base">{t('offerEditMinimumChangeOfferPriceLabel')}</li>
                <li className="font-normal text-base">{t('offerEditMinimumChangeOfferExpirationLabel')}</li>
              </ul>
              <div className="font-normal text-gray-600">
                {t('offerEditMinimumChangeExpertReviewNoteLabel')}
              </div>
            </div>

            {/* Significant Changes */}
            <div className={`flex-1 bg-gray-100 p-4 rounded-lg ${
                selectedEdit === "significant"
                    ? 'border-2 border-blue-500'
                    : ''
            }`} onClick={() => setSelectedEdit('significant')}>
              <div className="font-bold mb-2 text-gray-900">{t('offerEditSignificantChangeLabel')}</div>
              <div className="font-normal text-gray-600">
                {t('offerEditSignificantChangeReviewNoteLabel')}
              </div>
            </div>
          </div>

          <div className="flex w-full justify-end mt-5">
            <Button
                type={ButtonType.DARK}
                loading={isPropertyLoading}
                disabled={selectedEdit === ''}
                content={t('offerContinueEditLabel')}
                widthAuto={true}
                highetOverride={'h-[52px]'}
                onClick={async () => {
                  //requestProperty({
                  //  address: `${streetLineOne} ${streetLineTwo}, ${city}, ${state}, ${zipCode}`,
                  //});

                  window.open(
                      `/home/offer_draft?property_id=${selectedEditPropertyId}&offer_id=${selectedEditOfferId}&quick_price_check=${selectedEdit === "minimum"
                          ? 'true'
                          : 'false'}`,
                      '_blank',
                  );
                  closeEditPropertyModal();


                  //setEditPropertyModalOpen(false);
                  //setSelectedEdit('');
                }}
            />
          </div>
        </div>
      </Modal>
      <div className="flex flex-col min-h-screen w-full bg-black">
        <div className="mt-3 mr-3 rounded-[20px] bg-gray-50 grow flex flex-col relative overflow-hidden">
          <div className="flex items-center justify-end py-3 px-6 border-b border-solid border-gray-200">
            <div className="flex gap-2">
            {/*<Button
              type={ButtonType.DARK}
              loading={false}
              disabled={false}
              content={t('expertOfferLabel')}
              subContent={t('expertOfferNote')}
              onClick={async () => {
                window.open(
                    `https://calendly.com/home8-support/30min`,
                    '_blank',
                );
              }}
            />*/}
              <Button
                Icon={OfferCreate}
                type={ButtonType.LIGHT}
                loading={false}
                disabled={false}
                content={t('draftNewOfferLabel')}
                subContent={t('draftNewOfferNote')}
                onClick={async () => {
                  setSearchPropertyModalOpen(true);
                }}
              />
            </div>
          </div>
          <div
            className={classNames('flex flex-col p-5 w-full gap-3 h-full', {
              'overflow-auto': !dragging,
              'overflow-hidden': dragging,
            })}
          >
            {data.map((offer: OfferType) => {
              console.log(offer.last_offer_email_activities);
              if (offer.content !== '') {
                const content = JSON.parse(offer.content);
                const propertyStatus = switchPropertyStatus(
                  offer.property.status,
                );
                //console.log(offer.last_offer_email_activities)
                const address = `${content.address}, ${content.city}, ${content.state} ${content.zipcode}`;
                return (
                  <OfferCard
                    address={address}
                    dueDate={content.offerExpireTime}
                    status={offer.status}
                    price={content.offerPrice}
                    last_submitted_time={offer.last_submitted_time}
                    offerID={offer.id}
                    propertyID={offer.property_id}
                    userID={user?.id ?? ''}
                    offerExpiration={content.offerExpireTime}
                    lastOfferEmailActivities={offer.last_offer_email_activities}
                    propertyStatus={propertyStatus}
                    onStatusUpdate={() => {
                      setTimeout(refetch, 200);
                    }}
                    propertyContent={content}
                    openEditPropertyModal={openEditPropertyModal}
                    buyingPower={buyingPower}
                  />
                );
              }
            })}
          </div>
        </div>
        <ChatBottom disableAI={false} agentApiKey={OFFER_AGENT_API_KEY} />
      </div>
    </div>
  );
}

export default Offer;
