import { useHomeOffers } from '../query/useHomeOffers';
import Button, { ButtonType } from './Button';
import { ReactComponent as Done } from '../static/Done.svg';
import { ReactComponent as Pending } from '../static/Pending.svg';
import { ReactComponent as Declined } from '../static/Declined.svg';
import { ReactComponent as Expired } from '../static/Expired.svg';
import { ReactComponent as Grayed } from '../static/Grayed.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export enum StepStatus {
  DONE,
  PENDING,
  DECLINED,
  EXPIRED,
  GRAYED,
}

export type StepItemProps = {
  status: StepStatus;
  title: string;
  subTitles: string[];
  emailTrackStatus: EmailTrackerStatus;
};

export enum EmailTrackerStatus {
  NONE,
  NOT_OPEN,
  OPEN,
}

function StepperItem({
  status,
  title,
  subTitles,
  emailTrackStatus,
}: StepItemProps) {
  const icon = () => {
    switch (status) {
      case StepStatus.DONE:
        return <Done className="w-6 h-6" />;
      case StepStatus.PENDING:
        return <Pending className="w-6 h-6" />;
      case StepStatus.DECLINED:
        return <Declined className="w-6 h-6" />;
      case StepStatus.EXPIRED:
        return <Expired className="w-6 h-6" />;
      case StepStatus.GRAYED:
        return <Grayed className="w-6 h-6" />;
      default:
        return (
          <Button
            type={ButtonType.DARK}
            loading={false}
            disabled={false}
            content="Talk to offer expert"
            subContent="Available now"
            onClick={async () => {}}
          />
        );
    }
  };
  const { t, i18n } = useTranslation();

  return (
    <div className="flex items-start gap-2">
      {icon()}
      <div className="flex flex-col">
        <div
          className={classNames('font-bold', {
            'text-gray-200': status === StepStatus.GRAYED,
          })}
        >
          {title}
        </div>
        {subTitles &&
          subTitles?.map((title: string) => (
            <div
              className={classNames('font-base', {
                'text-gray-200': status === StepStatus.GRAYED,
              })}
            >
              {title}
            </div>
          ))}
        {emailTrackStatus === EmailTrackerStatus.NOT_OPEN && (
          <div className={classNames('font-base')}>
            {t('offerEmailTrackerLabel')}:{' '}
            <span style={{ color: '#FF7A00', fontWeight: '600' }}>
              {t('offerEmailTrackerNotOpenLabel')}
            </span>
          </div>
        )}
        {emailTrackStatus === EmailTrackerStatus.OPEN && (
          <div className={classNames('font-base')}>
            {t('offerEmailTrackerLabel')}:{' '}
            <span style={{ color: '#13986A', fontWeight: '600' }}>
              {t('offerEmailTrackerOpenLabel')}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default StepperItem;
