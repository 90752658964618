import { create } from "zustand";
import {FileStatus, Knowledge, Property, PropertyReport} from "./agentInfo";


type ReportInfoStore = {
    loading: boolean
    setLoading: (value: boolean) => void
    propertyId: number
    reasonForSelling: string
    setReasonForSelling: (value: string) => void
    driversOfDepreciation: string
    setDriversOfDepreciation: (value: string) => void
    driversOfAppreciation: string
    setDriversOfAppreciation: (value: string) => void
    compsHighestMlsListingId: string
    setCompsHighestMlsListingId: (value: string) => void
    compsMlsListingId1: string
    setCompsMlsListingId1: (value: string) => void
    compsMlsListingId2: string
    setCompsMlsListingId2: (value: string) => void
    compsMlsListingId3: string
    setCompsMlsListingId3: (value: string) => void
    compsMlsListingId4: string
    setCompsMlsListingId4: (value: string) => void
    compsMlsListingId5: string
    setCompsMlsListingId5: (value: string) => void
    compsMlsListingId6: string
    setCompsMlsListingId6: (value: string) => void
    estimatePrice: number
    setEstimatePrice: (value: number) => void
    offerInsights: string
    setOfferInsights: (value: string) => void
    initForUpdate: (info: PropertyReport) => void
    successModalOpen: boolean
    setSuccessModalOpen: (value: boolean) => void
    errorModalOpen: boolean
    setErrorModalOpen: (value: boolean) => void
}

const useReportInfoStore = create<ReportInfoStore>((set) => ({
    loading: false,
    setLoading: (value: boolean) => set({ loading: value }),
    propertyId: -1,
    reasonForSelling: '',
    setReasonForSelling: (value: string) => set({ reasonForSelling: value }),
    driversOfDepreciation: '',
    setDriversOfDepreciation: (value: string) => set({ driversOfDepreciation: value }),
    driversOfAppreciation: '',
    setDriversOfAppreciation: (value: string) => set({ driversOfAppreciation: value }),
    compsHighestMlsListingId: '',
    setCompsHighestMlsListingId: (value: string) => set({ compsHighestMlsListingId: value }),
    compsMlsListingId1: '',
    setCompsMlsListingId1: (value: string) => set({ compsMlsListingId1: value }),
    compsMlsListingId2: '',
    setCompsMlsListingId2: (value: string) => set({ compsMlsListingId2: value }),
    compsMlsListingId3: '',
    setCompsMlsListingId3: (value: string) => set({ compsMlsListingId3: value }),
    compsMlsListingId4: '',
    setCompsMlsListingId4: (value: string) => set({ compsMlsListingId4: value }),
    compsMlsListingId5: '',
    setCompsMlsListingId5: (value: string) => set({ compsMlsListingId5: value }),
    compsMlsListingId6: '',
    setCompsMlsListingId6: (value: string) => set({ compsMlsListingId6: value }),
    estimatePrice: 0,
    setEstimatePrice: (value: number) => set({ estimatePrice: value }),
    offerInsights: '',
    setOfferInsights: (value: string) => set({ offerInsights: value }),
    initForUpdate: (info: PropertyReport) =>
        set({
            propertyId: info.property_id,
            reasonForSelling: info.background_stories.reason_for_selling,
            driversOfDepreciation: info.potential_drivers_of_depreciation,
            driversOfAppreciation: info.potential_drivers_of_appreciation,
            compsHighestMlsListingId: info.comparable_sales?.comps_highest?.mls_listing_id,
            compsMlsListingId1: info.comparable_sales?.comps1?.mls_listing_id,
            compsMlsListingId2: info.comparable_sales?.comps2?.mls_listing_id,
            compsMlsListingId3: info.comparable_sales?.comps3?.mls_listing_id,
            compsMlsListingId4: info.comparable_sales?.comps4?.mls_listing_id,
            compsMlsListingId5: info.comparable_sales?.comps5?.mls_listing_id,
            compsMlsListingId6: info.comparable_sales?.comps6?.mls_listing_id,
            estimatePrice: info.estimate_price.price,
            offerInsights: info.offer_related_insights
        }),
    successModalOpen: false,
    setSuccessModalOpen: (value: boolean) => set({ successModalOpen: value }),
    errorModalOpen: false,
    setErrorModalOpen: (value: boolean) => set({ errorModalOpen: value }),
}))

export default useReportInfoStore
