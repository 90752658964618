import { create } from 'zustand'

type AuthStore = {
  loading: boolean
  setLoading: (value: boolean) => void
  email: string
  setEmail: (value: string) => void
  code: string
  setCode: (value: string) => void
}

const useAuthStore = create<AuthStore>((set) => ({
  loading: false,
  setLoading: (value: boolean) => set({ loading: value }),
  email: "",
  setEmail: (value: string) => set({ email: value }),
  code: "",
  setCode: (value: string) => set({ code: value }),
}))

export default useAuthStore
