import React, { useEffect, useRef, useState } from 'react';
import Button, { ButtonType } from './Button';
import { ReactComponent as AIAgent } from '../static/Agent.svg';
import { ReactComponent as ChatExpert } from '../static/ChatExpert.svg';
import { ReactComponent as CloseFullscreen } from '../static/CloseFullscreen.svg';
import { ReactComponent as OpenTab } from '../static/OpenTab.svg';
import { ReactComponent as Video } from '../static/Video.svg';
import { ReactComponent as SendAI } from '../static/sendAI.svg';
import { ReactComponent as DragButton } from '../static/DragButton.svg';
import { useTranslation } from 'react-i18next';
import { OfferType } from '../store/agentInfo';
import OfferCard from './OfferCard';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { BeatLoader } from 'react-spinners';
import Input from './Input';
import Cookies from 'js-cookie';
import { clear } from '@testing-library/user-event/dist/clear';
import { axiosWithAuth } from '../utils/axios';
import { useHomeOffers } from '../query/useHomeOffers';
import { useHomeExpertConversation } from '../query/useHomeExpertConversation';
import classNames from 'classnames';
import useChatBotStore from '../store/chatbot';

type ChatSentence = {
  role: 'human' | 'ai';
  content: string;
};

type IntercomAuthor = {
  id: string;
  type: string;
  name: string;
  email: string;
};

type IntercomAttachment = {
  type: string;
  name: string;
  url: string;
  content_type: string;
  filesize: string;
  width?: number;
  height?: number;
};

type IntercomSource = {
  type: string;
  id: string;
  delivered_as: string;
  subject: string;
  body: string;
  author: IntercomAuthor;
  attachments: IntercomAttachment[];
  url?: string;
  redacted: boolean;
};

type WrappedIntercomSource = {
  source: IntercomSource;
  is_user: boolean;
};

type IntercomReference = {
  type: string;
  id?: string;
};

type IntercomConversationPart = {
  type: string;
  id: string;
  part_type: string;
  body: string;
  created_at: number;
  updated_at: number;
  notified_at: number;
  assigned_to?: IntercomReference;
  author: IntercomAuthor;
  attachments: IntercomAttachment[];
  external_id?: string;
  redacted: boolean;
};

type WrappedIntercomConversationPart = {
  conversation_part: IntercomConversationPart;
  is_user: boolean;
};

type IntercomConversation = {
  waiting_since?: number;
  snoozed_until?: number;
  open: boolean;
  state: string;
  read: boolean;
  wrapped_source: WrappedIntercomSource;
  wrapped_conversation_parts: WrappedIntercomConversationPart[];
};

type Props = {
  disableAI: boolean;
  sampleQuestions?: string[];
  propertyId?: string;
  agentApiKey?: string;
};

const minWindowHeight = 200;

const ChatBottom = ({ disableAI, sampleQuestions, propertyId, agentApiKey }: Props) => {
  const { data, refetch } = useHomeExpertConversation();
  const [aiChat, setAIChat] = useState(false);
  const [expertChat, setExpertChat] = useState(false);
  const [aiChatHistory, setAIChatHistory] = useState<ChatSentence[]>([]);
  const [expertConversation, setExpertConversation] =
    useState<IntercomConversation | null>(data);
  const [aiConversationId, setAIConversationId] = useState(crypto.randomUUID());
  const { t, i18n } = useTranslation();
  const [aiInstruction, setAIInstruction] = useState('');
  const [expertQuestion, setExpertQuestion] = useState('');
  const aiScrollRef = useRef<HTMLDivElement>(null);
  const expertScrollRef = useRef<HTMLDivElement>(null);
  const [windowHeight, setWindowHeight] = useState(340);
  const axios = axiosWithAuth();
  let startY: number, startHeight: number;
  const minHeight = minWindowHeight;
  const maxHeight = window.outerHeight - minWindowHeight - 100;
  const { setDragging } = useChatBotStore();

  const addToAIHistory = (content: ChatSentence[]) =>
    setAIChatHistory((history) => {
      return [...history, ...content];
    });

  function timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const LinkRenderer = ({ node, ...props }: any) => (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );

  useEffect(() => {
    // Set interval to poll every 3 seconds
    const interval = setInterval(() => {
      refetch(); // Trigger a re-fetch of data
    }, 20000);

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [refetch]); // Only run the effect once on mount, and whenever refetch changes

  // Optionally update state when data changes
  useEffect(() => {
    setExpertConversation(data);
    if (expertScrollRef.current) {
      expertScrollRef.current.scrollTop = expertScrollRef.current.scrollHeight;
    }
  }, [data]);

  useEffect(() => {
    if (expertChat && expertScrollRef.current) {
      expertScrollRef.current.scrollTop = expertScrollRef.current.scrollHeight;
    }
  }, [expertChat]);

  useEffect(() => {
    if (aiChat && aiScrollRef.current) {
      aiScrollRef.current.scrollTop = aiScrollRef.current.scrollHeight;
    }
  }, [aiChat]);

  useEffect(() => {
    if (expertChat) {
      window.scrollTo({ top: document.documentElement.scrollHeight });
    }
  }, [expertChat]);

  useEffect(() => {
    if (aiChat) {
      window.scrollTo({ top: document.documentElement.scrollHeight });
    }
  }, [aiChat]);

  const formatFileSize = (size: string) => {
    const sizeInBytes = parseInt(size, 10);  // Convert string to integer
    return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
  };

  const handleAISubmit = async (question?: string) => {
    const text = question ? question : aiInstruction.toString();
    setAIInstruction('');
    addToAIHistory([
      {
        role: 'human',
        content: text,
      },
      { role: 'ai', content: '' },
    ]);
    if (aiScrollRef.current) {
      aiScrollRef.current.style.overflow = 'hidden';
    }

    let header = {}
    let body
    let url = ''
    if (propertyId) {
      url = 'https://home8-backend.onrender.com/property_chat';
      const token = Cookies.get('auth');
      body = JSON.stringify({
        property_id: propertyId,
        user_input: text,
        chat_history: aiChatHistory.map((sentence) => {
          return {
            role: sentence.role,
            content: sentence.content,
          };
        }),
        conversation_id: aiConversationId,
      });
      header = {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      };
    } else if (agentApiKey) {
      url = 'https://agent-management.onrender.com/agent_chat_api_key';
      body = JSON.stringify({
        api_key: agentApiKey,
        user_input: text,
        chat_history: aiChatHistory.map((sentence) => {
          return {
            role: sentence.role,
            content: sentence.content,
          };
        }),
        conversation_id: aiConversationId,
      });
      header = {
        'Content-Type': 'application/json',
      };
    }

    //const token = Cookies.get('auth');
    const response = await fetch(
      url,
      {
        method: 'POST',
        body: body,
        headers: header,
      },
    );

    if (aiScrollRef.current) {
      aiScrollRef.current.scrollTop = aiScrollRef.current.scrollHeight;
    }
    const reader = response.body
      ?.pipeThrough(new TextDecoderStream())
      .getReader();
    if (reader) {
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          setAIChatHistory((history) => {
            const newHistory = [...history];
            return newHistory;
          });
          if (aiScrollRef.current) {
            aiScrollRef.current.scrollTop = aiScrollRef.current.scrollHeight;
          }
          break;
        }
        setAIChatHistory((history) => {
          const newHistory = [...history];
          if (process.env.NODE_ENV === 'development') {
            if (!newHistory[newHistory.length - 1].content.endsWith(value)) {
              newHistory[newHistory.length - 1].content += value;
            }
          } else {
            newHistory[newHistory.length - 1].content += value;
          }
          return newHistory;
        });
        await timeout(100);
        if (aiScrollRef.current) {
          aiScrollRef.current.scrollTop = aiScrollRef.current.scrollHeight;
        }
      }
    }
    if (aiScrollRef.current) {
      aiScrollRef.current.style.overflow = 'auto';
    }
  };

  const handleExpertSubmit = async (question?: string) => {
    const text = question ? question : expertQuestion.toString();
    setExpertQuestion('');
    /*addToAIHistory([
            {
                role: 'human',
                content: text,
            },
            { role: 'ai', content: '' },
        ]);*/
    if (expertScrollRef.current) {
      expertScrollRef.current.style.overflow = 'hidden';
    }

    const body = {
      user_input: text,
    };
    const { data } = await axios.post('ask_expert', body);

    console.log(data?.result);
    //const intercomConversation = data?.result as IntercomConversation
    setExpertConversation(data?.result as IntercomConversation);
    if (expertScrollRef.current) {
      expertScrollRef.current.scrollTop = expertScrollRef.current.scrollHeight;
    }

    if (expertScrollRef.current) {
      expertScrollRef.current.style.overflow = 'auto';
    }
  };

  const markExpertConversationAsRead = async () => {
    if (expertScrollRef.current) {
      expertScrollRef.current.style.overflow = 'hidden';
    }

    const { data } = await axios.get('mark_expert_conversation_read');
    console.log(data?.result);
    //const intercomConversation = data?.result as IntercomConversation
    setExpertConversation(data?.result as IntercomConversation);
    if (expertScrollRef.current) {
      expertScrollRef.current.scrollTop = expertScrollRef.current.scrollHeight;
    }

    if (expertScrollRef.current) {
      expertScrollRef.current.style.overflow = 'auto';
    }
  };

  function onMouseMove(e: any) {
    // Calculate new height of top window
    const heightRes = startHeight + (startY ?? 0) - e.clientY;
    if (heightRes < maxHeight && heightRes > minHeight) {
      setWindowHeight(heightRes);
    }
  }

  function onMouseUp() {
    // Remove the event listeners when mouse is released
    setDragging(false)
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  }

  return (
    <div className="flex flex-col w-full bg-black text-white">
      {(aiChat || expertChat) && (
        <div className="h-3 flex justify-center items-center">
          <div
            className="cursor-pointer"
            onMouseDown={(e) => {
              startY = e.clientY;
              startHeight = windowHeight;
              document.addEventListener('mousemove', onMouseMove);
              document.addEventListener('mouseup', onMouseUp);
              setDragging(true)
            }}
          >
            <DragButton />
          </div>
        </div>
      )}
      {aiChat && (
        <div
          style={{ height: windowHeight }}
          className={classNames(
            'mr-3 rounded-[20px] rounded-br-none bg-gray-50 grow flex flex-col relative overflow-hidden',
          )}
        >
          <div
            className={classNames(
              'rounded-[20px] rounded-br-none w-full h-full flex flex-col items-center overflow-hidden',
            )}
          >
            <div
              className="flex w-full items-center justify-between p-5 border-b border-solid"
              style={{ borderColor: '#D1D6DC' }}
            >
              <div className="flex items-center gap-1">
                <AIAgent className="fill-gray-900 w-6 h-6" />
                <div className="text-gray-900 font-semibold text-sm">
                  {t('botAskAILabel')}
                </div>
                <div className="text-gray-900 font-light text-xs">
                  {t('botAskAINoteLabel')}
                </div>
              </div>
              <div className="flex items-center gap-5">
                <OpenTab
                  className="fill-gray-900 w-6 h-6 cursor-pointer"
                  onClick={() => {}}
                />
                <CloseFullscreen
                  className="fill-gray-900 w-6 h-6 cursor-pointer"
                  onClick={() => {
                    setAIChat(false);
                  }}
                />
              </div>
            </div>
            <div
              ref={aiScrollRef}
              style={{
                flexGrow: 1,
                overflow: 'auto',
                width: '100%',
                padding: 20,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 12,
                  width: '100%',
                }}
              >
                {aiChatHistory.map((message: any) => {
                  if (message.role !== 'human' && message.role != 'ai') {
                    return null;
                  }
                  if (message.role === 'ai') {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          width: '100%',
                          gap: 12,
                          minWidth: 0,
                        }}
                      >
                        <div
                          style={{
                            borderRadius: '4px 20px 20px 20px',
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 8,
                            paddingBottom: 8,
                            fontWeight: 400,
                            maxWidth: '90%',
                            backgroundColor: '#D1D6DC',
                            minWidth: 0,
                            color: '#161A1D',
                          }}
                          className="conversation"
                        >
                          {message.content ? (
                            <ReactMarkdown
                              remarkPlugins={[remarkGfm]}
                              components={{
                                a: LinkRenderer,
                              }}
                            >
                              {message.content}
                            </ReactMarkdown>
                          ) : (
                            <BeatLoader size={6} color="#D9D9D9" />
                          )}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          width: '100%',
                          gap: 12,
                          flexDirection: 'row-reverse',
                          minWidth: 0,
                        }}
                      >
                        <div
                          style={{
                            borderRadius: '20px 4px 20px 20px',
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 8,
                            paddingBottom: 8,
                            color: 'white',
                            fontWeight: 400,
                            maxWidth: 750,
                            backgroundColor: '#454F5A',
                            minWidth: 0,
                          }}
                          className="conversation"
                        >
                          {message.content ? (
                            <ReactMarkdown
                              components={{
                                a: LinkRenderer,
                              }}
                            >
                              {message.content}
                            </ReactMarkdown>
                          ) : (
                            <BeatLoader size={6} />
                          )}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            <div className="p-5 w-full">
              <Input
                className="w-full text-black h-[40px] text-xs bg-white"
                placeholder={t('chatbotQuestionStarter')}
                value={aiInstruction}
                isError={false}
                onChange={(e) => {
                  setAIInstruction((e.target as HTMLInputElement).value);
                }}
                onSubmit={() => {
                  handleAISubmit();
                }}
              />
            </div>
          </div>
        </div>
      )}
      {expertChat && (
        <div
          style={{ height: windowHeight }}
          className={classNames(
            'mr-3 rounded-[20px] bg-gray-50 grow flex flex-col relative overflow-hidden',
            {
              'rounded-br-none': disableAI,
            },
          )}
        >
          <div
            className={classNames(
              'rounded-[20px] w-full h-full flex flex-col items-center overflow-hidden',
              {
                'rounded-br-none': disableAI,
              },
            )}
          >
            <div
              className="flex w-full items-center justify-between p-5 border-b border-solid"
              style={{ borderColor: '#D1D6DC' }}
            >
              <div className="flex items-center gap-1">
                <ChatExpert className="fill-gray-900 w-6 h-6" />
                <div className="text-gray-900 font-semibold text-sm">
                  {t('botAskExpertLabel')}
                </div>
                <div className="text-gray-900 font-light text-xs">
                  {t('expertAvailableLabel')}
                </div>
              </div>
              <div className="flex items-center gap-5">
                <OpenTab
                  className="fill-gray-900 w-6 h-6 cursor-pointer"
                  onClick={() => {}}
                />
                <CloseFullscreen
                  className="fill-gray-900 w-6 h-6 cursor-pointer"
                  onClick={() => {
                    setExpertChat(false);
                  }}
                />
              </div>
            </div>
            <div
              ref={expertScrollRef}
              style={{
                flexGrow: 1,
                overflow: 'auto',
                width: '100%',
                padding: 20,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 12,
                  width: '100%',
                }}
              >
                {expertConversation &&
                  expertConversation.wrapped_source &&
                  !expertConversation.wrapped_source.is_user && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        width: '100%',
                        gap: 12,
                        minWidth: 0,
                      }}
                    >
                      <div
                        style={{
                          borderRadius: '4px 20px 20px 20px',
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 8,
                          paddingBottom: 8,
                          fontWeight: 400,
                          maxWidth: '90%',
                          backgroundColor: '#D1D6DC',
                          minWidth: 0,
                          color: '#161A1D',
                        }}
                        className="conversation"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              expertConversation.wrapped_source.source.body,
                          }}
                        />
                      </div>
                    </div>
                  )}
                {expertConversation &&
                  expertConversation.wrapped_source &&
                  expertConversation.wrapped_source.is_user && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        width: '100%',
                        gap: 12,
                        flexDirection: 'row-reverse',
                        minWidth: 0,
                      }}
                    >
                      <div
                        style={{
                          borderRadius: '20px 4px 20px 20px',
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 8,
                          paddingBottom: 8,
                          color: 'white',
                          fontWeight: 400,
                          maxWidth: 750,
                          backgroundColor: '#454F5A',
                          minWidth: 0,
                        }}
                        className="conversation"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              expertConversation.wrapped_source.source.body,
                          }}
                        />
                      </div>
                    </div>
                  )}
                {expertConversation &&
                  expertConversation.wrapped_conversation_parts.length > 0 &&
                  expertConversation.wrapped_conversation_parts.map(
                    (
                      wrapped_conversation_part: WrappedIntercomConversationPart,
                    ) => {
                      if (!wrapped_conversation_part.is_user) {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              width: '100%',
                              gap: 12,
                              minWidth: 0,
                            }}
                          >
                            <div
                              style={{
                                borderRadius: '4px 20px 20px 20px',
                                padding: 12,
                                fontWeight: 400,
                                maxWidth: '90%',
                                backgroundColor: '#D1D6DC',
                                minWidth: 0,
                                color: '#161A1D',
                              }}
                              className="conversation"
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    wrapped_conversation_part.conversation_part
                                      .body,
                                }}
                              />
                              {
                                wrapped_conversation_part.conversation_part.attachments.map((attachment) =>
                                        <div
                                            className="flex items-center justify-between bg-white border rounded-[4px] pl-2 pt-[6px] pr-2 pb-[6px] mt-2 w-full max-w-md">
                                          {/* Attachment icon and file name */}
                                          <div className="flex items-center space-x-2 overflow-hidden">
                                            {/* Paperclip icon */}
                                            {/*<AiOutlinePaperClip className="text-white" size={18}/>*/}
                                            <img src="https://home8-gswe.onrender.com/attachment-icon.png" alt="Home"
                                                 style={{width: '24px', /* Adjust the size of the icon */
                                                   height: '24px'}}/>

                                            {/* File name and link */}
                                            <a
                                                href={attachment.url}
                                                className="text-blue-500 text-sm font-weight-400 truncate no-underline hover:underline"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                              {attachment.name}
                                            </a>
                                          </div>

                                          {/* File size */}
                                          <span className="text-gray-600 text-sm ml-3 whitespace-nowrap">
                                            {formatFileSize(attachment.filesize)}
                                          </span>
                                        </div>
                                )
                              }
                            </div>
                          </div>
                        );
                      } else {
                        return (
                            <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  width: '100%',
                                  gap: 12,
                                  flexDirection: 'row-reverse',
                                  minWidth: 0,
                                }}
                            >
                              <div
                                  style={{
                                borderRadius: '20px 4px 20px 20px',
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 8,
                                paddingBottom: 8,
                                color: 'white',
                                fontWeight: 400,
                                maxWidth: 750,
                                backgroundColor: '#454F5A',
                                minWidth: 0,
                              }}
                              className="conversation"
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    wrapped_conversation_part.conversation_part
                                      .body,
                                }}
                              />
                            </div>
                          </div>
                        );
                      }
                    },
                  )}
              </div>
            </div>
            <div className="p-5 w-full">
              <Input
                className="w-full text-black h-[40px] text-xs bg-white"
                placeholder={t('chatbotQuestionStarter')}
                value={expertQuestion}
                isError={false}
                onChange={(e) => {
                  setExpertQuestion((e.target as HTMLInputElement).value);
                }}
                onSubmit={() => {
                  handleExpertSubmit();
                }}
              />
            </div>
          </div>
        </div>
      )}
      {!aiChat && !expertChat && (
        <div className="fixed bottom-20 right-3 flex flex-col space-y-2">
          {sampleQuestions?.map((question, index) => (
            <button
              key={index}
              className="inline-flex items-center bg-gray-600 text-sm text-white px-4 py-3 rounded-[30px] hover:bg-gray-700 focus:outline-none w-auto self-end"
              onClick={async () => {
                setAIChat(true);
                handleAISubmit(question);
              }}
            >
              <span className="mr-2">{question}</span>
              <SendAI className="fill-white" />
            </button>
          ))}
        </div>
      )}
      <div className="flex justify-end h-[76px] w-full bg-black text-white px-3">
        {/* Chat Options Section */}
        <div className="flex items-center">
          <div
            className={classNames({
              'bg-gray-50': expertChat,
            })}
          >
            <div
              className={classNames({
                'py-3 pr-3 rounded-tr-[32px] bg-black': expertChat,
              })}
            >
              <Button
                Icon={Video}
                type={ButtonType.GREY}
                loading={false}
                disabled={false}
                content={t('botVideoLabel')}
                subContent={t('botVideoNoteLabel')}
                widthMin="min-w-[303px]"
                onClick={async () => {
                  window.open(
                    `https://calendly.com/home8-support/30min`,
                    '_blank',
                  );
                }}
              />
            </div>
          </div>
          <div
            className={classNames({
              'bg-black h-full': expertChat,
              'bg-gray-50': aiChat,
            })}
          >
            <div
              className={classNames({
                'bg-gray-50 pt-3 rounded-b-[32px]': expertChat,
                'p-3 rounded-tr-[32px] bg-black': aiChat,
                'p-3': !expertChat && !aiChat && !disableAI,
                'py-3 pl-3': !expertChat && !aiChat && disableAI,
              })}
            >
              <Button
                Icon={ChatExpert}
                type={ButtonType.GREY}
                loading={false}
                disabled={false}
                content={t('botAskExpertLabel')}
                subContent={t('expertAvailableLabel')}
                widthMin="min-w-[197px]"
                unreadMessage={Boolean(expertConversation && !expertConversation.read)}
                onClick={async () => {
                  console.log('hahahahaha');
                  setAIChat(false);
                  setExpertChat(true);
                  if (!expertConversation?.read) {
                    await markExpertConversationAsRead();
                  }
                }}
              />
            </div>
          </div>
          {!disableAI && (
            <div
              className={classNames({
                'bg-gray-50': expertChat,
                'bg-black h-full': aiChat,
              })}
            >
              <div
                className={classNames({
                  'py-3 pl-3 rounded-tl-[32px] bg-black': expertChat,
                  'bg-gray-50 pt-3 rounded-b-[32px]': aiChat,
                })}
              >
                <Button
                  Icon={AIAgent}
                  type={ButtonType.GREY}
                  loading={false}
                  disabled={false}
                  content={t('botAskAILabel')}
                  subContent={t('botAskAINoteLabel')}
                  widthMin="min-w-[220px]"
                  onClick={async () => {
                    setAIChat(true);
                    setExpertChat(false);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatBottom;
