import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'
import { MoonLoader } from 'react-spinners'

type Props = {
  content: string
  onBeforeUpload: (files: FileList) => void
  loading: boolean
}

function Upload(props: Props) {
  const { content, onBeforeUpload, loading } = props

  return (
    <div className="relative">
      <label
        htmlFor={loading ? '' : 'file-upload'}
        className={classNames(
          'h-10 w-[506px] rounded-lg px-4 py-2 flex items-center justify-center font-semibold',
          {
            'cursor-not-allowed bg-gray-300 border-none text-gray-500': loading,
            'cursor-pointer hover:bg-blue-50 bg-white border-blue-500 border-solid border text-blue-500':
              !loading,
          },
        )}
      >
        <div className="flex items-center gap-2">
          {loading && <MoonLoader size={16} />}
          {content}
        </div>
      </label>
      <input
        type="file"
        name="file"
        multiple
        id="file-upload"
        className="absolute z-[-1] opacity-0"
        onChange={(e) => {
          const files = e.target.files!
          onBeforeUpload(files)
            console.log(files)
          e.target.value = ''
        }}
      />
    </div>
  )
}

export default Upload
