import React, { useState } from 'react';
import InputCode from './InputCode';
import SendCode from './SendCode';
//import LogRocket from 'logrocket';
//LogRocket.init('9ngrsj/home8');

function HomeLogin() {
  const [step, setStep] = useState(0);
  return (
    <div
      className="w-full flex justify-between items-center bg-cover bg-no-repeat bg-center min-h-full px-[100px] py-[50px]"
      style={{
        backgroundImage: 'url(https://servicegenie.ai/home8-background.jpeg)',
      }}
    >
      <div className="flex flex-col w-[600px] items-center z-10">
        <div className="text-[36px] font-bold text-white">
          We are not sales,
        </div>
        <div className="text-[36px] font-bold text-white">
          we are home-buying advisors
        </div>
        <div className="h-[36px]"></div>
        <div className="text-[24px] font-normal text-white">
          Zero commission
        </div>
        <div className="text-[24px] font-normal text-white">
          Unbiased insights
        </div>
        <div className="text-[24px] font-normal text-white">
          Full buying consultation service
        </div>
      </div>
      <div className="min-w-[480px] w-[480px] shrink-0 h-[540px] bg-white rounded-xl border border-gray-200 border-solid z-10 flex flex-col items-start p-10 gap-5">
        {
          step === 0 ? <SendCode onCodeSend={()=>{
            setStep(1)
          }}/> : <InputCode onTryOthers={()=> {
            setStep(0)
          }}/>
        }
      </div>
      <div className="absolute bg-[#00000080] w-full h-full left-0 top-0"></div>
    </div>
  );
}

export default HomeLogin;
