import { create } from 'zustand';

type RequestPropertyStore = {
  streetLineOne: string;
  setStreetLineOne: (value: string) => void;
  streetLineTwo: string;
  setStreetLineTwo: (value: string) => void;
  city: string;
  setCity: (value: string) => void;
  state: string;
  setState: (value: string) => void;
  zipCode: string;
  setZipCode: (value: string) => void;
  email: string;
  setEmail: (value: string) => void;
  phone: string;
  setPhone: (value: string) => void;
  clear: () => void;
};

export const useRequestPropertyStore = create<RequestPropertyStore>((set) => {
  return {
    streetLineOne: '',
    setStreetLineOne: (value: string) => set({ streetLineOne: value }),
    streetLineTwo: '',
    setStreetLineTwo: (value: string) => set({ streetLineTwo: value }),
    city: '',
    setCity: (value: string) => set({ city: value }),
    state: 'California',
    setState: (value: string) => set({ state: value }),
    zipCode: '',
    setZipCode: (value: string) => set({ zipCode: value }),
    email: '',
    setEmail: (value: string) => set({ email: value }),
    phone: '',
    setPhone: (value: string) => set({ phone: value }),
    clear: () =>
      set({
        streetLineOne: '',
        streetLineTwo: '',
        city: '',
        state: 'California',
        zipCode: '',
        email: '',
        phone: '',
      }),
  };
});
