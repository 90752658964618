import { useMutation } from '@tanstack/react-query'
import { queryClient } from '../App'
import useAgentInfoStore from '../store/agentInfo'
import {axiosAdminWithoutAuth, axiosWithAuth} from '../utils/axios'

export const useAdminUpdateProperty = () => {
  const axios = axiosAdminWithoutAuth()
  const { propertyId, mlsListingID, knowledges } =
    useAgentInfoStore()
  const updateAgent = async () => {
    try {
      const data = {
        id: propertyId,
        knowledges,
      }

      const res = await axios.patch('property', data)
      queryClient.setQueryData(
        ['propertyDetail', propertyId.toString()],
        (data: any) => {
          const newData = {
            ...data,
            mlsListingID,
            knowledges,
          }
          return newData
        },
      )
      return res
    } catch (e) {
      return null
    } finally {
    }
  }

  const { mutateAsync, isLoading } = useMutation(updateAgent)
  return { mutateAsync, isLoading }
}
