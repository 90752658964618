import {useQuery} from '@tanstack/react-query'
import {axiosAdminWithoutAuth} from '../utils/axios'

export const useAdminProperties = (offset?: number, limit?: number) => {
    const axios = axiosAdminWithoutAuth()
    return useQuery({
        queryKey: ['properties'],
        queryFn: async () => {
            try {
                const data = {
                    limit: limit,
                    offset: offset,
                }
                const result = await axios.post('properties', data)
                return result.data
            } catch (e: any) {
                return []
            }
        },
        refetchOnWindowFocus: false,
    })
}
