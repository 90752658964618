import { useParams, useSearchParams } from 'react-router-dom';
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import ChatBottom from "./ChatBottom";
import React from "react";
import {OFFER_AGENT_API_KEY} from "../utils/constant";

function OfferSignIframe() {
  const [searchParams] = useSearchParams();
    const { t, i18n } = useTranslation();
  const token = Cookies.get('auth');

  return (
      <div className="w-full h-full">
          <iframe
              src={`https://xiaxiangzhou.retool.com/p/offer_signing_app?offerid=${searchParams.get('offer_id') ?? ''}&accesstoken=${token!}&lang=${i18n.language}`}
              className="w-full h-full pb-20"
          ></iframe>
          <div className="absolute bottom-0 flex flex-col max-h-[76px] w-full bg-black">
              <ChatBottom disableAI={false} agentApiKey={OFFER_AGENT_API_KEY} />
          </div>
      </div>
  );
}

export default OfferSignIframe;
