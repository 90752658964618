import {useQuery} from '@tanstack/react-query'
import {axiosAdminWithoutAuth} from '../utils/axios'

export const useAdminPendingReviewOffers = () => {
    const axios = axiosAdminWithoutAuth()
    return useQuery({
        queryKey: ['offersPendingReview'],
        queryFn: async () => {
            try {
                const result = await axios.get('offers_pending_review')
                return result.data
            } catch (e: any) {
                return null
            }
        },
        refetchOnWindowFocus: false,
    })
}