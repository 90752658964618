import { useQuery } from '@tanstack/react-query'
import {axiosAdminWithoutAuth} from '../utils/axios'

export const useAdminPropertyDetail = (propertyId?: string) => {
    const axios = axiosAdminWithoutAuth()
    return useQuery({
        queryKey: ['propertyDetail', propertyId?.toString()],
        queryFn: async () => {
            if (!propertyId) {
                return null
            }
            try {
                const result = await axios.get(`property/${propertyId}`)
                return result.data
            } catch (e: any) {
                return null
            }
        },
        refetchOnWindowFocus: false,
    })
}
