import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

type Props = {
  content?: string;
};

function CustomReactionMarkdown({ content }: Props) {
  const LinkRenderer = ({ node, ...props }: any) => (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );

  return (
    <div className="markdown-content">
        <ReactMarkdown
            remarkPlugins={[remarkGfm, remarkBreaks]}
            components={{
                a: LinkRenderer,
            }}
        >
            {content?.replace(/\n/gi, '&nbsp; \n')}
        </ReactMarkdown>
    </div>
  );
}

export default CustomReactionMarkdown;
