import { create } from 'zustand'

type ChatBotStore = {
  dragging: boolean
  setDragging: (value: boolean) => void
}

const useChatBotStore = create<ChatBotStore>((set) => ({
  dragging: false,
  setDragging: (value: boolean) => set({ dragging: value }),
}))

export default useChatBotStore
