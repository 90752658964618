import React from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import { useParams, useSearchParams } from 'react-router-dom';
import { useHomeUser } from '../query/useHomeUser';
import {Property, PropertyReport} from "../store/agentInfo";
import {useHomePropertyDetail} from "../query/useHomePropertyDetail";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import ChatBottom from "./ChatBottom";
import {OFFER_AGENT_API_KEY} from "../utils/constant";

function OfferIframe() {
    //const { propertyId , offerId} = useParams()
    const [searchParams] = useSearchParams();
    const { t, i18n } = useTranslation();
    const token = Cookies.get('auth');

    const { data: user } = useHomeUser();

    if (!user) {
        return <></>
    }

    return (
        <div className="w-full h-full">
            <iframe
                src={`https://xiaxiangzhou.retool.com/p/offer_prep_app?offerid=${searchParams.get('offer_id') ?? ''}&reviewoffer=yes&accesstoken=${token!}&lang=${i18n.language}`}
                className="w-full h-full pb-20"
            ></iframe>
            <div className="absolute bottom-0 flex flex-col max-h-[76px] w-full bg-black">
                <ChatBottom disableAI={false} agentApiKey={OFFER_AGENT_API_KEY} />
            </div>
        </div>
    );
}

export default OfferIframe;