import classnames from 'classnames';
import Button, { ButtonType } from './Button';
import { ReactComponent as OpenTab } from '../static/OpenTab.svg';
import React, { useState } from "react";
import {off} from "process";
import {useAdminEmbedSigningUrl} from "../query/useAdminEmbedSigningUrl";
import {useAdminPendingReviewOffers} from "../query/useAdminPendingReviewOffers";
import {useAdminPublishProperty} from "../query/usePublishProperty";
import {axiosAdminWithoutAuthSigningUrl} from "../utils/axios";


type Offer = {
    id: number
    property_id: number,
    property_address: string,
    user_email: string,
    status: string
    last_submitted_time: number
}
type Props = { offers: Offer[] };

function formatDate(timestamp: number) {
    // Convert the timestamp to milliseconds
    const date = new Date(timestamp * 1000);

    // Format the date using toLocaleString or manually
    const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',  // You can use 'short' for abbreviated month names
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,  // Use false for 24-hour time format
    });

    return formattedDate;
}

const ReviewPendingOfferList = ({
    offers
}: Props) => {
    const [selectedOfferId, setSelectedOfferId] = useState<number | null>(null); // State to store the selected offer ID for fetching
    const [fetchData, setFetchData] = useState(false);

    //const { data, isFetching, isLoading, isFetched } = useAdminEmbedSigningUrl(fetchData && selectedOfferId !== null, selectedOfferId)

    const handleFetchSigningUrl = async (offerId: number) => {
        setSelectedOfferId(offerId); // Set the selected offer ID
        setFetchData(true); // Enable data fetching

        const axios = axiosAdminWithoutAuthSigningUrl()
        const result = await axios.get(`/offer/${offerId}/docusign/0/embedded_signing_url?return_url=https://home8-gswe.onrender.com/admin/offer_reivew`)

        window.open(result.data.url, '_blank')
    };

    return (
        <div className='flex flex-col w-full'>
            {
                offers.map((offer, index) => {
                        console.log(offer)
                        return <div key={offer.property_id}
                                    className={classnames("h-[80px] px-6 py-4 flex items-start justify-between", {
                                        "bg-gray-50": index % 2 === 0,
                                        "bg-white": index % 2 === 1,
                                    })}>

                            <div className="flex flex-col gap-2 items-start">
                                <div className="text-base font-bold">
                                    {offer.property_address}
                                </div>
                                <div>User: {offer.user_email}. Time: {formatDate(offer.last_submitted_time)}</div>
                            </div>

                            <div className='flex items-center gap-6'>
                            <Button type={ButtonType.SECONDARY} content="Review" Icon={OpenTab}
                                    onClick={async () => {
                                        await handleFetchSigningUrl(offer.id)
                                    }}/>
                            </div>
                        </div>
                    }
                )
            }
        </div>
    );
};

export default ReviewPendingOfferList;
