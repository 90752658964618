import {useQuery} from '@tanstack/react-query'
import {axiosWithAuth} from '../utils/axios'

export const useHomeBuyingPower = (userId: number) => {
    const axios = axiosWithAuth()
    return useQuery({
        queryKey: ['buying_power'],
        queryFn: async () => {
            try {
                const { data } = await axios.get(`user/${userId}/buying_power`)
                return data?.result.buying_power
            } catch (e: any) {
                return 0
            }
        },
        refetchOnWindowFocus: false,
    })
}
