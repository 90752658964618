import classnames from 'classnames';
import Button, { ButtonType } from './Button';
import { ReactComponent as OpenTab } from '../static/OpenTab.svg';
import React from "react";


type Property = {
  address: string,
  id: number,
  edit_status: string,
  is_publish: boolean
}
type Props = { properties: Property[] };

const PropertyList = ({
  properties
}: Props) => {
  return (
    <div className='flex flex-col w-full'>
      {
        properties.map((property, index) => {
          console.log(property.edit_status)
            if (property.edit_status === 'publish') {
            return <div key={property.id}
                        className={classnames("h-[80px] px-6 py-4 flex items-start justify-between", {
                          "bg-gray-50": index % 2 === 0,
                          "bg-white": index % 2 === 1,
                        })}>
              <div className='text-base font-bold'><span style={{color: 'green'}}>published:</span>{property.address}</div>
              <div className='flex items-center gap-6'>
                <Button type={ButtonType.SECONDARY} content="Chat History" Icon={OpenTab} onClick={async () => {
                  window.open(`/admin/history/${property.id}/${property.address}`, '_blank')
                }}/>
                <Button type={ButtonType.SECONDARY} content="Test property" Icon={OpenTab} onClick={async () => {
                  window.open(`/admin/test/${property.id}`, '_blank')
                }}/>
                <Button type={ButtonType.SECONDARY} content="View details" Icon={OpenTab} onClick={async () => {
                  window.open(`/admin/property/${property.id}`, '_blank')
                }}/>
              </div>
            </div>
            } else if (property.edit_status === 'draft') {
              return <div key={property.id}
                          className={classnames("h-[80px] px-6 py-4 flex items-start justify-between", {
                            "bg-gray-50": index % 2 === 0,
                            "bg-white": index % 2 === 1,
                          })}>
                <div className='text-base font-bold'><span style={{color: 'gray'}}>draft:</span> {property.address}</div>
                <div className='flex items-center gap-6'>
                  <Button type={ButtonType.SECONDARY} content="Test property" Icon={OpenTab} onClick={async () => {
                    window.open(`/admin/test/${property.id}`, '_blank')
                  }}/>
                  <Button type={ButtonType.SECONDARY} content="View details" Icon={OpenTab} onClick={async () => {
                    window.open(`/admin/property/${property.id}`, '_blank')
                  }}/>
                </div>
              </div>
            } else if (property.edit_status === 'under_review') {
              return <div key={property.id}
                          className={classnames("h-[80px] px-6 py-4 flex items-start justify-between", {
                            "bg-gray-50": index % 2 === 0,
                            "bg-white": index % 2 === 1,
                          })}>
                <div className='text-base font-bold'><span style={{color: 'red'}}>review:</span> {property.address}</div>
                <div className='flex items-center gap-6'>
                  <Button type={ButtonType.SECONDARY} content="Chat History" Icon={OpenTab} onClick={async () => {
                    window.open(`/admin/history/${property.id}/${property.address}`, '_blank')
                  }}/>
                  <Button type={ButtonType.SECONDARY} content="Test property" Icon={OpenTab} onClick={async () => {
                    window.open(`/admin/test/${property.id}`, '_blank')
                  }}/>
                  <Button type={ButtonType.SECONDARY} content="View details" Icon={OpenTab} onClick={async () => {
                    window.open(`/admin/property/${property.id}`, '_blank')
                  }}/>
                </div>
              </div>
            }

            }
        )
      }
    </div>
  );
};

export default PropertyList;
