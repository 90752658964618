import {useQuery} from '@tanstack/react-query'
import {axiosWithAuth} from '../utils/axios'

export const useHomeOffersByProperty = (propertyId?: string) => {
    const axios = axiosWithAuth()
    return useQuery({
        queryKey: ['home8_offers_by_property'],
        queryFn: async () => {
            if (!propertyId) {
                return [];
            }

            try {
                const result = await axios.get(`offer_by_property/${propertyId}`)
                console.log(result)
                if (result.data?.code === -1) {
                    return []
                }
                return result.data?.result
            } catch (e: any) {
                return []
            }
        },
        refetchOnWindowFocus: false,
    })
}