import { create } from 'zustand';

type SearchPropertyStore = {
    streetLineOne: string;
    setStreetLineOne: (value: string) => void;
    streetLineTwo: string;
    setStreetLineTwo: (value: string) => void;
    city: string;
    setCity: (value: string) => void;
    state: string;
    setState: (value: string) => void;
    zipCode: string;
    setZipCode: (value: string) => void;
    clear: () => void;
};

export const useSearchPropertyStore = create<SearchPropertyStore>((set) => {
    return {
        streetLineOne: '',
        setStreetLineOne: (value: string) => set({ streetLineOne: value }),
        streetLineTwo: '',
        setStreetLineTwo: (value: string) => set({ streetLineTwo: value }),
        city: '',
        setCity: (value: string) => set({ city: value }),
        state: 'CA',
        setState: (value: string) => set({ state: value }),
        zipCode: '',
        setZipCode: (value: string) => set({ zipCode: value }),
        clear: () =>
            set({
                streetLineOne: '',
                streetLineTwo: '',
                city: '',
                state: 'CA',
                zipCode: '',
            }),
    };
});
