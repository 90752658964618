type InspectionProblem = {
    category: string
    status: string
    defects: string
    recommendations: string
    repair_cost: string
}

function InspectionCard({ category, status, defects, recommendations, repair_cost }: InspectionProblem) {

    const styles = {
        section: {
            marginBottom: '4px',
            backgroundColor: '#F6F7F8',
            paddingTop: '11px',
            paddingBottom: '11px',
            paddingLeft: '8px',
            borderRadius: '12px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        sectionTitle: {
            flex: 1,
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '600',
            marginRight: '6px',
            color: '#192D1D',
        },
        cost: {
            flex: '0 0 150px',
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            textAlign: 'left' as const,
            //width: '100%',
            display: 'inline-block',
            color: '#C40612',
        },
        sectionContent: {
            marginTop: '10px',
        },
        defects: {
            flex: 2,
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            color: '#FF7A00',
        },
        remainingYears: {
            fontSize: '14px',
            color: 'green',
        },
    };

    return (
        <div style={styles.section}>
            <span style={styles.sectionTitle}>{category}</span>
            <span style={styles.defects}>{defects}</span>
            <span style={styles.cost}>{repair_cost}</span>
        </div>
    );
}

export default InspectionCard;