import {useQuery} from '@tanstack/react-query'
import {axiosWithAuth} from '../utils/axios'

export const useHomeExpertConversation = () => {
    const axios = axiosWithAuth()
    return useQuery({
        queryKey: ['home8_expert_conversation'],
        queryFn: async () => {
            try {
                const result = await axios.get(`expert_chat_history`)
                console.log(result)
                if (result.data?.code === -1) {
                    return null
                }
                return result.data?.result
            } catch (e: any) {
                return null
            }
        },
        refetchOnWindowFocus: false,
    })
}
